import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Authorize } from 'Auth/Authorize'
import { SuperAdminProfile } from './pages/Profile'
import { SuperAdminCompaniesRoutes } from './pages/Companies'
import { SuperAdminPhysiotherapistsRoutes } from './pages/Physiotherapists'
import { SuperAdminPatientsRoutes } from './pages/Patients'
import { checkAccess } from './access'
import { path, link } from './routes'

export const Module: React.FC = () => {
  return (
    <Authorize checkAccess={checkAccess}>
      <Switch>
        <Route exact path={path['/superadmin']} component={Entry} />
        <Route exact path={path['/superadmin/profile']} component={SuperAdminProfile} />
        <Route path={path['/superadmin/companies']} component={SuperAdminCompaniesRoutes} />
        <Route
          path={path['/superadmin/physiotherapists']}
          component={SuperAdminPhysiotherapistsRoutes}
        />
        <Route path={path['/superadmin/patients']} component={SuperAdminPatientsRoutes} />
      </Switch>
    </Authorize>
  )
}

const Entry = () => {
  return <Redirect to={link['/superadmin/companies']()} />
}
