/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Entity } from 'shared/types/Entity'
import { isDifferentMonth } from 'shared/utils/date'

export type TimelineProps<D extends Entity = any> = {
  // Base timeline props
  entries: D[]
  select: (entry: D) => void
  // These can be customized depending on the type of entry we want to display
  renderEntry: (entry: D) => React.ReactNode
}

export const Timeline: React.FC<TimelineProps> = ({ entries, select, renderEntry }) => {
  return (
    <div css={cssTimeline /*(size)*/}>
      {entries.map((entry, index) => (
        <div key={index} css={cssEntryBox}>
          {isDifferentMonth(entries, index) ? (
            <div css={cssTimelineMonthSeparator}>
              <span>{entry.date.substring(0,7)}</span>
            </div>
          ) : null}
          <div css={cssEntry} onClick={() => select(entry)}>
            {renderEntry(entry)}
          </div>
        </div>
      ))}
    </div>
  )
}

const cssTimeline = css`
  margin: 0;
`
const cssEntryBox = css`
  min-height: 50px;
  padding-left: 16px;
`
const cssEntry = css`
  position: relative;
  min-height: 50px;
  border-left: 4px dashed #ddd;
  padding-left: 24px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`
const cssTimelineMonthSeparator = css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 12px;
  font-size: 0.75rem;
  & > span {
    z-index: 1;
    background: white;
    padding: 0 8px;
    font-weight: 500;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    border-bottom: 1px solid #ddd;
  }
`
