import { Translations } from './Translations'

export const pl: Translations = {
  'Not Found': 'Nie znaleziono strony',
  'Method Not Allowed': 'Niedozwolona metoda',
  'Failed to fetch': 'Błąd połączenia',
  'Authentication Required': 'Zaloguj się aby uzyskać dostęp',

  not_blank: 'Pole wymagane',
  invalid: 'Nieprawidłowa wartość',
  not_unique: 'Podana wartość już istnieje',
  too_long: 'Przekroczony limit znaków',
  too_short: 'Podana wartość ma za mało znaków',
  invalid_length: 'Nieprawidłowa długość',

  status: 'Status',
  new: 'Nowy',
  active: 'Aktywny',
  inactive: 'Nieaktywny',

  busy: 'zajęty',
  online: 'online',
  offline: 'offline',

  paid: 'Zapłacone',
  not_paid: 'Do zapłaty',

  to_do: 'Do wykonania',
  completed: 'Wykonano',
  failed: 'Nie wykonano',

  patient: 'Pacjent',
  physiotherapist: 'Fizjoterapeuta',
  company: 'Placówka',
  superadmin: 'Super administrator',

  set_of_exercises_created: 'Utworzenie zestawu ćwiczeń',
  set_of_exercises_changed: 'Zmiana zestawu ćwiczeń',
  set_of_exercises_expired: 'Wygaśnięcie zestawu ćwiczeń',
  set_of_exercises_undone: 'Informacja o niewykonaniu ćwiczeń',
  set_of_exercises_prompt: 'Przypomnienie o wykonaniu ćwiczeń',
  set_of_exercises_paid: 'Potwierdzenie płatności',
  set_of_exercises_payment_failed: 'Płatność nie powiodła się',
  profile_data_changed: 'Dane profilu zmienione',
  physiotherapist_created: 'Utworzono nowego fizioterapeute',
  uncertain: 'Inne powiadomienie',
  invitation: 'Zaproszenie',
  invoice: 'Faktura',

  'action.activate': 'Aktywuj',
  'action.add': 'Dodaj',
  'action.back': 'Powrót',
  'action.call': 'Zadzwoń',
  'action.cancel': 'Anuluj',
  'action.changeStatus': 'Zmień status',
  'action.changePassword': 'Zmień hasło',
  'action.close': 'Zamknij',
  'action.connect': 'Połącz',
  'action.deactivate': 'Dezaktywuj',
  'action.delete': 'Usuń',
  'action.disconnect': 'Rozłącz',
  'action.edit': 'Edytuj',
  'action.enterChat': 'Otwórz czat',
  'action.filter': 'Filtruj',
  'action.resetPassword': 'Zapomniałem hasła',
  'action.backToLogin': 'Powrót do logowania',
  'action.found': 'Znaleziono',
  'action.loading': 'Ładuję…',
  'action.login': 'Zaloguj',
  'action.logout': 'Wyloguj',
  'action.play': 'Odtwórz film',
  'action.save': 'Zapisz',
  'action.saveAsTemplate': 'Zapisz jako szablon',
  'action.send': 'Wyślij',
  'action.setNewPassword': 'Ustaw hasło',

  'connection.calling': 'Dzwoni...',
  'connection.connecting': 'Łączenie...',
  'connection.conversation': 'Rozmowa',
  'connection.error': 'Błąd połączenia',
  'connection.error.chat_configuration': 'Nie udało sie pobrać konfiguracji wideoczatu',
  'connection.error.calling_up': 'Nie udało się połączyć z użytkownikiem',
  'connection.finished': 'Połączenie zakończone',

  'date.forDay': 'na dzień',
  'date.fromDay': 'z dn.',
  'date.range': (from, to) => `${from} do ${to}`,
  'date.today': 'Dziś',
  'date.tomorrow': 'Jutro',
  'date.yesterday': 'Wczoraj',

  error: 'Wystąpił błąd',

  'info.activeDateRange': (from, to) => `ważny od ${from} do ${to}`,
  'info.author': name => `opracował ${name}`,
  'info.set': number => `zestaw ${number}`,
  'info.busy':
    'Poczekaj na zakończenie bieżącej rozmowy fizjoterapeuty. Zadzwoń, gdy ponownie będzie dostępny.',
  'info.busy.tooltip': 'Fizjoterapeuta prowadzi inną rozmowę',
  'info.offline':
    'Zapytaj swojego fizjoterapeutę o godziny dostępności. Zadzwoń do niego, gdy będzie dostępny.',
  'info.offline.tooltip': 'Fizjoterapeuta niedostępny na chacie',

  'period.weeks': (weeks: number) => `${weeks} tyg.`,

  'placeholder.notes': 'Dodaj nową notatkę...',
  'placeholder.search': 'Wyszukaj...',
  'placeholder.search_set': '🔍 Wyszukaj zestaw...',
  'placeholder.search_template': '🔍 Wyszukaj szablon...',
  'placeholder.search_exercise': '🔍 Wyszukaj ćwiczenie...',
  'placeholder.specialisation': 'Podaj nazwę specjalizacji',
  'placeholder.specialisation.add': 'Dodaj nową specjalizację...',

  'property.access': 'Dostęp',
  'property.accountNumber': 'Numer konta',
  'property.address': 'Adres',
  'property.apartmentNumber': 'Nr lokalu',
  'property.amount': 'Kwota',
  'property.bankName': 'Nazwa banku',
  'property.billingPeriod': 'Okres rozliczeniowy',
  'property.city': 'Miasto',
  'property.company': 'Placówka',
  'property.description': 'Opis',
  'property.diagnosis': 'Rozpoznanie',
  'property.email': 'Email',
  'property.newPassword': 'Nowe hasło',
  'property.reNewPassword': 'Powtórz nowe hasło',
  'property.firstName': 'Imię',
  'property.height': 'Wzrost',
  'property.houseNumber': 'Nr domu',
  'property.index': 'Lp.',
  'property.lastName': 'Nazwisko',
  'property.licenseNumber': 'Numer PWZFz',
  'property.login': 'Login',
  'property.month': 'Miesiąc',
  'property.name': 'Nazwa',
  'property.nip': 'NIP',
  'property.notes': 'Notatki, dodatkowe informacje',
  'property.number': 'Numer',
  'property.patientFullName': 'Dla pacjenta',
  'property.password': 'Hasło',
  'property.phone': 'Telefon',
  'property.physiotherapistFullName': 'Od fizjoterapeuty',
  'property.postalCode': 'Kod pocztowy',
  'property.quantity': 'Liczba',
  'property.role': 'Rola',
  'property.set': 'Zestaw',
  'property.specialisations': 'Specjalizacje',
  'property.street': 'Ulica',
  'property.state': 'Status',
  'property.unitCost': 'Kwota za tydzień',
  'property.validityPeriod': 'Okres ważności',
  'property.voivodeship': 'Województwo',
  'property.weekCounter': 'Licznik tygodni',
  'property.sourceSet': 'Zestaw źródłowy',
  'property.newFromScratch': 'Ręczna edycja zestawu',
  'property.schedule': 'Terminarz',
  'property.dateStart': 'Obowiązuje od',
  'property.duration': 'Czas trwania',
  'property.everyNumberDay': 'Częstotliwość wykonywania ćwiczeń',
  'property.onceDay': 'Liczba wykonań dziennie',
  'property.exercisesSet': 'Zestaw ćwiczeń',
  'property.setName': 'Nazwa zestawu',
  'property.recognition': 'Rozpoznanie',

  'section.basic': 'Dane podstawowe',
  'section.medical': 'Dane medyczne',

  'title.company.add': 'Dodaj placówkę',
  'title.company.edit': 'Edycja placówki',
  'title.companies': 'Placówki',
  'title.groupInvoice': 'Faktura zbiorcza',
  'title.information': 'Informacje',
  'title.notifications': 'Powiadomienia',
  'title.physiotherapist.add': 'Dodaj terapeutę',
  'title.physiotherapist.edit': 'Edycja fizjoterapeuty',
  'title.physiotherapists': 'Fizjoterapeuci',
  'title.patient.add': 'Nowy pacjent',
  'title.patient.edit': 'Edycja pacjenta',
  'title.patientPhysiotherapists': 'Moi fizjoterapeuci',
  'title.patients': 'Pacjenci',
  'title.exercises': 'Ćwiczenia',
  'title.exercisesHistory': 'Historia ćwiczeń',
  'title.setOfExercises': 'Zestaw ćwiczeń',
  'title.setsOfExercises': 'Zestawy ćwiczeń',
  'title.payments': 'Płatności',
  'title.resetDescription': 'Podaj adres e-mail, na który wyślemy instrukcję resetowania hasła.',
  'title.updateDescription': 'Nowe hasło musi zawierać minimum 8 znaków w tym małe i wielkie litery, cyfry i znaki specjalne.',
  'title.settlements': 'Rozliczenia',
  'title.summary': 'Podsumowanie',
  'title.templates': 'Szablony ćwiczeń',
  'title.videoPreview': 'Podgląd wideo',
  'title.templatesPreview': 'Podgląd zestawu ćwiczeń',

  'unit.custom': 'niestandardowo',
  'unit.days': 'dni',
  'unit.every': 'co',
  'unit.interval': days => {
    if (days === 1) return 'codziennie'
    if (days === 7) return 'co tydzień'
    if (days === undefined) return 'niestandardowo'
    return `co ${days} dni`

  },
  'unit.times(s)PerDay': times => {
    if (times === 1) return 'raz dziennie'
    return 'razy dziennie'
  },
  'unit.week(s)': weeks => {
    if (weeks === 1) return 'tydzień'
    switch (weeks % 10) {
      case 2:
      case 3:
      case 4: {
        return Math.floor(weeks / 10) === 1 ? 'tygodni' : 'tygodnie'
      }
      default:
        return 'tygodni'
    }
  },

  'money.gross': 'Suma brutto',
  'money.net': 'Suma netto',
  'money.tax': 'Podatek',
  'money.total': 'Do zapłaty',

  'patients.add': 'Dodaj pacjenta',
  'patients.empty': 'Brak pacjentów na liście',
  'patients.empty.hint': `Poczekaj az lista zapełni się zgłoszeniami pacjentów. Mozesz tez samodzielnie dodać
  nowego pacjenta.`,

  'sets.add': 'Utwórz nowy zestaw',
  'sets.empty': 'Ten pacjent nie ma aktywnego zestawu ćwiczeń',
  'sets.empty.hint': `Możesz stworzyć nowy zestaw ćwiczeń lub wczytać jeden z poprzednich zestawów`,

  'exercise.add': 'Dodaj ćwiczenie',
  'exercise.duration': minutes => `max. ${minutes} min.`,
  'exercise.repetitionsDone': (done, repetitions) => `${done}/${repetitions} powtórzeń`,
  'exercise.repetitionsSeries': (series, repetitions) => `${series} x ${repetitions} powtórzeń`,
  'exercise.pause': 'Przerwa',
  'exercise.pauseBetweenSeries': minutes => `${minutes} min. przerwy między seriami`,
  'exercise.pauseAfterComplete': minutes => `${minutes} min.`,

  'exercises.hint': `Na liście brakuje jakiegoś ćwiczenia? Chcesz zgłosić poprawki do istniejącego ćwiczenia?
  Skontaktuj się z nami info@rehabee.pl, tel. 123 456 789`,
  'exercises.percentScore': percent => `Wykonany na ${percent}%`,
  'exercises.scheduled': count => {
    if (count === 1) return `Do wykonania ${count} ćwiczenie`
    switch (count % 10) {
      case 2:
      case 3:
      case 4:
        return `Do wykonania ${count} ćwiczenia`
      default:
        return `Do wykonania ${count} ćwiczeń`
    }
  },
}
