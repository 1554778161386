import { matchPath } from 'react-router'
import useRouter from 'use-react-router'
import mapValues from 'lodash/mapValues'
import { CreateLink, createLink, prefix } from 'shared/utils/url'

export type Params = {
  companyId: string
  physiotherapistId?: string
  settlementId?: string
}

const routes = {
  '/company/:companyId': undefined,
  '/company/:companyId/profile': undefined,
  '/company/:companyId/physiotherapists': undefined,
  '/company/:companyId/physiotherapists/add': undefined,
  '/company/:companyId/physiotherapists/:physiotherapistId': undefined,
  '/company/:companyId/settlements': undefined,
  '/company/:companyId/settlements/:settlementId': undefined,
} as const

export const path = mapValues(routes, (v, k) => prefix + k)
export const link = mapValues(routes, (v, k) => (createLink as CreateLink<Params>)(k))

export const root = prefix + '/company'
export const entry = '/company/:companyId'

export const useParams = () => {
  const { location } = useRouter<Params>()
  const match = matchPath<Params>(location.pathname, prefix + entry)
  return (match && match.params) || undefined
}
