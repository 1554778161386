import { Company, CompanyInput } from 'shared/types/Company'
import { FormValidation } from 'shared/types/Form/FormValidation'
import { validateEmail } from 'shared/attributes/email'
import { validatePhone } from 'shared/attributes/phone'
import { validatePostalCode } from 'shared/attributes/postalCode'
import { validateNIP } from 'shared/attributes/nip'
import { validateAccountNumber } from 'shared/attributes/accountNumber'
import * as In from 'shared/utils/input'

// --- CONTACT DATA ---

const name: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const phone: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
  validate: validatePhone,
}

const email: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
  validate: validateEmail,
}

// --- ADDRESS DATA ---

const voivodeship: In.FieldConfig<string> = {
  ...In.TEXT,
}

const city: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const postalCode: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
  validate: validatePostalCode,
}

const street: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const houseNumber: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const apartmentNumber: In.FieldConfig<string> = {
  ...In.TEXT,
}

// --- PAYMENT DATA ---

const nip: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
  validate: validateNIP,
}

const bankName: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const accountNumber: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
  validate: validateAccountNumber,
}

// --- OTHER DETAILS ---

const description: In.FieldConfig<string> = {
  ...In.TEXT,
}

// --- FORM FIELDS ---

const fields: Record<keyof CompanyInput, In.FieldConfig> = {
  name,
  phone,
  email,
  voivodeship,
  city,
  postalCode,
  street,
  houseNumber,
  apartmentNumber,
  nip,
  bankName,
  accountNumber,
  description,
}

// --- FORM HELPERS ---

export const required: (keyof CompanyInput)[] = Object.keys(fields).reduce(
  (req: (keyof CompanyInput)[], key: any) => {
    return fields[key as keyof CompanyInput].required === true ? [...req, key] : req
  },
  []
)

export const validation: FormValidation<Company, CompanyInput> = {
  getInitialValues: In.getInitialValues(fields),
  validate: In.validate(fields),
}

const _payloadCreate: In.PayloadCreate<CompanyInput> = In.payloadCreate
const _payloadUpdate: In.PayloadUpdate<Company, CompanyInput> = In.payloadUpdate

export const payloadCreate = _payloadCreate(fields)
export const payloadUpdate = _payloadUpdate(fields)
