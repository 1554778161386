import {
  FetchMany,
  FetchOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
  PatchOne,
  fetchMany,
  fetchOne,
  createOne,
  updateOne,
  deleteOne,
  activateOne,
  deactivateOne,
} from 'shared/api/crud'
import { Meta } from 'shared/types/Meta'
import { Patient, PatientInput, PatientParams, PatientsQuery } from 'shared/types/Patient'

const resource = 'patients'

export type PatientsPayload = { patients: Patient[]; meta: Meta }
export type PatientPayload = { patient: Patient }

export const fetchPatients: FetchMany<PatientsPayload, PatientsQuery> = fetchMany(resource)
export const fetchPatient: FetchOne<PatientPayload> = fetchOne(resource)
export const createPatient: CreateOne<PatientPayload, PatientInput & PatientParams> = createOne(
  resource
)
export const updatePatient: UpdateOne<PatientPayload, PatientInput & Patient> = updateOne(resource)
export const deletePatient: DeleteOne = deleteOne(resource)
export const activatePatient: PatchOne<PatientPayload> = activateOne(resource)
export const deactivatePatient: PatchOne<PatientPayload> = deactivateOne(resource)
