/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import * as Api from 'shared/api'
import { useConnections } from 'shared/context/connections'
import { UUID } from 'shared/types/UUID'
import { Caller } from 'shared/types/Caller'
import { ConnectionHeader, ConnectionLoader } from 'shared/components/Connection'
import { TeleportToModals } from 'shared/views/portals/PortalWithModals'
import { ActionAcceptCall } from 'shared/views/actions/ActionAcceptCall'
import { ActionDisconnect } from 'shared/views/actions/ActionDisconnect'

type PopupConnectionProps = {
  me: UUID
  caller: Caller
}

export const PopupIncomingConnection: React.FC<PopupConnectionProps> = ({ me, caller }) => {
  const { actions } = useConnections()

  const rejectCallAndCloseModal = () => {
    actions.dismissIncomingConnection(caller.id)
    Api.hangUp(me, caller.id)
  }

  return (
    <TeleportToModals>
      <Modal open={true} onClose={rejectCallAndCloseModal} closeAfterTransition>
        <PopupPaper>
          <HeaderBox>
            <ConnectionHeader participant={caller} incoming={true} />
          </HeaderBox>
          <ContentBox>
            <ConnectionLoader participant={caller} />
          </ContentBox>
          <ActionsBox>
            <ActionAcceptCall participant={caller} />
            <ActionDisconnect disconnect={rejectCallAndCloseModal} />
          </ActionsBox>
        </PopupPaper>
      </Modal>
    </TeleportToModals>
  )
}

const PopupPaper = styled(Paper)`
  && {
    width: 420px;
    margin: 64px auto;
    padding: 16px;
    border-radius: 2px;
  }
`

const HeaderBox = styled.div``

const ContentBox = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 8px 0 16px;
}
`

const ActionsBox = styled.div`
  max-width: 106px;
  min-height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
