/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { SetOfExercises } from 'shared/types/SetOfExercises'
import { EmptyList } from 'shared/components/EmptyList'
import { PatientPaymentOptions } from 'shared/views/PatientPaymentOptions'

type PatientSetExpiredProps = {
  setOfExercises: SetOfExercises
}

export const PatientSetExpired: React.FC<PatientSetExpiredProps> = ({ setOfExercises }) => {
  return (
    <div>
      <EmptyList
        title={'Twój zestaw ćwiczeń jest już nieaktualny'}
        hint={'Możesz przedłużyć dostęp do zestawu\no kolejne 2 tygodnie'}
      >
        <div css={cssDetails}>
          <div css={cssAmount}>
            <h4>20,00 zł</h4>
          </div>
          <span css={cssDescription}>Dostęp do zestawu na 14 kolejnych dni</span>
          <PatientPaymentOptions
            checkAccess={session => (session ? session.userId === setOfExercises.patient : false)}
          />
        </div>
      </EmptyList>
    </div>
  )
}

const cssDetails = css`
  width: 320px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const cssAmount = css`
  font-weight: bold;
`
const cssDescription = css`
  font-size: 0.75rem;
`
