/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Notification } from 'shared/types/Notification'
import { NotificationIcon } from 'shared/components/NotificationIcon'
import { toShortDayHourLabel } from 'shared/utils/date'
import { colors } from 'shared/styles/colors'
import { t } from 'shared/translations'

type NotificationProps = {
  notification: Notification
  markAsRead: () => void
}

export const NotificationRender: React.FC<NotificationProps> = ({ notification, markAsRead }) => {
  switch (notification.state) {
    case 'new': {
      return (
        <div css={cssNotification} onClick={markAsRead}>
          <div css={cssIcon}>
            <NotificationIcon type={notification.type} />
          </div>
          <div>
            <div css={cssName}>
              {t[notification.type]}
              <div css={cssState}>{t['new']}</div>
            </div>
            <div css={cssContents}>{notification.title}</div>
            <div>{toShortDayHourLabel(notification.dateSend)}</div>
          </div>
        </div>
      )
    }
    case 'read': {
      return (
        <div css={cssNotificationRead}>
          <div css={cssIcon}>
            <NotificationIcon type={notification.type} />
          </div>
          <div>
            <div css={cssName}>{t[notification.type]}</div>
            <div css={cssContents}>{notification.title}</div>
            <div>{toShortDayHourLabel(notification.dateSend)}</div>
          </div>
        </div>
      )
    }
  }
}

const cssNotification = css`
  display: flex;
  padding: 10px 5px;
  border-bottom: 1px solid #ccc;
  color: ${colors.mediumDarkGrey};
  font-size: 0.875rem;
  line-height: 1.5rem;
  & > div > div:nth-of-type(1) {
    font-weight: bold;
  }
  & > div > div:nth-of-type(2) {
    color: #999;
  }
  :hover {
    cursor: pointer;
  }
`
const cssIcon = css`
  margin: 15px 10px;
`
const cssNotificationRead = css`
  display: flex;
  padding: 10px 5px;
  border-bottom: 1px solid #ccc;
  color: ${colors.mediumGrey};
  font-size: 0.875rem;
  line-height: 1.5rem;
`
const cssName = css`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`
const cssState = css`
  padding: 0 5px;
  color: ${colors.lightestGrey};
  background-color: ${colors.actionBlue};
`
const cssContents = css`
  padding-bottom: 5px;
`
