import * as Storage from 'shared/storage'
import { SessionState } from './state'
import { SessionAction } from './actions'

export const initialState: SessionState = {
  pending: Storage.loadSessionToken() || false,
}

export const reducer: React.Reducer<SessionState, SessionAction> = (state, action) => {
  switch (action.type) {
    case 'AUTH/CREDENTIALS':
      return { pending: true }
    case 'AUTH/TOKEN':
      return { pending: action.payload }
    case 'AUTH.FAILURE':
      return { pending: false, error: action.payload }
    case 'AUTH.SUCCESS':
      return { pending: false, session: action.payload }
    case 'LOGOUT':
      return { pending: false }
    default:
      return state
  }
}
