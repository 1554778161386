import { FetchMany, FetchOne, fetchMany, fetchOne } from 'shared/api/crud'
import { Meta } from 'shared/types/Meta'
import { Exercise, ExercisesQuery } from 'shared/types/Exercise'

const resource = 'exercises'

export type ExercisesPayload = { exercises: Exercise[]; meta: Meta }
export type ExercisePayload = { exercise: Exercise }

export const fetchExercises: FetchMany<ExercisesPayload, ExercisesQuery> = fetchMany(resource)
export const fetchExercise: FetchOne<ExercisePayload> = fetchOne(resource)
