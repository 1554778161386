import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { Icons } from 'shared/components/Icons'
import { IconButton } from 'shared/components/Button'
import { t } from 'shared/translations'

type ActionCloseProps = {
  close: () => void
}

export const ActionClose: React.FC<ActionCloseProps> = ({ close }) => {
  return (
    <Tooltip title={t['action.close']}>
      <IconButton onClick={close}>
        <Icons.Close />
      </IconButton>
    </Tooltip>
  )
}
