import { explain } from 'shared/utils/error'
import { CreateFieldValidator, FieldVariant } from 'shared/types/Form/FieldState'

/**
 * Define different form behavior depending on the context.
 *
 * The idea here is to define a form component for given resource once
 * and reuse it in different contexts (with slightly modified behavior)
 *
 * A "context" may be:
 * - creating a new resource (**add**)
 * - updating existing resource (**edit**)
 * - viewing a resource as read-only (not implemented yet)
 *
 * We want form fields to behave differently depending on the context
 */

// --- FORM ADD/CREATE ---

/**
 * Form validation behavior for adding new resource
 */
export const createAddFormFieldValidator: CreateFieldValidator = server => formik => {
  const { touched, errors } = formik.form
  const { name, value } = formik.field
  const pending = server.pending
  const serverError = server.errors && server.errors[name]
  const localError = errors[name]

  let variant: FieldVariant | undefined
  let helperText: string | undefined

  if (touched[name]) {
    if (localError) {
      variant = 'error'
      helperText = explain(localError)
    } else {
      // Let's only mark non-empty fields as successfully validated
      // Marking optional empty fields with green markers feels like overkill
      if (value) {
        variant = 'success'
        helperText = undefined
      }
    }
  } else {
    if (serverError) {
      variant = 'error'
      helperText = explain(serverError)
    }
  }

  return {
    pending,
    variant,
    helperText,
  }
}

// --- FORM EDIT/UPDATE ---

/**
 * Form validation behavior for editing existing resource
 */
export const createEditFormFieldValidator: CreateFieldValidator = server => formik => {
  const { touched, errors } = formik.form
  const { name, value } = formik.field

  const pending = server.pending
  const serverValue = server.data && server.data[name]
  const serverError = server.errors && server.errors[name]
  const localError = errors[name]

  let variant: FieldVariant | undefined
  let helperText: string | undefined

  if (touched[name]) {
    if (localError) {
      variant = 'error'
      helperText = explain(localError)
    } else {
      // Adding new value
      if (!serverValue) {
        // Let's only mark non-empty fields as successfully validated
        // Marking optional empty fields with green markers feels like overkill
        if (value) {
          variant = 'success'
          helperText = undefined
        }
      }
      // Editing existing value
      else {
        // Let's mark editing existing values differently
        if (value !== serverValue) {
          variant = 'edited'
          helperText = undefined
        }
      }
    }
  } else {
    if (serverError) {
      variant = 'error'
      helperText = explain(serverError)
    }
  }

  return {
    pending,
    variant,
    helperText,
  }
}
