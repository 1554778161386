import React from 'react'
import * as Ps from 'shared/data/payment'
import { ListProps } from 'shared/types/List'
import { Payment } from 'shared/types/Payment'
import { Column } from 'shared/types/Table'
import { Wrapper } from 'shared/components/Wrapper'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { PaymentStatusText } from 'shared/views/text/PaymentStatusText'
import { createScrollHandler } from 'shared/utils/scroll'
import { formatCurrency } from 'shared/utils/money'
import { toDateLabel } from 'shared/utils/date'
import { t } from 'shared/translations'
import { PaymentsTable } from './PaymentsTable'

export const PaymentsList: React.FC<ListProps<Payment>> = ({
  state,
  order,
  loadNext,
  sortBy,
  rowLink,
  renderActions,
}) => {
  const { data, meta } = state
  const [[key, value = 'asc']] = Object.entries(order || Ps.defaultOrder)

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const handleScroll = createScrollHandler({ data, meta, loadNext })

  const columns = renderActions
    ? [...dataColumns, { ...actionColumn, cellRenderer: renderActions }]
    : dataColumns

  return (
    <Wrapper>
      <PaymentsTable
        columns={columns}
        rowCount={data.length}
        rowGetter={({ index }) => data[index]}
        rowLink={rowLink}
        handleScroll={handleScroll}
        handleRequestSort={sortBy}
        orderKey={key as keyof Payment}
        orderValue={value}
      />
    </Wrapper>
  )
}

const dataColumns: Column<Payment>[] = [
  {
    id: 'setOfExercisesNumber',
    label: t['property.set'],
    width: 300,
    sortable: true,
  },
  {
    id: 'setOfExercisesName',
    label: t['property.name'],
    width: 400,
    sortable: true,
  },
  {
    id: 'setOfExercisesStartDate',
    label: t['property.access'],
    width: 400,
    sortable: true,
    cellRenderer: props => {
      const p = props.rowData as Payment
      return t['date.range'](
        toDateLabel(p.setOfExercisesStartDate.substring(0,10)),
        toDateLabel(p.setOfExercisesEndDate.substring(0,10))
      )
    },
  },
  {
    id: 'amount',
    label: t['property.amount'],
    width: 240,
    sortable: false,
    cellRenderer: props => {
      const p = props.rowData as Payment
      return formatCurrency(p.amount)
    },
  },
  {
    id: 'state',
    label: t['property.state'],
    width: 200,
    minWidth: 80,
    sortable: false,
    cellRenderer: props => {
      const p = props.rowData as Payment
      return <PaymentStatusText status={p.state} />
    },
  },
]

const actionColumn: Column<Payment> = {
  id: 'actions',
  label: '',
  width: 500,
}
