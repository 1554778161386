import React from 'react'
import * as Api from 'shared/api'
import { UUID } from 'shared/types/UUID'
import { SetOfExercises } from 'shared/types/SetOfExercises'
import { GetLabel, Fetch } from 'shared/types/Label'
import { RemoteLabel } from 'shared/components/RemoteLabel'
import { t } from 'shared/translations'

type SetOfExercisesExerciseCounterProps = {
  id: UUID
}

const getLabel: GetLabel<SetOfExercises> = setEx =>
  t['exercises.scheduled'](setEx.exercisesParameters.length)
const fetch: Fetch<SetOfExercises> = id =>
  Api.fetchSetOfExercises(id).then(res => res.setOfExercises)

export const SetOfExercisesExerciseCounter: React.FC<SetOfExercisesExerciseCounterProps> = ({
  id,
}) => {
  return <RemoteLabel id={id} getLabel={getLabel} fetch={fetch} />
}
