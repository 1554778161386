import React from 'react'
import clsx from 'clsx'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableCell from '@material-ui/core/TableCell'
import * as V from 'react-virtualized'
import useRouter from 'use-react-router'
import { Column } from 'shared/types/Table'
import { useTableStyles } from 'shared/styles/table'
import { isPartOfClickableElement } from 'shared/utils/dom'

interface Index {
  index: number
}

export interface VirtualizedTableProps<D extends object = any> {
  columns: Column<D>[]
  headerHeight?: number
  rowHeight?: number
  rowCount: number
  rowGetter?: (row: Index) => D
  rowLink?: (rowData: D) => string
  onRowClick?: (p: V.RowMouseEventHandlerParams) => void
  orderKey: keyof D
  orderValue: 'asc' | 'desc'
  handleRequestSort: (by: keyof D) => void
  handleScroll: Function
}

export const VirtualizedTable: React.FC<VirtualizedTableProps> = ({
  columns,
  headerHeight = 48,
  rowHeight = 60,
  rowCount,
  rowGetter,
  rowLink,
  onRowClick,
  orderKey,
  orderValue,
  handleRequestSort,
  handleScroll,
}) => {
  const classes = useTableStyles()
  const router = useRouter()

  const handleRowClick =
    typeof rowLink === 'function' || typeof onRowClick === 'function'
      ? (p: V.RowMouseEventHandlerParams) => {
          if (isPartOfClickableElement(p.event.target as HTMLElement)) {
            // Ignore default handlers in case user clicked on a link or a button inside table row
            // We don't want to handle one click event in two places at once (example: action buttons)
            return false
          }
          if (typeof onRowClick === 'function') {
            onRowClick(p)
          }
          if (typeof rowLink === 'function') {
            router.history.push(rowLink(p.rowData))
          }
        }
      : undefined

  const getRowClassName = ({ index }: Index): string => {
    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1 && Boolean(handleRowClick),
    })
  }

  const headerRenderer: V.TableHeaderRenderer = ({ label, dataKey, disableSort }) => {
    const className = clsx(classes.tableCell, classes.flexContainer, classes.noClick)
    const sortDirection = orderKey === dataKey ? orderValue : false

    return (
      <TableCell
        component="div"
        className={className}
        variant="head"
        align="left"
        sortDirection={sortDirection}
      >
        {!disableSort ? (
          <TableSortLabel
            active={orderKey === dataKey}
            direction={orderValue}
            onClick={() => handleRequestSort(dataKey)}
          >
            {label}
          </TableSortLabel>
        ) : (
          <TableSortLabel hideSortIcon={true}>{label}</TableSortLabel>
        )}
      </TableCell>
    )
  }

  const cellRenderer: V.TableCellRenderer = props => {
    const { columnIndex, cellData } = props

    const column = columns[columnIndex]
    const align = column.numeric ? 'right' : 'left'
    const className = clsx(classes.tableCell, classes.flexContainer, {
      [classes.noClick]: !handleRowClick,
    })

    return (
      <TableCell component="div" className={className} variant="body" align={align}>
        {column.cellRenderer ? column.cellRenderer(props) : cellData}
      </TableCell>
    )
  }

  return (
    <V.AutoSizer>
      {({ height, width }) => (
        <V.Table
          onScroll={(el: any) => handleScroll(el)}
          height={height}
          width={width}
          rowClassName={getRowClassName}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          rowCount={rowCount}
          rowGetter={rowGetter}
          onRowClick={handleRowClick}
          orderKey={orderKey}
          orderValue={orderValue}
          handleRequestSort={handleRequestSort}
        >
          {columns.map(column => {
            const { id, ...rest } = column
            return (
              <V.Column
                {...rest}
                key={String(id)}
                className={classes.flexContainer}
                headerRenderer={headerRenderer}
                cellRenderer={cellRenderer}
                dataKey={String(id)}
              />
            )
          })}
        </V.Table>
      )}
    </V.AutoSizer>
  )
}
