import React from 'react'
import { PaymentStatus } from 'shared/types/Status'
import { TextVariant, Text } from 'shared/components/Text'
import { t } from 'shared/translations'

type PaymentStatusTextProps = {
  status: PaymentStatus
}

const statusTextVariant: Record<PaymentStatus, TextVariant> = {
  paid: 'green',
  not_paid: 'orange',
}

export const PaymentStatusText: React.FC<PaymentStatusTextProps> = ({ status }) => {
  return <Text label={t[status]} variant={statusTextVariant[status]} />
}
