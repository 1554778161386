import React from 'react'
import * as Pt from 'shared/data/patient'
import { ListProps } from 'shared/types/List'
import { Patient } from 'shared/types/Patient'
import { Column } from 'shared/types/Table'
import { Wrapper } from 'shared/components/Wrapper'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { StatusText } from 'shared/views/text/StatusText'
import { createScrollHandler } from 'shared/utils/scroll'
import { t } from 'shared/translations'
import { PatientsTable } from './PatientsTable'
import { css } from '@emotion/core'

export const PatientsList: React.FC<ListProps<Patient>> = ({
  state,
  order,
  loadNext,
  sortBy,
  rowLink,
  renderActions,
}) => {
  const { data, meta } = state
  const [[key, value = 'asc']] = Object.entries(order || Pt.defaultOrder)

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const handleScroll = createScrollHandler({ data, meta, loadNext })

  const columns = renderActions
    ? [...dataColumns, { ...actionColumn, cellRenderer: renderActions }]
    : dataColumns

  return (
    <Wrapper>
      <PatientsTable css={patientsTable}
        columns={columns}
        rowCount={data.length}
        rowGetter={row => data[row.index]}
        rowLink={rowLink}
        handleScroll={handleScroll}
        handleRequestSort={sortBy}
        orderKey={key as keyof Patient}
        orderValue={value}
      />
    </Wrapper>
  )
}

const dataColumns: Column<Patient>[] = [
  { id: 'firstName', label: t['property.firstName'], width: 200, minWidth: 100, sortable: true },
  { id: 'lastName', label: t['property.lastName'], width: 300, minWidth: 100, sortable: true },
  {
    id: 'state',
    label: t['property.state'],
    width: 200,
    minWidth: 80,
    cellRenderer: props => {
      const p = props.rowData as Patient
      return <StatusText status={p.state} />
    },
  },
  { id: 'phone', label: t['property.phone'], width: 200, minWidth: 100, sortable: true },
  { id: 'email', label: t['property.email'], width: 400, minWidth: 100, sortable: true },
]

const actionColumn: Column<Patient> = {
  id: 'actions',
  label: '',
  width: 200,
  minWidth: 100,
}

const patientsTable = css`
  outline: none!important;
  &&focus{
    outline: none!important;

  }
`