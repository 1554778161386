/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { FieldProps } from 'formik'
import { Calendar } from '@material-ui/pickers'
import * as SetEx from 'shared/data/setOfExercises'
import { FieldState } from 'shared/types/Form/FieldState'
import { SetOfExercisesInput } from 'shared/types/SetOfExercises'
import { OcurrenceInput } from 'shared/types/Ocurrence'
import { shouldDisableDay, formatUTCDateTime, isSameDate } from 'shared/utils/date'
import { colors } from 'shared/styles/colors'
import { t } from 'shared/translations'

interface SetOfExercisesCalendarProps extends FieldProps<SetOfExercisesInput> {
  state: FieldState
  setOfExercises: SetOfExercisesInput
}

export const SetOfExercisesCalendar: React.FC<SetOfExercisesCalendarProps> = ({
  form,
  state,
  setOfExercises,
}) => {
  const selectedDays = setOfExercises.frequencies.map(d => d.date).filter(Boolean)
  const now = new Date() // This is not really the selected date, but we have to pass something into calendar

  const selectIntervalInDays = (step: number) => {
    form.setFieldValue('everyNumberDay', step)
    form.setFieldValue('custom', false)
    form.setFieldValue(
      'frequencies',
      SetEx.selectOcurrencesWithinRange({ ...form.values, everyNumberDay: step })
    )
    form.validateForm()
  }

  const selectDay = (date: Date | null) => {
    if (date) {
      // Remove date from calendar selection
      const day = formatUTCDateTime(date)
      if (selectedDays.some(d => isSameDate(d, date))) {
        form.setFieldValue('everyNumberDay', undefined)
        form.setFieldValue('custom', true)
        form.setFieldValue(
          'frequencies',
          form.values.frequencies.filter(d => !isSameDate(d.date, date))
        )
      } else {
        // Add date to calendar selection
        // Take number of sets/day into account
        const datesToAdd: OcurrenceInput[] = Array(form.values.onceDay || 1).fill({ date: day })
        form.setFieldValue('everyNumberDay', undefined)
        form.setFieldValue('custom', true)
        form.setFieldValue('frequencies', [...form.values.frequencies, ...datesToAdd].sort())
      }
      form.validateForm()
    }
  }

  return (
    <div css={cssSetOfExercisesCalendar}>
      <div css={cssCalendarBox}>
        {setOfExercises.dateStart && setOfExercises.duration && (
          <Calendar
            date={now}
            onChange={selectDay}
            disablePast
            shouldDisableDate={shouldDisableDay(setOfExercises.dateStart, setOfExercises.duration)}
            renderDay={(day, today, isThisMonth, dayComponent: JSX.Element) => {
              return React.cloneElement(dayComponent, {
                selected: Boolean(day && selectedDays.some(d => isSameDate(d, day))),
              })
            }}
          />
        )}
      </div>
      {/* <div css={cssOptionsBox}>
        <div css={cssOptionsGroup}>
          {[1, 2, 3].map(days => (
            <button
              key={days}
              type="button"
              onClick={() => selectIntervalInDays(days)}
              css={cssButton(setOfExercises.everyNumberDay === days)}
            >
              {t['unit.interval'](days)}
            </button>
          ))}
        </div>
        <div css={cssOptionsGroup}>
          {[4, 5, 6].map(days => (
            <button
              key={days}
              type="button"
              onClick={() => selectIntervalInDays(days)}
              css={cssButton(setOfExercises.everyNumberDay === days)}
            >
              {t['unit.interval'](days)}
            </button>
          ))}
        </div>
        <div css={cssOptionsGroup}>
          <button
            type="button"
            onClick={() => selectIntervalInDays(7)}
            css={cssButton(setOfExercises.everyNumberDay === 7)}
          >
            {t['unit.interval'](7)}
          </button>
        </div>
        <div css={cssOptionsGroup}>
          <button
            type="button"
            disabled={true}
            css={cssButton(setOfExercises.custom === true, true)}
          >
            {t['unit.custom']}
          </button>
        </div>
      </div> */}
    </div>
  )
}

const cssSetOfExercisesCalendar = css`
  display: flex;
  flex-direction: row;
`
const cssCalendarBox = css`
  width: 340px;
`
const cssOptionsBox = css`
  padding: 10px 16px;
  border-left: 1px solid #ccc;
`
const cssOptionsGroup = css`
  display: flex;
  flex-direction: row;
  &:not(:first-of-type) {
    margin-top: 8px;
  }
  & > button {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  & > button:not(:first-of-type) {
    margin-left: 8px;
  }
`
const cssButton = (active: boolean, fullWidth?: boolean) => css`
  min-width: 136px;
  width: ${fullWidth ? '100%' : 'auto'};
  background: white;
  border: 1px ${fullWidth ? 'dashed' : 'solid'} ${active ? colors.mainOrange : '#ccc'};
  color: ${active ? colors.mainOrange : '#666'};
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  &[disabled] {
    color: ${active ? colors.mainOrange : '#ccc'};
    border-color: #ccc;
    cursor: default;
  }
`
