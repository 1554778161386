import { FetchMany, FetchOne, fetchMany, fetchOne } from 'shared/api/crud'
import { Meta } from 'shared/types/Meta'
import { Invoice, InvoicesQuery } from 'shared/types/Invoice'

const resource = 'invoices'

export type InvoicesPayload = { invoices: Invoice[]; meta: Meta }
export type InvoicePayload = { invoice: Invoice }

export const fetchInvoices: FetchMany<InvoicesPayload, InvoicesQuery> = fetchMany(resource)
export const fetchInvoice: FetchOne<InvoicePayload> = fetchOne(resource)
