/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import * as Layout from 'shared/components/Layout'
import { Icons } from 'shared/components/Icons'

type ContactInfoProps = {
  person: {
    phone?: string
    email?: string
  }
}

export const ContactInfo: React.FC<ContactInfoProps> = ({ person }) => {
  return (
    <div css={cssBox}>
      {person.phone && <ContactInfoPhone phone={person.phone} />}
      {person.email && <ContactInfoEmail email={person.email} />}
    </div>
  )
}

type ContactInfoPhoneProps = {
  phone: string
}

const ContactInfoPhone: React.FC<ContactInfoPhoneProps> = ({ phone }) => {
  return (
    <Layout.FlexLine>
      <Icons.Phone fontSize="small" css={cssIcon} />
      <div css={cssText}>{phone}</div>
    </Layout.FlexLine>
  )
}

type ContactInfoEmailProps = {
  email: string
}

const ContactInfoEmail: React.FC<ContactInfoEmailProps> = ({ email }) => {
  return (
    <Layout.FlexLine>
      <Icons.Email fontSize="small" css={cssIcon} />
      <div css={cssText}>{email}</div>
    </Layout.FlexLine>
  )
}

const cssBox = css`
  flex-direction: column;
`

const cssIcon = css`
  color: #999;
`
const cssText = css`
  margin-left: 4px;
  font-size: 0.75rem;
`
