import React from 'react'
import { IconButton, FooterButton } from 'shared/components/Button'
import { Icons } from 'shared/components/Icons'
import { t } from 'shared/translations'

type ActionSaveAsTemplateProps = {
  save: () => void
  disabled?: boolean
  renderAsButton?: boolean
}

export const ActionSaveAsTemplate: React.FC<ActionSaveAsTemplateProps> = ({
  save,
  disabled,
  renderAsButton,
}) => {
  return renderAsButton ? (
    <FooterButton disabled={disabled} onClick={save} title={t['action.saveAsTemplate']}>
      <Icons.Save />
      {t['action.saveAsTemplate']}
    </FooterButton>
  ) : (
    <IconButton disabled={disabled} onClick={save} title={t['action.saveAsTemplate']}>
      <Icons.Save />
    </IconButton>
  )
}
