/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { colors } from 'shared/styles/colors'

type TagProps = {
  label: string
  variant: TagVariant
}

export type TagVariant = 'green' | 'blue' | 'orange' | 'grey'

const color: Record<TagVariant, string> = {
  green: colors.actionGreen,
  blue: colors.actionBlue,
  orange: colors.additionalOrange,
  grey: '#999',
}

export const Tag: React.FC<TagProps> = ({ label, variant }) => {
  return <div css={cssTag(variant)}>{label}</div>
}

const cssTag = (variant: TagVariant) => css`
  color: white;
  background: ${color[variant]};
  padding: 2px 8px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
`
