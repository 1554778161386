/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useEffect } from 'react'
import { AutoSizer } from 'react-virtualized'
import * as Api from 'shared/api'
import * as S from 'shared/data/session'
import { Notification } from 'shared/types/Notification'
import { useNotifications } from 'shared/context/notifications'
import { useSession } from 'shared/context/session'
import * as Layout from 'shared/components/Layout'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { H4 } from 'shared/components/Heading'
import { NotificationRender } from 'shared/components/Notification'
import { t } from 'shared/translations'

export const NotificationsList: React.FC = () => {
  const session = useSession()
  const { state, collection, entity } = useNotifications()
  const { pending, data, meta } = state
  const me = S.selectUserId(session.state)

  // Only fetch notifications list if for some reason it hasn't been fetched yet
  const shouldFetch = !data && !pending

  useEffect(() => {
    if (me && shouldFetch) {
      let cancelled = false
      collection.loading()
      Api.fetchNotifications({ users: me })
        .then(res => !cancelled && collection.success(res.notifications, res.meta))
        .catch(err => !cancelled && collection.failure(err))
      return () => {
        cancelled = true
      }
    }
  }, [collection, me, shouldFetch ])

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const markAsRead = (notification: Notification) => {
    entity.success({ ...notification, state: 'read' })
    Api.markAsReadNotification(notification.id)
  }

  return (
    // TODO use normal flex layout with overflow: auto, get rid of AutoSizer
    <AutoSizer disableWidth>
      {({ height }) => (
        <Layout.ScrollableColumn height={height}>
          <H4 css={cssNotificationsTitle}>{t['title.notifications']}</H4>
          {data.map(n => (
            <NotificationRender key={n.id} notification={n} markAsRead={() => markAsRead(n)} />
          ))}
        </Layout.ScrollableColumn>
      )}
    </AutoSizer>
  )
}

const cssNotificationsTitle = css`
  margin: 20px 0 16px;
`
