import React from 'react'

type VideoPlayerProps = {
  src: string
  type?: string
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, type = 'video/mp4' }) => {
  return (
    <video width="800" height="500" controls autoPlay>
      <source src={src} type={type} />
    </video>
  )
}
