/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { H4 } from 'shared/components/Heading'

export const FormSectionHeader: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <header css={cssHeader}>
      <H4 css={cssTitle}>{children}</H4>
    </header>
  )
}

const cssHeader = css`
  padding: 16px 0;
  &:not(:first-of-type) {
    padding-top: 32px;
    font-weight: 800;
  }
`
const cssTitle = css`
  font-weight: 900;
`
