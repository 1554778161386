/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Stm from 'shared/data/settlement'
import * as Inv from 'shared/data/invoice'
import { Settlement } from 'shared/types/Settlement'
import { UUID } from 'shared/types/UUID'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { H3 } from 'shared/components/Heading'
import { CompanyPaymentOptions } from 'shared/views/CompanyPaymentOptions'
import { toDateLabel } from 'shared/utils/date'
import { formatCurrency } from 'shared/utils/money'
import { t } from 'shared/translations'
import { Params } from 'modules/company/routes'

export const CompanySettlementView: React.FC = () => {
  const { match } = useRouter<Params>()
  const { state, entity } = Stm.useEntityReducer()
  const { settlementId, companyId } = match.params
  const { data } = state

  useEffect(() => {
    let cancelled = false
    if (settlementId) {
      entity.loading()
      Api.fetchSettlement(settlementId)
        .then(res => !cancelled && entity.success(res.settlement))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, settlementId])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <SettlementInvoice settlement={data} companyId={companyId} />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}

const SettlementInvoice: React.FC<{ settlement: Settlement; companyId: UUID }> = ({
  settlement,
  companyId,
}) => {
  const { invoice } = settlement
  const { state, entity } = Inv.useEntityReducer()
  const { data } = state

  useEffect(() => {
    let cancelled = false
    if (invoice) {
      entity.loading()
      Api.fetchInvoice(invoice)
        .then(res => !cancelled && entity.success(res.invoice))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, invoice])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  return (
    <div css={cssView}>
      <div css={cssTitle}>
        <H3>
          {t['title.groupInvoice']} {data.number}
        </H3>
      </div>
      <div css={cssBuyer}>
        {data.buyerName && (
          <p>
            <strong>{data.buyerName}</strong>
          </p>
        )}
        {data.buyerAddress && <p>{data.buyerAddress}</p>}
        {data.buyerAccountNumber && <p>{data.buyerAccountNumber}</p>}
        {data.buyerBankName && <p>{data.buyerBankName}</p>}
        {data.buyerNip && (
          <p>
            {t['property.nip']} {data.buyerNip}
          </p>
        )}
      </div>
      <div css={cssSeller}>
        {data.sellerName && (
          <p>
            <strong>{data.sellerName}</strong>
          </p>
        )}
        {data.sellerAddress && <p>{data.sellerAddress}</p>}
        {data.sellerAccountNumber && <p>{data.sellerAccountNumber}</p>}
        {data.sellerBankName && <p>{data.sellerBankName}</p>}
        {data.sellerNip && (
          <p>
            {t['property.nip']} {data.sellerNip}
          </p>
        )}
      </div>
      <div css={cssTable}>
        <table>
          <thead>
            <tr>
              <th>{t['property.index']}</th>
              <th>{t['property.set']}</th>
              <th>{t['property.name']}</th>
              <th>{t['property.access']}</th>
              <th>{t['property.patientFullName']}</th>
              <th>{t['property.unitCost']}</th>
              <th>{t['property.quantity']}</th>
              <th>{t['property.amount']}</th>
            </tr>
          </thead>
          <tbody>
            {data.invoiceItems.map((item, i) => (
              <tr key={item.id}>
                <td>{i + 1}</td>
                <td>{item.setOfExercisesNumber}</td>
                <td>{item.setOfExercisesName}</td>
                <td>
                  {toDateLabel(item.setOfExercisesStartDate.substring(0,10))} do {' '}
                  {toDateLabel(item.setOfExercisesEndDate.substring(0,10))}
                </td>
                <td>{item.patientFullName}</td>
                <td>{formatCurrency(item.unitPrice)}</td>
                <td>{item.quantity}</td>
                <td>{formatCurrency(item.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div css={cssSummary}>
        <p>
          <span>{t['money.net']}</span>
          <span>{formatCurrency(data.netAmount)}</span>
        </p>
        <p>
          <span>{t['money.tax']}</span>
          <span>{formatCurrency(data.vatAmount)}</span>
        </p>
        <p>
          <span>{t['money.gross']}</span>
          <span>{formatCurrency(data.grossAmount)}</span>
        </p>
        <p>
          <span>{t['money.total']}</span>
          <span>{formatCurrency(data.grossAmount)}</span>
        </p>
      </div>
      <CompanyPaymentOptions
        checkAccess={session => (session ? session.userId === companyId : false)}
        settlement={settlement}
        invoice={data}
      />
    </div>
  )
}

const cssView = css`
  overflow-y: auto;
  padding: 1.75rem 1rem;
  font-size: 0.875rem;
  color: #333;
`
const cssTitle = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
const cssBuyer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.875rem;
  & > p {
    margin: 0.25rem 0;
  }
`
const cssSeller = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;
  & > p {
    margin: 0.25rem 0;
  }
`
const cssTable = css`
  padding: 32px 0;
  & > table {
    width: 100%;
    tr {
      height: 40px;
      text-align: left;
    }
  }
`
const cssSummary = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.875rem;
  & > p {
    display: flex;
    flex-direction: row;
    margin: 0.25rem 0;
    & > span:first-of-type {
      display: flex;
      width: 120px;
    }
    & > span:first-of-type {
      display: flex;
      width: 120px;
      min-width: 90px;
      font-weight: bold;
    }
    &:last-of-type {
      margin-top: 16px;
    }
  }
`
