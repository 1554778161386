import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import { Authorize } from 'Auth/Authorize'
import * as Ex from 'shared/data/exercise'
import { checkAccess } from './access'
import { Params, path, link } from './routes'
import { PhysiotherapistProfile } from './pages/Profile'
import { PhysiotherapistPatientsRoutes } from './pages/Patients'
import { PhysiotherapistExercisesRoutes } from './pages/Exercises'

export const Module: React.FC = () => {
  return (
    <Authorize checkAccess={checkAccess}>
      <Ex.ExercisesProvider>
        <Switch>
          <Route exact path={path['/physiotherapist/:physiotherapistId']} component={Entry} />
          <Route
            exact
            path={path['/physiotherapist/:physiotherapistId/profile']}
            component={PhysiotherapistProfile}
          />
          <Route
            path={path['/physiotherapist/:physiotherapistId/patients']}
            component={PhysiotherapistPatientsRoutes}
          />
          <Route
            path={path['/physiotherapist/:physiotherapistId/exercises']}
            component={PhysiotherapistExercisesRoutes}
          />
        </Switch>
      </Ex.ExercisesProvider>
    </Authorize>
  )
}

const Entry = () => {
  const { match } = useRouter<Params>()
  return <Redirect to={link['/physiotherapist/:physiotherapistId/patients'](match.params)} />
}
