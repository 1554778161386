import { Dispatch } from 'react'
import { ServerError } from 'shared/types/Error'
import { Meta } from 'shared/types/Meta'

/**
 * Generic type **RemoteCollection** represents a server/database collection
 * The point is to capture all possible states, taking into account
 * communication with the server. That includes following states:
 * - **initial (idle)** - before anything is fetched
 * - **loading** - no data yet, but request has been sent
 * - **success** - loaded data from server
 * - **failure** - server responded with some error
 * - **reloading** - we have some data already, but have to send a request to refresh it
 *
 * @example
 * RemoteCollection<Patient> // list of patients loaded from the server
 */
export type RemoteCollection<D extends object = any> = {
  pending: boolean
  error?: ServerError
  data?: D[]
  meta?: Meta
}

// ACTIONS

/**
 * **RemoteCollectionAction** represents the following possible things that may happen:
 * - **loading** - we just sent a request. this is probably be a GET request to load the list,
 *   but the important thing here is that it relates to *a collection of objects* in the db
 * - **failure** - server responded with some error.
 * - **success** - server responded with payload containing list of `D`s and a `Meta`
 *
 * We also want to be able to add/update/remove individual items inside the collection.
 * For example when one resource gets edited or activated/deactivated by the user,
 * we want to update this one particular object in our cache.
 * To do that we handle some extra actions related to individual objects.
 */
export type RemoteCollectionAction<D extends object = any> =
  // Collection actions
  | {
      resolved: false
    }
  | {
      resolved: 'FAILURE'
      error: ServerError
    }
  | {
      resolved: 'SUCCESS'
      data: D[]
      meta: Meta
    }
  // Individual object actions
  /**
   * Loaded fresh version of particular object
   * Check if we have the same item in our collection and update it if necessary.
   * This action should match **RemoteEntityAction** Success
   */
  | {
      resolved: 'SUCCESS'
      data: D
    }

export type Loading = () => {
  resolved: false
}

export type Failure = (
  error: ServerError
) => {
  resolved: 'FAILURE'
  error: ServerError
}

export type Success<D extends object = any> = (
  data: D[],
  meta: Meta
) => {
  resolved: 'SUCCESS'
  data: D[]
  meta: Meta
}

export const loading: Loading = () => ({ resolved: false })
export const failure: Failure = error => ({ resolved: 'FAILURE', error })
export const success: Success = (data, meta) => ({ resolved: 'SUCCESS', data, meta })

// DISPATCHERS

export type RemoteCollectionActions<D extends object = any> = {
  loading: () => void
  failure: (error: ServerError) => void
  success: (data: D[], meta: Meta) => void
}

type CreateRemoteCollectionActions<D extends object = any> = (
  dispatch: Dispatch<RemoteCollectionAction<D>>
) => RemoteCollectionActions<D>

export const createCollectionActions: CreateRemoteCollectionActions = dispatch => {
  return {
    loading: () => dispatch(loading()),
    failure: error => dispatch(failure(error)),
    success: (data, meta) => dispatch(success(data, meta)),
  }
}

// REDUCER

/**
 * **RemoteCollectionReducer**
 */
export type RemoteCollectionReducer<D extends object = any> = (
  state: RemoteCollection<D>,
  action: RemoteCollectionAction<D>
) => RemoteCollection<D>

export const remoteCollectionReducer: RemoteCollectionReducer = (state, action) => {
  switch (action.resolved) {
    case false:
      return {
        pending: true,
        ...(state.data && { data: state.data }),
        ...(state.meta && { meta: state.meta }),
      }
    case 'FAILURE':
      return {
        pending: false,
        error: action.error,
      }
    case 'SUCCESS': {
      if ('meta' in action) {
        return {
          pending: false,
          data: action.data,
          meta: action.meta,
        }
      } else {
        return {
          ...state,
          ...(state.data && {
            data: state.data.map(ent => (ent.id === action.data.id ? action.data : ent)),
          }),
        }
      }
    }
    default:
      return state
  }
}

export const idle: RemoteCollection = { pending: false }
