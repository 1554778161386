import React from 'react'
import { t } from 'shared/translations'
import { Authorize } from 'Auth/Authorize'
import { SidebarGroup } from 'shared/components/SidebarGroup'
import { Icons } from 'shared/components/Icons'
import { checkAccess } from './access'
import { useParams, link } from './routes'

export const Sidebar: React.FC = () => {
  const params = useParams()
  if (!params) return null

  return (
    <Authorize checkAccess={checkAccess}>
      <SidebarGroup
        title={t['company']}
        links={[
          {
            icon: <Icons.Assignment />,
            to: link['/company/:companyId/physiotherapists'](params),
            label: t['title.physiotherapists'],
          },
          {
            icon: <Icons.Payment />,
            to: link['/company/:companyId/settlements'](params),
            label: t['title.settlements'],
          },
        ]}
      />
      {/* TODO add fiziotherapist panel */}
    </Authorize>
  )
}
