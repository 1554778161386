/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { FieldArrayRenderProps } from 'formik'
import Grid from '@material-ui/core/Grid'
import MaterialTextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Exercise } from 'shared/types/Exercise'
import { ExerciseParameters } from 'shared/types/ExerciseParameters'
import { FormStepHeader } from 'shared/components/FormSectionHeader'
import { IconButton } from 'shared/components/Button'
import { Icons } from 'shared/components/Icons'
import { ModalSelectExercise } from 'shared/views/modals/ModalSelectExercise'
import { ExerciseLabel } from 'shared/views/labels/ExerciseLabel'
import { useFormStyles } from 'shared/styles/form'
import { colors } from 'shared/styles/colors'

interface SetOfExerciseParametersProps extends FieldArrayRenderProps {}

export const SetOfExercisesParameters: React.FC<SetOfExerciseParametersProps> = props => {
  const exercisesWithParameters: ExerciseParameters[] = props.form.values[props.name]

  const add = (ex: Exercise) => {
    props.push({
      name: ex.name,
      exercise: ex.id,
      estimatedDuration: 7, // min
      restDurationUponComplete: 60, // s
      seriesCount: 3,
      repetitionsPerSeries: 10,
      restDurationBetweenSeries: 30, // s
      targetPoseDuration: 10, // s
    })
  }

  const update = (i: number, params: ExerciseParameters & { name?: string }) => {
    props.replace(i, params)
  }

  const remove = (i: number) => {
    props.remove(i)
  }

  return (
    <div css={cssExerciseParams}>
      {exercisesWithParameters.map((xp: ExerciseParameters, i: number) => (
        <SetOfExercisesStep
          key={i}
          parameters={xp}
          update={parameters => update(i, parameters)}
          remove={() => remove(i)}
        />
      ))}
      <SetOfExercisesExerciseAdd add={add} />
    </div>
  )
}

const SetOfExercisesStep: React.FC<{
  parameters: ExerciseParameters & { name?: string }
  update: (xp: ExerciseParameters & { name?: string }) => void
  remove: () => void
}> = ({ parameters, update, remove }) => {
  const classes = useFormStyles()

  return (
    // TODO try get api to send us "parameters.exerciseName" always, so we don't have to make 1 more request
    <div css={cssStep}>
      <FormStepHeader
        icon={<Icons.PersonOutline />}
        title={parameters.name || <ExerciseLabel id={parameters.exercise} />}
        color={'#999'}
        actions={
          <IconButton onClick={remove}>
            <Icons.Close />
          </IconButton>
        }
      />
      <div css={cssStepContent}>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={6} sm={3} md={2}>
            <MaterialTextField
              label="Czas trwania"
              type="number"
              placeholder="min."
              value={parameters.estimatedDuration}
              onChange={e => update({ ...parameters, estimatedDuration: Number(e.target.value) })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" css={cssAdornment}>
                    <Icons.Alarm />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" css={cssAdornment}>
                    min.
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <MaterialTextField
              label="Liczba serii"
              type="number"
              placeholder="serii"
              value={parameters.seriesCount}
              onChange={e => update({ ...parameters, seriesCount: Number(e.target.value) })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" css={cssAdornment}>
                    <Icons.Refresh />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" css={cssAdornment}>
                    serii
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3} sm={3} md={2}>
            <MaterialTextField
              label="Liczba powtórzeń"
              type="number"
              placeholder="powt."
              value={parameters.repetitionsPerSeries}
              onChange={e =>
                update({ ...parameters, repetitionsPerSeries: Number(e.target.value) })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" css={cssAdornment}>
                    <Icons.Refresh />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" css={cssAdornment}>
                    powt.
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <MaterialTextField
              label="Przerwa między seriami"
              type="number"
              placeholder="sek."
              value={parameters.restDurationBetweenSeries}
              onChange={e =>
                update({ ...parameters, restDurationBetweenSeries: Number(e.target.value) })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" css={cssAdornment}>
                    <Icons.Pause />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" css={cssAdornment}>
                    sek.
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <MaterialTextField
              label="Czas utrzymania pozycji"
              type="number"
              placeholder="sek."
              value={parameters.targetPoseDuration}
              onChange={e => update({ ...parameters, targetPoseDuration: Number(e.target.value) })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" css={cssAdornment}>
                    <Icons.Pose />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" css={cssAdornment}>
                    sek.
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <MaterialTextField
              label="Przerwa po ćwiczeniu"
              type="number"
              placeholder="sek."
              value={parameters.restDurationUponComplete}
              onChange={e =>
                update({ ...parameters, restDurationUponComplete: Number(e.target.value) })
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" css={cssAdornment}>
                    <Icons.Pause />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" css={cssAdornment}>
                    sek.
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const SetOfExercisesExerciseAdd: React.FC<{ add: (ex: Exercise) => void }> = ({ add }) => {
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }
  const cancel = () => {
    setOpen(false)
  }
  const select = (ex: Exercise) => {
    add(ex)
    setOpen(false)
  }

  return (
    <div css={cssFlex}>
      <FormStepHeader icon={'+'} title={'Dodaj ćwiczenie'} onClick={openModal} />
      {open ? <ModalSelectExercise cancel={cancel} select={select} /> : null}
    </div>
  )
}

const cssExerciseParams = css`
  background: #fafafa;
  width: 100%;
  min-height: 180px;
  margin: 16px 0;
  padding: 12px 16px;
`
const cssStep = css`
  margin-bottom: 16px;
`
const cssStepContent = css`
  display: flex;
  flex-direction: row;
  padding-left: 22px;
  margin-left: 16px;
  margin-bottom: 8px;
  border-left: 2px dashed #999;
  & > .MuiGrid-root {
    padding-bottom: 8px !important;
  }
`
const cssAdornment = css`
  color: ${colors.adornmentGrey};
  white-space: nowrap;
`
const cssFlex = css`
  display: flex;
  flex-direction: column;
`
