import { Physiotherapist, PhysiotherapistInput } from 'shared/types/Physiotherapist'
import { FormValidation } from 'shared/types/Form'
import { Entry, EntryInput } from 'shared/types/Entry'
import { UUID } from 'shared/types/UUID'
import { validateEmail } from 'shared/attributes/email'
import { validatePhone } from 'shared/attributes/phone'
import { validateLicenseNumber } from 'shared/attributes/licenseNumber'
import * as In from 'shared/utils/input'

// --- PERSONAL/CONTACT DATA ---

const firstName: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const lastName: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const phone: In.FieldConfig<string> = {
  ...In.TEXT,
  validate: validatePhone,
  required: true,

}

const email: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
  validate: validateEmail,
}

// --- ADDRESS DATA ---

const voivodeship: In.FieldConfig<string> = {
  ...In.TEXT,
}

const city: In.FieldConfig<string> = {
  ...In.TEXT,
}

const street: In.FieldConfig<string> = {
  ...In.TEXT,
}

const houseNumber: In.FieldConfig<string> = {
  ...In.TEXT,
}

const apartmentNumber: In.FieldConfig<string> = {
  ...In.TEXT,
}

// --- WORK RELATED DATA ---

const description: In.FieldConfig<string> = {
  ...In.TEXT,
}

const licenseNumber: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
  validate: validateLicenseNumber,
}

const specialisations: In.FieldConfig<(Entry | EntryInput)[]> = {
  defaultValue: [],
}

/**
 * Physiotherapist's "company" field may sometimes hidden.
 * Superadmin user should have it visible with an option to select a company.
 * For a Company user this field should be preset and read-only.
 */
const company: In.FieldConfig<UUID> = {
  ...In.TEXT,
  required: true,

}

// --- FORM FIELDS ---

const fields: Record<keyof PhysiotherapistInput, In.FieldConfig> = {
  firstName,
  lastName,
  phone,
  email,
  voivodeship,
  city,
  street,
  houseNumber,
  apartmentNumber,
  description,
  licenseNumber,
  specialisations,
  company,
}

// --- FORM HELPERS ---

const _payloadCreate: In.PayloadCreate<PhysiotherapistInput> = In.payloadCreate
const _payloadUpdate: In.PayloadUpdate<Physiotherapist, PhysiotherapistInput> = In.payloadUpdate

export const payloadCreate = _payloadCreate(fields)
export const payloadUpdate = _payloadUpdate(fields)

export const validation: FormValidation<Physiotherapist, PhysiotherapistInput> = {
  getInitialValues: In.getInitialValues(fields),
  validate: In.validate(fields),
}
