/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Status } from 'shared/types/Status'
import * as Layout from 'shared/components/Layout'
import { H4 } from 'shared/components/Heading'
import { ActionLinkBack } from 'shared/views/actions/ActionLinkBack'
import { StatusTag } from 'shared/views/tags/StatusTag'
import { portalWithHeaderActions } from 'shared/views/portals/PortalWithHeaderActions'

type FormHeaderProps = {
  title: string
  back: string
  status?: Status
}

export const FormHeader: React.FC<React.PropsWithChildren<FormHeaderProps>> = ({
  children,
  back,
  title,
  status,
}) => {
  return (
    <Layout.Header
      renderBack={<ActionLinkBack to={back} />}
      renderContent={
        <React.Fragment>
          <Layout.FlexRow css={cssTitleRow}>
            <H4>{title}</H4>
            {status && (
              <div css={cssSpacing}>
                <StatusTag status={status} />
              </div>
            )}
          </Layout.FlexRow>
          <Layout.FlexRow css={cssExtraRow}>{children}</Layout.FlexRow>
        </React.Fragment>
      }
      renderActions={<div id={portalWithHeaderActions} css={cssHeaderActions} />}
    />
  )
}

const cssTitleRow = css`
  min-height: 40px;
  align-items: center;
  flex: 0;
`
const cssExtraRow = css`
  flex: 0;
`
const cssSpacing = css`
  margin: 0 8px;
`
const cssHeaderActions = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
