/** @jsx jsx */
import React, { useState } from 'react'
import * as Api from 'shared/api'
import { jsx, css } from '@emotion/core'
import logo from 'shared/assets/rehabee_logo_small.svg'
import { colors } from 'shared/styles/colors'
import { TextField } from '@material-ui/core'
import { t } from 'shared/translations'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import useRouter from 'use-react-router';

type RouteParams = {
  activationCode: string;
};

export const UpdatePassword: React.FC = () => {
  const router = useRouter<RouteParams>();
  const { activationCode } = router.match.params;
  const [password, setPassword] = useState<string>('')
  const [repassword, setRepassword] = useState<string>('')

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Api.updatePassword(activationCode, password, repassword)
      .then()
      .catch();
  }
  const disabled = !password || !repassword;


  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 30px;
      `}
    >
      <img src={logo} alt="RehaBee" />
      <div
        css={css`
          margin: 40px 0px;
          text-align: center;
        `}
      >
        {t['title.updateDescription']}
      </div>
      <form
        onSubmit={submit}
        css={css`
          width: 300px;
          & > input {
            width: 100%;
          }
        `}
      >
        <TextField
          type="password"
          label={t['property.newPassword']}
          autoFocus
          value={password}
          onChange={e => setPassword(e.target.value)}
          css={css`
            color: ${colors.mainOrange};
            width: 100%;
            padding-bottom: 10px;
          `}

        />
        <TextField
          type="password"
          label={t['property.reNewPassword']}
          value={repassword}
          onChange={e => setRepassword(e.target.value)}
          css={css`
            color: ${colors.mainOrange};
            width: 100%;
          `}

        />

        <button
          type="submit"
          disabled={disabled}
          css={css`
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            background: ${colors.mainOrange};
            color: white;
            text-decoration: none;
            padding: 8px 20px;
            border-radius: 3px;
            border: 1px solid ${colors.mainOrange};
            &[disabled] {
              background: white;
              color: ${colors.mainOrange};
              border-color: ${colors.mainOrange};
              cursor: default;
            }
          `}
        >
          {t['action.setNewPassword']}
        </button>
        <div css={cssResetPassBox}>
          <ResetPassLink to={'/'}>{t['action.backToLogin']}</ResetPassLink>
        </div>
      </form>
    </div>
  )
}

const cssResetPassBox = css`
  margin-top: 20px;
  text-align: center;
`

const ResetPassLink = styled(Link)`
  && {
    text-decoration: underline;
    color: ${colors.mediumGrey};
  }
`
