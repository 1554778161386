import React, { useContext, useEffect } from 'react'
import * as Api from 'shared/api'
import { Exercise } from 'shared/types/Exercise'
import { RemoteCollection, idle } from 'shared/types/RemoteCollection'
import { useCollectionReducer } from './reducers'

// --- OPTIONAL EXERCISES CONTEXT ---

const CachedExercises: React.Context<RemoteCollection<Exercise>> = React.createContext(idle)

// --- PROVIDE EXERCISES ---

export const ExercisesProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { state, collection } = useCollectionReducer()

  useEffect(() => {
    let cancelled = false
    Api.fetchExercises()
      .then(res => !cancelled && collection.success(res.exercises, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = false
    }
  }, [collection])

  return <CachedExercises.Provider value={state}>{children}</CachedExercises.Provider>
}

// --- USE EXERCISES ---

export const useCachedExercises = () => {
  return useContext(CachedExercises)
}
