/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { useConnections } from 'shared/context/connections'
import { Caller } from 'shared/types/Caller'
import * as Cns from 'shared/data/connections'
import { Icons } from 'shared/components/Icons'
import { IconButton } from 'shared/components/Button'
import { t } from 'shared/translations'
import { colors } from 'shared/styles/colors'

type ActionAcceptCallProps = {
  participant: Caller
}

export const ActionAcceptCall: React.FC<ActionAcceptCallProps> = ({ participant }) => {
  const { state, actions } = useConnections()
  const isBusy = Cns.isBusy(state)

  const acceptCall = () => {
    actions.acceptIncomingConnection(participant)
  }

  return (
    <IconButton disabled={isBusy} onClick={acceptCall} title={t['action.call']} css={cssGreen}>
      <Icons.Call />
    </IconButton>
  )
}

const cssGreen = css`
  background: ${colors.additionalGreen} !important;
  color: white !important;
`
