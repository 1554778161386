/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useEffect } from 'react'
import trim from 'lodash/trim'
import * as Api from 'shared/api'
import * as Ex from 'shared/data/exercise'
import { ExerciseParameters } from 'shared/types/ExerciseParameters'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { Icons } from 'shared/components/Icons'
import { AdditionalInfo } from 'shared/components/AdditionalInfo'
import { ActionPlayVideo, ActionPlayVideoPreview } from 'shared/views/actions/ActionPlayVideo'
import { secondsToMinutes } from 'shared/utils/time'
import { t } from 'shared/translations'

type ExerciseExplainedProps = {
  exercisesParameters: ExerciseParameters
}

export const ExerciseExplainedView: React.FC<ExerciseExplainedProps> = ({ exercisesParameters }) => {
  const { state, entity } = Ex.useEntityReducer()
  const { data } = state

  useEffect(() => {
    let cancelled = false
    if (exercisesParameters.exercise) {
      entity.loading()
      Api.fetchExercise(exercisesParameters.exercise)
        .then(res => !cancelled && entity.success(res.exercise))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, exercisesParameters.exercise])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  return (
    <div css={cssExerciseExplainedView}>
      <div css={cssExercisePartsColumn}>
        <div css={cssExercise}>
          <div css={cssIcon}>
            <Icons.Pose />
          </div>
          <div css={cssName}>{data.name}</div>
          {exercisesParameters.estimatedDuration && (
            <AdditionalInfo
              icon={Icons.Pose}
              text={t['exercise.duration'](secondsToMinutes(exercisesParameters.estimatedDuration))}
            />
          )}
          {exercisesParameters.repetitionsPerSeries && (
            <AdditionalInfo
              icon={Icons.Refresh}
              text={t['exercise.repetitionsSeries'](
                exercisesParameters.seriesCount || 1,
                exercisesParameters.repetitionsPerSeries
              )}
            />
          )}
          <AdditionalInfo
            icon={Icons.Pause}
            text={t['exercise.pauseBetweenSeries'](
              secondsToMinutes(exercisesParameters.restDurationBetweenSeries)
            )}
          />
        </div>
        <div css={cssPause}>
          <div css={cssIcon}>
            <Icons.Pause />
          </div>
          <div css={cssName}>{t['exercise.pause']}</div>
          <AdditionalInfo
            icon={Icons.Pause}
            text={t['exercise.pauseAfterComplete'](
              secondsToMinutes(exercisesParameters.restDurationUponComplete)
            )}
          />
        </div>
      </div>
      <div css={cssExerciseInfoColumn}>
        <div css={cssExerciseDescription}>{trim(data.description.substr(0,300))}...</div>
        <div css={cssExerciseVideo}>
          <ActionPlayVideo fileId={exercisesParameters.exercise} renderer={ActionPlayVideoPreview} description={data.description}/>
        </div>
      </div>
    </div>
  )
}

const cssExerciseExplainedView = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-left: 11px;
  padding-right: 16px;
`
const cssExercisePartsColumn = css`
  display: flex;
  flex: 1;
  max-width: 400px;
  flex-direction: column;
  border-left: 2px dashed #ddd;
  padding-left: 24px;
`
const cssExerciseInfoColumn = css`
  display: flex;
  flex: 1;
  font-size: 0.75rem;
  padding: 4px 8px;
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`
const cssExercise = css`
  position: relative;
`
const cssPause = css`
  position: relative;
  margin: 16px 0;
  color: #999;
`
const cssIcon = css`
  position: absolute;
  width: 35px;
  height: 33px;
  left: -43px;
  top: 0;
  border-radius: 40px;
  border: none;
  background: #ddd;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    font-size: 1.5rem;
  }
`
const cssName = css`
  margin-left: 10px;
  margin-bottom: 8px;
  font-size: 1.3rem;
`
const cssExerciseDescription = css`
  display: flex;
  white-space: pre-line;
  flex:1;
`
const cssExerciseVideo = css`
  display: flex;
    flex:1;
    align-items: flex-start;

`
