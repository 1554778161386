import { t } from 'shared/translations'

const VALID_PHONE = /^[0-9]{9}$/

export const validatePhone = (val: any): string | undefined => {
  if (typeof val !== 'string') return t['invalid']
  if (val.length < 9) return t['too_short']
  if (val.length > 9) return t['too_long']
  if (!VALID_PHONE.test(val)) return t['invalid']
}
