import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field, FieldProps } from 'formik'
import * as Cp from 'shared/data/company'
import { FormProps, FormFieldsProps } from 'shared/types/Form'
import { Company, CompanyInput } from 'shared/types/Company'
import { TextField } from 'shared/components/FormField/TextField'
import { useFormStyles } from 'shared/styles/form'
import { t } from 'shared/translations'
import { createForm } from 'shared/components/Form'

const ProfileFormFields: React.FC<FormFieldsProps<CompanyInput>> = ({ fieldValidator }) => {
  const classes = useFormStyles()

  return (
    <Grid container direction="column" className={classes.padding}>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="name"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.name']}
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="phone"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.phone']}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="email"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.email']}
                required={true}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const CompanyProfileForm: React.FC<FormProps<Company, CompanyInput>> = createForm({
  validation: Cp.validation,
  FormFields: ProfileFormFields,
})
