import { Patient, PatientInput } from 'shared/types/Patient'
import { FormValidation } from 'shared/types/Form'
import { Note, NoteInput } from 'shared/types/Note'
import { validatePhone } from 'shared/attributes/phone'
import { validateEmail } from 'shared/attributes/email'
import { validateHeight } from 'shared/attributes/height'
import * as In from 'shared/utils/input'

// --- PERSONAL/CONTACT DATA ---

const firstName: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const lastName: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const phone: In.FieldConfig<string> = {
  ...In.TEXT,
  validate: validatePhone,
}

const email: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
  validate: validateEmail,
}

// --- PATIENT DATA ---

const height: In.FieldConfig<string, number> = {
  ...In.INT,
  required: true,
  validate: validateHeight,
}

const diagnosis: In.FieldConfig<string> = {
  ...In.TEXT,
}

const notes: In.FieldConfig<(Note | NoteInput)[]> = {
  defaultValue: [],
}

// --- FORM FIELDS ---

const fields: Record<keyof PatientInput, In.FieldConfig> = {
  firstName,
  lastName,
  phone,
  email,
  height,
  diagnosis,
  notes,
}

// --- FORM HELPERS ---

const _payloadCreate: In.PayloadCreate<PatientInput> = In.payloadCreate
const _payloadUpdate: In.PayloadUpdate<Patient, PatientInput> = In.payloadUpdate

export const payloadCreate = _payloadCreate(fields)
export const payloadUpdate = _payloadUpdate(fields)

export const validation: FormValidation<Patient, PatientInput> = {
  getInitialValues: In.getInitialValues(fields),
  validate: In.validate(fields),
}
