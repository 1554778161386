/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Session } from 'shared/types/Session'
import { GooglePayButton } from 'shared/components/GooglePayButton'
import { Authorize } from 'Auth/Authorize'

type PatientPaymentOptionsProps = {
  checkAccess: (session?: Session) => boolean
}

export const PatientPaymentOptions: React.FC<PatientPaymentOptionsProps> = ({ checkAccess }) => {
  // TODO FIXME pass payment information. but how to access invoice data from setOfExercises??
  return (
    <Authorize checkAccess={checkAccess}>
      <div css={cssPaymentOptions}>
        <GooglePayButton />
      </div>
    </Authorize>
  )
}

const cssPaymentOptions = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
`
