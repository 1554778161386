/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { H3 } from 'shared/components/Heading'
import { colors } from 'shared/styles/colors'

type ListHeaderProps = {
  title: string
  action?: React.ReactNode
}

export const ListHeader: React.FC<ListHeaderProps> = ({ title, action }) => {
  return (
    <header css={cssHeader}>
      <div css={cssFlex}>
        <H3>{title}</H3>
      </div>
      {action && <div css={cssButton}>{action}</div>}
    </header>
  )
}

// TODO similar styles as in FormHeader
const cssHeader = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 54px;
  height: 54px;
  padding: 40px 10px;
  padding-left: 0;
  background: white;
`
const cssFlex = css`
  display: flex;
  flex-direction: row;
`
const cssButton = css`
  min-width: 230px;
  background: ${colors.mainOrange};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 36px;
  border-radius: 2px;
  height: 80%;
  & a {
    margin: 0 auto;
  }
`
