import { UUID } from 'shared/types/UUID'
import { ConnectionsState } from './state'
import { ConnectionsAction } from './actions'
import * as Chat from 'shared/data/chatConfiguration'

export const initialState: ConnectionsState = {
  incomingConnections: [],
}

export const reducer: React.Reducer<ConnectionsState, ConnectionsAction> = (state, action) => {
  const hasIncomingConnection = (callerId: UUID) =>
    Boolean(state.incomingConnections.find(c => c.id === callerId))

  const hasActiveConnection = (callerId: UUID) =>
    Boolean(state.activeConnection && state.activeConnection.participant.id === callerId)

  const hasConnection = (callerId: UUID) =>
    hasIncomingConnection(callerId) || hasActiveConnection(callerId)

  if ('type' in action) {
    switch (action.type) {
      case 'CONNECTION_START': {
        if (!hasConnection(action.caller.id)) {
          // Receiving a new incoming call.
          // Add it to incoming connection list, from where it can be picked up or rejected.
          return {
            ...state,
            incomingConnections: [...state.incomingConnections, action.caller],
          }
        }
        // We may receive a notification from the server about an outgoing call we are making.
        // In that case there is nothing more to do here.
        return state
      }
      case 'CONNECTION_END': {
        if (hasActiveConnection(action.caller.id)) {
          // Either participant has ended the call.
          // Mark that connection as finished.
          return {
            incomingConnections: state.incomingConnections,
            finishedConnection: action.caller,
          }
        }
        if (hasIncomingConnection(action.caller.id)) {
          // Caller has ended the call before we picked it up.
          // Remove the call from incoming connection list.
          return {
            ...state,
            incomingConnections: state.incomingConnections.filter(c => c.id !== action.caller.id),
            finishedConnection: action.caller,
          }
        }
        // We may receive a notification from the server about us ending the connection.
        // In that case there is nothing more to do here.
        return state
      }
      case 'DISMISS_INCOMING_CONNECTION': {
        if (hasIncomingConnection(action.userId)) {
          // Dismiss incoming connection.
          // NOTE: This only updates our local state. Make sure to hang up connection on the server too!
          return {
            ...state,
            incomingConnections: state.incomingConnections.filter(c => c.id !== action.userId),
          }
        }
        return state
      }
      case 'ACCEPT_INCOMING_CONNECTION': {
        if (!hasActiveConnection(action.caller.id)) {
          // Accept an incoming call.
          // Make sure we clean it up from incoming connection list.
          // Dismiss any finished connection popup too.
          return {
            activeConnection: {
              participant: action.caller,
              chat: { pending: false },
              outgoing: false,
            },
            incomingConnections: state.incomingConnections.filter(c => c.id !== action.caller.id),
          }
        }
        return state
      }
      case 'CALL_UP': {
        if (!hasActiveConnection(action.caller.id)) {
          // Make an outgoing call.
          // Make sure we clean it up from incoming connection list, if needed.
          // Dismiss any finished connection popup too.
          return {
            activeConnection: {
              participant: action.caller,
              chat: { pending: false },
              outgoing: true,
            },
            incomingConnections: state.incomingConnections.filter(c => c.id !== action.caller.id),
          }
        }
        return state
      }
      case 'DISCONNECT': {
        if (hasActiveConnection(action.userId)) {
          // Terminate active connection.
          // Since we chose to close it, we don't bother showing it as a finished connection.
          // NOTE: This only updates our local state. Make sure to hang up connection on the server too!
          return {
            incomingConnections: state.incomingConnections,
          }
        }
        return state
      }
      case 'DISMISS_FINISHED': {
        if (state.finishedConnection) {
          // Dismiss modal about finished connection
          return {
            activeConnection: state.activeConnection,
            incomingConnections: state.incomingConnections,
          }
        }
        return state
      }
      default: {
        return state
      }
    }
  } else {
    // Delegate updating details of chat connection to chat's reducer
    // This will be
    if (state.activeConnection) {
      return {
        ...state,
        activeConnection: {
          ...state.activeConnection,
          chat: Chat.reducer(state.activeConnection.chat, action),
        },
      }
    }
    return state
  }
}
