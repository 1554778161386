import React from 'react'
import { useSession } from 'shared/context/session'
import { Session } from 'shared/types/Session'

type AuthorizeProps = {
  checkAccess: (session?: Session) => boolean
  renderNoAccess?: React.ReactNode
}

/**
 * Wrap stuff in <Authorize checkAccess={checkAccess} /> to make sure it will only be seen
 * by users that satisfy `checkAccess` predicate.
 * 
 * @example
 * <Authorize checkAccess={session => isSuperAdmin(session)}>
 *   <div>Only superadmin will see this</div>
 * </Authorize>
 */
export const Authorize: React.FC<React.PropsWithChildren<AuthorizeProps>> = ({
  children,
  checkAccess,
  renderNoAccess,
}) => {
  const { state } = useSession()
  const hasAccess = checkAccess(state.session)

  return <React.Fragment>{hasAccess ? children : renderNoAccess || null}</React.Fragment>
}
