import { UUID } from 'shared/types/UUID'
import { Session } from 'shared/types/Session'
import { SessionState } from './state'

export const select = (s: SessionState) => s.session

export const isAuthenticated = (s: SessionState) => Boolean(s.session)
export const isVerifyingCredentials = (s: SessionState) => s && s.pending === true
export const isVerifyingToken = (s: SessionState) => typeof s.pending === 'string'

export const selectToken = (s: SessionState) =>
  typeof s.pending === 'string' ? s.pending : s.session && s.session.token

export const selectRole = (s: SessionState) => s.session && s.session.role
export const selectUserId = (s: SessionState) => s.session && s.session.userId
export const selectError = (s: SessionState) => s.error

export const isMe = (s: SessionState) => (id: UUID) => selectUserId(s) === id

export const isPatient = (s?: Session) => Boolean(s && s.role === 'patient')
export const isPhysiotherapist = (s?: Session) => Boolean(s && s.role === 'physiotherapist')
export const isCompany = (s?: Session) => Boolean(s && s.role === 'company')
export const isSuperAdmin = (s?: Session) => Boolean(s && s.role === 'superadmin')
