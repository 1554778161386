import {
  FetchMany,
  FetchOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
  PatchOne,
  fetchMany,
  fetchOne,
  createOne,
  updateOne,
  deleteOne,
  activateOne,
  deactivateOne,
} from 'shared/api/crud'
import { Entity } from 'shared/types/Entity'
import { Meta } from 'shared/types/Meta'
import { Company, CompanyInput, CompaniesQuery } from 'shared/types/Company'

const resource = 'companies'

export type CompaniesPayload = { companies: Company[]; meta: Meta }
export type CompanyPayload = { company: Company }

export const fetchCompanies: FetchMany<CompaniesPayload, CompaniesQuery> = fetchMany(resource)
export const fetchCompany: FetchOne<CompanyPayload> = fetchOne(resource)
export const createCompany: CreateOne<CompanyPayload, CompanyInput> = createOne(resource)
export const updateCompany: UpdateOne<CompanyPayload, CompanyInput & Entity> = updateOne(resource)
export const deleteCompany: DeleteOne = deleteOne(resource)
export const activateCompany: PatchOne<CompanyPayload> = activateOne(resource)
export const deactivateCompany: PatchOne<CompanyPayload> = deactivateOne(resource)
