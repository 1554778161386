/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { ButtonLink } from 'shared/components/Link'

type EmptyListProps = {
  title: string
  hint?: string
  link?: { label: string; to: string }
}

export const EmptyList: React.FC<React.PropsWithChildren<EmptyListProps>> = ({
  children,
  title,
  hint,
  link,
}) => {
  return (
    <div css={cssEmptyList}>
      <h2>{title}</h2>
      {hint && <p css={cssHint}>{hint}</p>}
      {link && (
        <div css={cssLink}>
          <ButtonLink to={link.to}>{link.label}</ButtonLink>
        </div>
      )}
      {children}
    </div>
  )
}

const cssEmptyList = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const cssHint = css`
  font-size: 0.875rem;
  white-space: pre-line;
`
const cssLink = css`
  margin-top: 24px;
  margin-bottom: 16px;
`
