import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Pt from 'shared/data/patient'
import { Patient } from 'shared/types/Patient'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { ButtonLink } from 'shared/components/Link'
import { EmptyList } from 'shared/components/EmptyList'
import { ListHeader } from 'shared/components/ListHeader'
import { ListFilters } from 'shared/components/ListFilters'
import { TableRowActions } from 'shared/components/TableRowActions'
import { PatientsList } from 'shared/views/PatientsList'
import { ActionLinkEdit } from 'shared/views/actions/ActionLinkEdit'
import { ActionCall } from 'shared/views/actions/ActionCall'
import { t } from 'shared/translations'
import { link } from 'modules/routes'
import { Params } from 'modules/physiotherapist/routes'
import { css } from '@emotion/core'

export const PhysiotherapistPatients: React.FC = () => {
  const { match } = useRouter<Params>()
  const { state, collection } = Pt.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy, updateStatus } = Pt.useQueries()
  const { physiotherapistId } = match.params

  useEffect(() => {
    let cancelled = false
    if (physiotherapistId) {
      collection.loading()
      Api.fetchPatients({ ...query, physiotherapists: [physiotherapistId] })
        .then(res => !cancelled && collection.success(res.patients, res.meta))
        .catch(err => !cancelled && collection.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [collection, query, physiotherapistId])

  const noPatientsYet = state.data && state.data.length === 0 && !query.searchTerm && !query.states
  if (noPatientsYet) {
    return (
      <EmptyList
        title={t['patients.empty']}
        hint={t['patients.empty.hint']}
        link={{
          label: t['patients.add'],
          to: link['/physiotherapist/:physiotherapistId/patients/add'](match.params),
        }}
      />
    )
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <ListHeader
          title={t['title.patients']}
          action={
            <ButtonLink to={link['/physiotherapist/:physiotherapistId/patients/add'](match.params)}>
              {t['title.patient.add']}
            </ButtonLink>
          }
        />
        <ListFilters
          searchTerm={query.searchTerm || ''}
          status={query.status}
          searchBy={searchBy}
          updateStatus={updateStatus}
        />
        <PatientsList css={patientList}
          state={state}
          order={query.order}
          loadNext={loadNext}
          sortBy={sortBy}
          rowLink={patient =>
            link['/physiotherapist/:physiotherapistId/patients/:patientId']({
              physiotherapistId,
              patientId: patient.id,
            })
          }
          renderActions={props => {
            const p = props.rowData as Patient
            return (
              <TableRowActions>
                <ActionLinkEdit
                  to={link['/physiotherapist/:physiotherapistId/patients/:patientId/edit']({
                    physiotherapistId,
                    patientId: p.id,
                  })}
                />
                <ActionCall participant={p} />
              </TableRowActions>
            )
          }}
        />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}

const patientList = css`
  outline: none!important;
  &&focus{
    outline: none!important;

  }
`