import { SetOfExercises } from 'shared/types/SetOfExercises'
import { UseRemoteEntityReducer, useRemoteEntityReducer } from 'shared/hooks/useRemoteEntityReducer'
import {
  UseRemoteCollectionReducer,
  useRemoteCollectionReducer,
} from 'shared/hooks/useRemoteCollectionReducer'

export const useEntityReducer: UseRemoteEntityReducer<SetOfExercises> = useRemoteEntityReducer
export const useCollectionReducer: UseRemoteCollectionReducer<
  SetOfExercises
> = useRemoteCollectionReducer
