import React, { useContext } from 'react'
import { Patient } from 'shared/types/Patient'

// --- OPTIONAL PATIENT CONTEXT ---

let PatientContext: React.Context<Patient>

// --- PROVIDE PATIENT ---

/**
 * There is probably no point in using PatientContext unless we actually have a Patient.
 * Because of this let's make it a required prop, so that consumers won't have to be needlessly defensive.
 */
export const PatientProvider: React.FC<React.PropsWithChildren<{ patient: Patient }>> = ({
  children,
  patient,
}) => {
  PatientContext = React.createContext(patient)
  return <PatientContext.Provider value={patient}>{children}</PatientContext.Provider>
}

// --- USE PATIENT ---

export const useCachedPatient = () => {
  if (typeof PatientContext === 'undefined') {
    throw new Error(`--- Missing PatientContext ---
      "usePatient()" hook can only be used when PatientContext has been provided using

      <PatientProvider patient={patient}>
        /* usePatient() can only be used here */
      </PatientProvider>

      This means we should have a Patient object available, but instead got ${PatientContext}.
    `)
  }
  return useContext(PatientContext)
}
