import React from 'react'
import { IconButton, FooterButton } from 'shared/components/Button'
import { Icons } from 'shared/components/Icons'
import { t } from 'shared/translations'

type ActionResetPasswordProps = {
  resetPassword: () => void
  disabled?: boolean
  renderAsButton?: boolean
}

export const ActionResetPassword: React.FC<ActionResetPasswordProps> = ({
  resetPassword,
  disabled,
  renderAsButton,
}) => {
  return renderAsButton ? (
    <FooterButton disabled={disabled} onClick={resetPassword} title={t['action.changePassword']}>
      <Icons.Lock />
      {t['action.changePassword']}
    </FooterButton>
  ) : (
    <IconButton disabled={disabled} onClick={resetPassword} title={t['action.changePassword']}>
      <Icons.Lock />
    </IconButton>
  )
}
