import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Pt from 'shared/data/patient'
import * as Layout from 'shared/components/Layout'
import { FormFooter } from 'shared/components/FormFooter'
import { ContactInfo } from 'shared/components/ContactInfo'
import { Tabs } from 'shared/components/Tabs'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { FormHeader } from 'shared/views/headers/FormHeader'
import { t } from 'shared/translations'
import { PhysiotherapistPatientTabEdit } from './PatientTabEdit'
import { PhysiotherapistPatientTabSets } from './PatientTabSets'
import { PhysiotherapistPatientTabSetsAdd } from './PatientTabSetsAdd'
import { PhysiotherapistPatientTabSetsEdit } from './PatientTabSetsEdit'
import { PhysiotherapistPatientTabHistory } from './PatientTabHistory'
import { link, path } from 'modules/routes'
import { Params } from 'modules/physiotherapist/routes'

export const PhysiotherapistPatientRoutes: React.FC = () => {
  const { match } = useRouter<Params>()
  const { state, entity } = Pt.useEntityReducer()
  const { data } = state
  const { patientId, setId } = match.params
  const back = setId // tweak back-navigation on exercise set edit form
    ? link['/physiotherapist/:physiotherapistId/patients/:patientId/sets'](match.params) // back to exercise sets timeline
    : link['/physiotherapist/:physiotherapistId/patients'](match.params) // back to list of patients

  useEffect(() => {
    let cancelled = false
    if (patientId) {
      entity.loading()
      Api.fetchPatient(patientId)
        .then(res => !cancelled && entity.success(res.patient))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, patientId])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const tabs = [
    {
      label: t['title.setOfExercises'],
      to: link['/physiotherapist/:physiotherapistId/patients/:patientId/sets'](match.params),
    },
    {
      label: t['title.exercisesHistory'],
      to: link['/physiotherapist/:physiotherapistId/patients/:patientId/history'](match.params),
    },
    {
      label: t['title.information'],
      to: link['/physiotherapist/:physiotherapistId/patients/:patientId/edit'](match.params),
    },
  ]

  return (
    <Layout.FlexColumn>
      <FormHeader back={back} title={`${data.firstName} ${data.lastName}`} status={data.state}>
        <ContactInfo person={data} />
      </FormHeader>
      <Tabs tabs={tabs} />
      <Pt.PatientProvider patient={data}>
        <Switch>
          <Route
            exact
            path={path['/physiotherapist/:physiotherapistId/patients/:patientId']}
            component={() => (
              <Redirect
                to={link['/physiotherapist/:physiotherapistId/patients/:patientId/sets'](
                  match.params
                )}
              />
            )}
          />
          <Route
            exact
            path={path['/physiotherapist/:physiotherapistId/patients/:patientId/sets']}
            component={PhysiotherapistPatientTabSets}
          />
          <Route
            exact
            path={path['/physiotherapist/:physiotherapistId/patients/:patientId/sets/add']}
            component={PhysiotherapistPatientTabSetsAdd}
          />
          <Route
            exact
            path={path['/physiotherapist/:physiotherapistId/patients/:patientId/sets/:setId']}
            component={PhysiotherapistPatientTabSetsEdit}
          />
          <Route
            exact
            path={path['/physiotherapist/:physiotherapistId/patients/:patientId/history']}
            component={PhysiotherapistPatientTabHistory}
          />
          <Route
            exact
            path={path['/physiotherapist/:physiotherapistId/patients/:patientId/edit']}
            component={PhysiotherapistPatientTabEdit}
          />
        </Switch>
      </Pt.PatientProvider>
      <FormFooter />
    </Layout.FlexColumn>
  )
}
