export const secondsToMinutes = (seconds?: number): number => {
  return Math.round((seconds || 0) / 60)
}

/**
 * Format time in seconds into a readable format (hours, minutes, seconds)
 * @example
 * formatTimer(13) == '00:13'
 * formatTimer(125) == '02:05'
 */
export const formatTimer = (seconds: number): string => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = seconds % 60

  return h > 0 ? `${h}:${pad2(m)}:${pad2(s)}` : `${pad2(m)}:${pad2(s)}`
}

const pad2 = (value: number): string => {
  return value < 10 ? [0, value].join('') : String(value)
}

/**
 * Format duration time in seconds into a readable format (minutes + seconds)
 * @example
 * formatTimer(13) == '13s'
 * formatTimer(125) == '2 min. 5s'
 */
export const formatDuration = (seconds: number): string => {
  const m = Math.floor((seconds % 3600) / 60)
  const s = seconds % 60

  return m > 0 ? `${m} min. ${s}s` : `${s}s`
}
