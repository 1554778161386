/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Availability } from 'shared/types/Availability'
import { colors } from 'shared/styles/colors'

type AvailabilityDotProps = {
  availability: Availability
}

export const AvailabilityDot: React.FC<AvailabilityDotProps> = ({ availability }) => {
  const dot = dotStyle[availability]

  return <div css={cssDot(dot)}></div>
}

type DotStyle = {
  color: string
  isFull: boolean
}

const dotStyle: Record<Availability, DotStyle> = {
  offline: { color: colors.mediumGrey, isFull: true },
  inactive: { color: colors.actionGreen, isFull: false },
  online: { color: colors.actionGreen, isFull: true },
  busy: { color: colors.actionRed, isFull: true },
}

const cssDot = (dot: DotStyle) => css`
  width: 20px;
  height: 20px;
  border: 4px solid ${dot.color};
  border-radius: 20px;
  background: ${dot.isFull ? dot.color : 'transparent'};
  position: absolute;
  top: 90px;
  left: 100px;
`
