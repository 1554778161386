import React from 'react'
import * as Stm from 'shared/data/settlement'
import { ListProps } from 'shared/types/List'
import { Settlement } from 'shared/types/Settlement'
import { Column } from 'shared/types/Table'
import { Wrapper } from 'shared/components/Wrapper'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { PaymentStatusText } from 'shared/views/text/PaymentStatusText'
import { createScrollHandler } from 'shared/utils/scroll'
import { toDateLabel } from 'shared/utils/date'
import { formatCurrency } from 'shared/utils/money'
import { t } from 'shared/translations'
import { SettlementsTable } from './SettlementsTable'

export const SettlementsList: React.FC<ListProps<Settlement>> = ({
  state,
  order,
  loadNext,
  sortBy,
  rowLink,
  renderActions,
}) => {
  const { data, meta } = state
  const [[key, value = 'asc']] = Object.entries(order || Stm.defaultOrder)

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const handleScroll = createScrollHandler({ data, meta, loadNext })

  const columns = renderActions
    ? [...dataColumns, { ...actionColumn, cellRenderer: renderActions }]
    : dataColumns

  return (
    <Wrapper>
      <SettlementsTable
        columns={columns}
        rowCount={data.length}
        rowGetter={({ index }) => data[index]}
        rowLink={rowLink}
        handleScroll={handleScroll}
        handleRequestSort={sortBy}
        orderKey={key as keyof Settlement}
        orderValue={value}
      />
    </Wrapper>
  )
}

const dataColumns: Column<Settlement>[] = [
  {
    id: 'month',
    label: t['property.month'],
    width: 300,
    sortable: true,
  },
  {
    id: 'settlementPeriodStartDate',
    label: t['property.billingPeriod'],
    width: 500,
    sortable: false,
    cellRenderer: props => {
      const p = props.rowData as Settlement
      return t['date.range'](
        toDateLabel(p.settlementPeriodStartDate.substring(0,10)),
        toDateLabel(p.settlementPeriodEndDate.substring(0,10))
      )
    },
  },
  {
    id: 'weeksNumber',
    label: t['property.weekCounter'],
    width: 240,
    sortable: false,
    cellRenderer: props => {
      const p = props.rowData as Settlement
      return t['period.weeks'](p.weeksNumber)
    },
  },
  {
    id: null,
    label: t['property.amount'],
    width: 240,
    sortable: false,
    cellRenderer: props => {
      const p = props.rowData as Settlement
      return formatCurrency(p.amount)
    },
  },
  {
    id: 'state',
    label: t['property.state'],
    width: 200,
    minWidth: 80,
    sortable: false,
    cellRenderer: props => {
      const p = props.rowData as Settlement
      return <PaymentStatusText status={p.state} />
    },
  },
]

const actionColumn: Column<Settlement> = {
  id: 'actions',
  label: '',
  width: 200,
  minWidth: 100,
}
