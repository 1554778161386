/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { Icons } from 'shared/components/Icons'
import { IconLink, ButtonLink } from 'shared/components/Link'
import { t } from 'shared/translations'

type ActionLinkEditProps = {
  to: string
  renderAsButton?: boolean
}

export const ActionLinkEdit: React.FC<ActionLinkEditProps> = ({ to, renderAsButton }) => {
  return renderAsButton ? (
    <ButtonLink to={to} aria-label={t['action.edit']} css={cssButton}>
      <Icons.Edit />
      Edytuj w kreatorze{/** TODO translate */}
    </ButtonLink>
  ) : (
    <Tooltip title={t['action.edit']}>
      <IconLink to={to} aria-label={t['action.edit']}>
        <Icons.Edit />
      </IconLink>
    </Tooltip>
  )
}

const cssButton = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  color: #666;
  font-size: 0.875rem;
  & > svg {
    margin: 0 8px;
  }
`
