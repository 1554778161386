import { t } from 'shared/translations'

const VALID_NIP = /^[0-9]{10}$/

export const validateNIP = (val: any): string | undefined => {
  let str = val
  if (typeof val === 'number') {
    str = String(val)
  }
  if (typeof str !== 'string') return t['invalid']
  if (!VALID_NIP.test(str)) return t['invalid']
}
