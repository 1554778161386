import { apiUrl } from '../config'
import { request } from '../utils'
import { FetchOne, PatchOne, fetchOne, patchOne } from 'shared/api/crud'
import { Availability } from 'shared/types/Availability'

const resource = 'users'

export const resetPassword = async (email: string): Promise<void> => {
  return request.post(`${apiUrl}/${resource}/resetPassword`, { email })
}

export const updatePassword = async (
  activationCode: string,
  password: string,
  repassword: string
) => {
  return request.post(`${apiUrl}/${resource}/updatePassword/${activationCode}`, {
    activationCode,
    password,
    repassword,
  })
}


type UserAvailabilityPayload = {
  user: { availability: Availability }
}

export const getUserAvailability: FetchOne<UserAvailabilityPayload> = fetchOne(
  userId => `${resource}/${userId}/availability`
)

export const setUserStatusOnline: PatchOne<any> = patchOne(userId => `${resource}/${userId}/online`)

export const setUserStatusOffline: PatchOne<any> = patchOne(
  userId => `${resource}/${userId}/offline`
)

export const setUserStatusInactive: PatchOne<any> = patchOne(
  userId => `${resource}/${userId}/inactive`
)
