/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'

type TextProps = {
  label: string
  variant?: TextVariant
}

export type TextVariant = 'green' | 'blue' | 'orange' | 'grey'

// TODO use correct colors
const color: Record<TextVariant, string> = {
  green: 'limegreen',
  blue: 'dodgerblue',
  orange: 'orange',
  grey: 'grey',
}

export const Text: React.FC<TextProps> = ({ label, variant }) => {
  return <div css={cssText(variant)}>{label}</div>
}

const cssText = (variant?: TextVariant) => css`
  color: ${variant ? color[variant] : 'inherit'};
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.875rem;
`
