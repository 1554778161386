import mapValues from 'lodash/mapValues'
import { CreateLink, createLink, prefix } from 'shared/utils/url'

export type Params = {
  physiotherapistId?: string
  patientId?: string
  companyId?: string
}

const routes = {
  '/superadmin': undefined,
  '/superadmin/profile': undefined,
  '/superadmin/companies': undefined,
  '/superadmin/companies/add': undefined,
  '/superadmin/companies/:companyId': undefined,
  '/superadmin/physiotherapists': undefined,
  '/superadmin/physiotherapists/add': undefined,
  '/superadmin/physiotherapists/:physiotherapistId': undefined,
  '/superadmin/patients': undefined,
  '/superadmin/patients/add': undefined,
  '/superadmin/patients/:patientId': undefined,
} as const

export const path = mapValues(routes, (v, k) => prefix + k)
export const link = mapValues(routes, (v, k) => (createLink as CreateLink<Params>)(k))

export const root = prefix + '/superadmin'
export const entry = '/superadmin'
