import { isBefore, startOfToday } from 'date-fns'
import {
  SetOfExercises,
  SetOfExercisesInput,
  SetOfExercisesInputNormalized,
} from 'shared/types/SetOfExercises'
import { FormValidation } from 'shared/types/Form'
import { Ocurrence, OcurrenceInput } from 'shared/types/Ocurrence'
import { ExerciseParameters } from 'shared/types/ExerciseParameters'
import * as In from 'shared/utils/input'
import { getUTCDatesWithinRange, getEndDate, createOcurrence } from 'shared/utils/date'
import { t } from 'shared/translations'

// --- SET OF EXERCISES FIELDS ---

const name: In.FieldConfig<string> = {
  ...In.TEXT,
  required: true,
}

const dateStart: In.FieldConfig<Date, string> = {
  ...In.DATE,
  required: true,
}

const duration: In.FieldConfig<number> = {
  required: true,
  defaultValue: 1,
  validate: (value, values) => {
    if (value < 1 || value % 1 !== 0) return t['invalid']

    // Make sure messing with duration doesn't cause end date to end up in the past!
    // (that may happen while editing exercise set after some time has passed since start date)
    const endDay = getEndDate(values.dateStart, value)
    if (isBefore(endDay, startOfToday())) return t['invalid']
  },
}

const onceDay: In.FieldConfig<number> = {
  required: true,
  defaultValue: 1,
  validate: value => {
    if (value < 1 || value % 1 !== 0) return t['invalid']
  },
}

const everyNumberDay: In.FieldConfig<number> = {
  defaultValue: 1,
  validate: value => {
    if (value < 1 || value % 1 !== 0) return t['invalid']
  },
}

const custom: In.FieldConfig<boolean> = {
  defaultValue: false,
}

const frequencies: In.FieldConfig<(Ocurrence | OcurrenceInput)[]> = {
  defaultValue:
    dateStart.defaultValue && duration.defaultValue
      ? getUTCDatesWithinRange(
          dateStart.defaultValue,
          duration.defaultValue,
          everyNumberDay.defaultValue || 1
        ).map(createOcurrence)
      : [],
  required: true,
}

const exercisesParameters: In.FieldConfig<ExerciseParameters[]> = {
  defaultValue: [],
  required: true,
  validate: value => {
    if (value.length === 0) return t['not_blank']
  },
}

// --- FORM FIELDS ---

const fields: Record<keyof SetOfExercisesInput, In.FieldConfig> = {
  name,
  dateStart,
  duration,
  onceDay,
  everyNumberDay,
  custom,
  frequencies,
  exercisesParameters,
}


// --- FORM HELPERS ---

const _payloadCreate: In.PayloadCreate<SetOfExercisesInput, SetOfExercisesInputNormalized> =
  In.payloadCreate
const _payloadUpdate: In.PayloadUpdate<SetOfExercises, SetOfExercisesInput> = In.payloadUpdate

export const payloadCreate = _payloadCreate(fields)
export const payloadUpdate = _payloadUpdate(fields)

export const validation: FormValidation<SetOfExercises, SetOfExercisesInput> = {
  getInitialValues: In.getInitialValues(fields),
  validate: In.validate(fields),
}
