import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { PhysiotherapistPatients } from './Patients'
import { PhysiotherapistPatientAdd } from './PatientAdd'
import { PhysiotherapistPatientRoutes } from './PatientPage'
import { path } from 'modules/routes'

export const PhysiotherapistPatientsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={path['/physiotherapist/:physiotherapistId/patients']}
        component={PhysiotherapistPatients}
      />
      <Route
        exact
        path={path['/physiotherapist/:physiotherapistId/patients/add']}
        component={PhysiotherapistPatientAdd}
      />
      <Route
        path={[
          path['/physiotherapist/:physiotherapistId/patients/:patientId/sets/:setId'],
          path['/physiotherapist/:physiotherapistId/patients/:patientId'],
        ]}
        component={PhysiotherapistPatientRoutes}
      />
    </Switch>
  )
}
