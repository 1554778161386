export const colors = {
  //Main colors
  mainOrange: '#FF6247', //buttons,links
  secondaryLitePink: '#FFE8E4',

  //Grey shades
  adornmentGrey: 'rgba(0,0,0,0.54)', // Matches Material-UI input labels and borders
  mediumDarkGrey: '#666666',
  mediumGrey: '#CCCCCC',
  lightMediumGrey: '#FAFAFA',
  listGrey: '#F2F2F2',
  lightGrey: '#E9E9E9',
  lightestGrey: '#FFFFFF',
  searchBarGrey: '#EAEAEA',

  //Action colors
  actionBlue: '#1A73E9',
  actionGreen: '#00b060', // '#00C851',
  actionYellow: '#FFCC00',
  actionRed: '#FF0000',

  //Additional colors
  additionalBlue: '#039BE5',
  additionalGreen: '#00CC99',
  additionalOrange: '#FF9900',
  additionalRed: '#FF6666',
}
