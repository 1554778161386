import React from 'react'
import { Problem } from 'shared/components/Problem'
import { explain } from 'shared/utils/error'

type ErrorBoundaryProps = React.PropsWithChildren<any>

type ErrorBoundaryState = {
  errorMessage?: string
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { errorMessage: undefined }
  }

  static getDerivedStateFromError(error: any) {
    return { errorMessage: explain(error) }
  }

  render() {
    if (this.state.errorMessage) {
      return <Problem error={this.state.errorMessage} />
    }
    return this.props.children
  }
}
