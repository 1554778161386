import { Settlement } from 'shared/types/Settlement'
import { UseRemoteEntityReducer, useRemoteEntityReducer } from 'shared/hooks/useRemoteEntityReducer'
import {
  UseRemoteCollectionReducer,
  useRemoteCollectionReducer,
} from 'shared/hooks/useRemoteCollectionReducer'

export const useEntityReducer: UseRemoteEntityReducer<Settlement> = useRemoteEntityReducer
export const useCollectionReducer: UseRemoteCollectionReducer<
  Settlement
> = useRemoteCollectionReducer
