/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { jsx, css } from '@emotion/core'
import { colors } from 'shared/styles/colors'

const iconSizes = {
  normal: {
    width: 24,
    height: 24,
    left: -14 /* extra 2 px = half of dashed border */,
    font: 1,
  },
  large: {
    width: 32,
    height: 32,
    left: -18 /* extra 2 px = half of dashed border */,
    font: 1.5,
  },
}

const iconColors = {
  grey: '#999',
  green: colors.actionGreen,
  orange: colors.mainOrange,
}

type EntryIconProps = {
  icon: React.ReactNode
  size: IconSize
  variant: IconVariant
}

type IconSize = 'normal' | 'large'
type IconVariant = 'grey' | 'green' | 'orange'

export const TimelineEntryIcon: React.FC<EntryIconProps> = ({ icon, size, variant }) => {
  return <div css={cssTimelineEntryIcon({ size, variant })}>{icon}</div>
}

const cssTimelineEntryIcon = ({ size, variant }: { size: IconSize; variant: IconVariant }) => css`
  position: absolute;
  width: ${iconSizes[size].width}px;
  height: ${iconSizes[size].height}px;
  left: ${iconSizes[size].left}px;
  top: 0;
  border-radius: 40px;
  border: none;
  background: ${iconColors[variant]};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    font-size: ${iconSizes[size].font}rem;
  }
`
export const TimelineEntryTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & > div {
    display: flex;
    font-size: 0.75rem;
  }
`
export const TimelineEntryContent = styled.div`
  font-size: .7rem;
  padding-top: 5px;
  color: #999;
`
