import React from 'react'
import { TagVariant, Tag } from 'shared/components/Tag'
import { t } from 'shared/translations'

type SetOfExercisesTagProps = {
  status?: 'completed' | 'failed'
}

const statusTagVariant: Record<'completed' | 'failed', TagVariant> = {
  completed: 'green',
  failed: 'orange',
}

export const SetOfExercisesTag: React.FC<SetOfExercisesTagProps> = ({ status }) => {
  return status ? (
    <Tag label={t[status]} variant={statusTagVariant[status]} />
  ) : (
    <Tag label={t['to_do']} variant={'blue'} />
  )
}
