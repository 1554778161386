import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { Field, FieldProps } from 'formik'
import * as Ft from 'shared/data/physiotherapist'
import * as S from 'shared/data/session'
import { FormProps, FormFieldsProps } from 'shared/types/Form'
import { Physiotherapist, PhysiotherapistInput } from 'shared/types/Physiotherapist'
import { FormSectionHeader } from 'shared/components/FormSectionHeader'
import { TextField } from 'shared/components/FormField/TextField'
import { EntriesField } from 'shared/views/fields/EntriesField'
import { CompaniesSelect } from 'shared/views/CompaniesSelect'
import { useFormStyles } from 'shared/styles/form'
import { t } from 'shared/translations'
import { createForm } from 'shared/components/Form'
import { Authorize } from 'Auth/Authorize'

const PhysiotherapistFormFields: React.FC<FormFieldsProps<PhysiotherapistInput>> = ({
  formik,
  fieldValidator,
}) => {
  const classes = useFormStyles()

  return (
    <Grid container direction="column" className={classes.padding}>
      <FormSectionHeader>{t['section.basic']}</FormSectionHeader>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="firstName"
            render={(field: FieldProps) => (
              <TextField
                {...field}
                state={fieldValidator(field)}
                label={t['property.firstName']}
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="lastName"
            render={(field: FieldProps) => (
              <TextField
                {...field}
                state={fieldValidator(field)}
                label={t['property.lastName']}
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="phone"
            render={(field: FieldProps) => (
              <TextField
                {...field}
                state={fieldValidator(field)}
                label={t['property.phone']}
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="email"
            render={(field: FieldProps) => (
              <TextField
                {...field}
                state={fieldValidator(field)}
                label={t['property.email']}
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="voivodeship"
            render={(field: FieldProps) => (
              <TextField
                {...field}
                state={fieldValidator(field)}
                label={t['property.voivodeship']}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="city"
            render={(field: FieldProps) => (
              <TextField {...field} state={fieldValidator(field)} label={t['property.city']} />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="street"
            render={(field: FieldProps) => (
              <TextField {...field} state={fieldValidator(field)} label={t['property.street']} />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={1}>
          <Field
            name="houseNumber"
            render={(field: FieldProps) => (
              <TextField
                {...field}
                state={fieldValidator(field)}
                label={t['property.houseNumber']}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={1}>
          <Field
            name="apartmentNumber"
            render={(field: FieldProps) => (
              <TextField
                {...field}
                state={fieldValidator(field)}
                label={t['property.apartmentNumber']}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="description"
            render={(field: FieldProps) => (
              <TextField
                {...field}
                state={fieldValidator(field)}
                label={t['property.description']}
                multiline={true}
              />
            )}
          />
        </Grid>
      </Grid>
      <FormSectionHeader>{t['section.medical']}</FormSectionHeader>
      <Grid container className={classes.container} spacing={2}>
        <Authorize checkAccess={S.isSuperAdmin}>
          <Grid item xs={12} sm={8} xl={6}>

            <FormControl fullWidth required={true} >
              <InputLabel>{t['property.company']}</InputLabel>
                          <CompaniesSelect
                company={formik.values.company}
                selectCompany={c => formik.setFieldValue('company', c)}
              />
               </FormControl>
          </Grid>
        </Authorize>
        <Grid item xs={8} md={4} xl={2}>
          <Field
            name="licenseNumber"
            render={(field: FieldProps) => (
              <TextField
                {...field}
                state={fieldValidator(field)}
                label={t['property.licenseNumber']}
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={8} xl={6}>
          <Field
            name="specialisations"
            render={(field: FieldProps) => (
              <EntriesField
                {...field}
                state={fieldValidator(field)}
                label={t['property.specialisations']}
                placeholder={t['placeholder.specialisation']}
                placeholderEdit={t['placeholder.specialisation.add']}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const PhysiotherapistForm: React.FC<
  FormProps<Physiotherapist, PhysiotherapistInput>
> = createForm({
  validation: Ft.validation,
  FormFields: PhysiotherapistFormFields,
})
