/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { ExerciseStatistic } from 'shared/types/ExerciseStatistic'
import { Icons } from 'shared/components/Icons'

type ExerciseStatisticIconProps = {
  statistic?: ExerciseStatistic
}

export const ExerciseStatisticIcon: React.FC<ExerciseStatisticIconProps> = ({ statistic }) => {
  switch (statistic && statistic.state) {
    case 'completed':
      return <Icons.Done css={statistic && statistic.progress < 95 ? cssWarning : cssSuccess} />
    case 'failed':
      return <Icons.Close css={cssWarning} />
    default:
      return <Icons.Pose css={cssDefault} />
  }
}

const cssSuccess = css`
  color: green;
`
const cssWarning = css`
  color: orange;
`
const cssDefault = css`
  color: grey;
`
