import {
  FetchMany,
  FetchOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
  PatchOne,
  fetchMany,
  fetchOne,
  createOne,
  updateOne,
  deleteOne,
  activateOne,
  deactivateOne,
} from 'shared/api/crud'
import { Meta } from 'shared/types/Meta'
import {
  SetOfExercises,
  SetOfExercisesByDay,
  SetOfExercisesInputNormalized,
  SetOfExercisesParams,
  SetsOfExercisesQuery,
  SetsOfExercisesByDayQuery,
} from 'shared/types/SetOfExercises'

const resource = 'setsOfExercises'

export type SetsOfExercisesPayload = { setsOfExercises: SetOfExercises[]; meta: Meta }
export type SetsOfExercisesByDayPayload = {
  setsOfExercisesByDay: SetOfExercisesByDay[]
  meta: Meta
}
export type SetOfExercisesPayload = { setOfExercises: SetOfExercises }

export const fetchSetsOfExercises: FetchMany<
  SetsOfExercisesPayload,
  SetsOfExercisesQuery
> = fetchMany(resource)
export const fetchSetsOfExercisesByDay: FetchMany<
  SetsOfExercisesByDayPayload,
  SetsOfExercisesByDayQuery
> = fetchMany('setsOfExercisesByDay')
export const fetchSetOfExercises: FetchOne<SetOfExercisesPayload> = fetchOne(resource)
export const createSetOfExercises: CreateOne<
  SetOfExercisesPayload,
  SetOfExercisesInputNormalized & SetOfExercisesParams
> = createOne(resource)
export const updateSetOfExercises: UpdateOne<
  SetOfExercisesPayload,
  SetOfExercisesInputNormalized & SetOfExercises
> = updateOne(resource)
export const deleteSetOfExercises: DeleteOne = deleteOne(resource)
export const activateSetOfExercises: PatchOne<SetOfExercisesPayload> = activateOne(resource)
export const deactivateSetOfExercises: PatchOne<SetOfExercisesPayload> = deactivateOne(resource)
