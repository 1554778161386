import React, { useContext } from 'react'
import * as N from 'shared/data/notification'
import { RemoteEntityActions } from 'shared/types/RemoteEntity'
import { RemoteCollection, RemoteCollectionActions, idle } from 'shared/types/RemoteCollection'
import { Notification } from 'shared/types/Notification'

// --- GLOBAL NOTIFICATIONS CONTEXT ---

type Notifications = {
  state: RemoteCollection<Notification>
  collection: RemoteCollectionActions<Notification>
  entity: RemoteEntityActions<Notification>
}

/**
 * Current state of user's notifications lives here
 */
const NotificationsContext: React.Context<Notifications> = React.createContext({
  state: idle,
} as Notifications)

// --- PROVIDE NOTIFICATIONS ---

export const NotificationsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const notifications = N.useCollectionReducer()
  return (
    <NotificationsContext.Provider value={notifications}>{children}</NotificationsContext.Provider>
  )
}

// --- USE NOTIFICATIONS ---

export const useNotifications = () => {
  return useContext(NotificationsContext)
}
