import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Cp from 'shared/data/company'
import { useToasts } from 'shared/context/toasts'
import { CompanyInput } from 'shared/types/Company'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { FormFooter } from 'shared/components/FormFooter'
import { FormHeader } from 'shared/views/headers/FormHeader'
import { CompanyForm } from 'shared/views/CompanyForm'
import { ActionChangeStatus } from 'shared/views/actions/ActionChangeStatus'
import { ActionResetPassword } from 'shared/views/actions/ActionResetPassword'
import { createEditFormFieldValidator } from 'shared/utils/validation'
import { link } from 'modules/routes'
import { Params } from 'modules/superadmin/routes'

export const SuperAdminCompanyEdit: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, entity } = Cp.useEntityReducer()
  const [redirect, setRedirect] = useState<string>()
  const { companyId } = match.params
  const { data } = state
  const backToList = link['/superadmin/companies']()

  useEffect(() => {
    let cancelled = false
    if (companyId) {
      entity.loading()
      Api.fetchCompany(companyId)
        .then(res => !cancelled && entity.success(res.company))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, companyId])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const save = (input: CompanyInput) => {
    entity.loading()
    Api.updateCompany(Cp.payloadUpdate(data, input))
      .then(res => {
        toasts.success(`Zapisano zmiany`)
        entity.success(res.company)
        setRedirect(backToList)
      })
      .catch(err => {
        toasts.error(`Nie udało się zapisać zmian`)
        entity.failure(err)
      })
  }

  const activate = () => {
    entity.loading()
    Api.activateCompany(data.id)
      .then(res => {
        toasts.success(`Aktywowano placówkę`)
        entity.success(res.company)
      })
      .catch(() => {
        toasts.error(`Nie udało się aktywować placówki`)
      })
  }

  const deactivate = () => {
    entity.loading()
    Api.deactivateCompany(data.id)
      .then(res => {
        toasts.success(`Dezaktywowano placówkę "${res.company.name}"`)
        entity.success(res.company)
      })
      .catch(() => {
        toasts.error(`Nie udało się dezaktywować placówki`)
      })
  }

  const resetPassword = () => {
    Api.resetPassword(data.email)
      .then(() => toasts.success(`Wysłano email na adres "${data.email}"`))
      .catch(() => toasts.error(`Nie udało się wysłać emaila`))
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <FormHeader back={backToList} title={data.name} status={data.state} />
        <CompanyForm
          createFieldValidator={createEditFormFieldValidator}
          state={state}
          save={save}
          headerActions={[
            () => (
              <ActionChangeStatus
                activate={activate}
                deactivate={deactivate}
                status={data.state}
                disabled={state.pending}
                renderAsButton={false}
              />
            ),
            () => (
              <ActionResetPassword
                resetPassword={resetPassword}
                disabled={state.pending}
                renderAsButton={false}
              />
            ),
          ]}
          footerActions={[
            () => (
              <ActionChangeStatus
                activate={activate}
                deactivate={deactivate}
                status={data.state}
                disabled={state.pending}
                renderAsButton={true}
              />
            ),
            () => (
              <ActionResetPassword
                resetPassword={resetPassword}
                disabled={state.pending}
                renderAsButton={true}
              />
            ),
          ]}
        />
        <FormFooter />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
