import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { Icons } from 'shared/components/Icons'
import { IconLink } from 'shared/components/Link'
import { t } from 'shared/translations'

type ActionLinkBackProps = {
  to: string
}

export const ActionLinkBack: React.FC<ActionLinkBackProps> = ({ to }) => {
  return (
    <Tooltip title={t['action.back']}>
      <IconLink to={to} aria-label={t['action.back']}>
        <Icons.ArrowBack />
      </IconLink>
    </Tooltip>
  )
}
