import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field, FieldProps } from 'formik'
import * as Ft from 'shared/data/physiotherapist'
import { FormProps, FormFieldsProps } from 'shared/types/Form'
import { Physiotherapist, PhysiotherapistInput } from 'shared/types/Physiotherapist'
import { TextField } from 'shared/components/FormField/TextField'
import { useFormStyles } from 'shared/styles/form'
import { t } from 'shared/translations'
import { createForm } from 'shared/components/Form'

const ProfileFormFields: React.FC<FormFieldsProps<PhysiotherapistInput>> = ({ fieldValidator }) => {
  const classes = useFormStyles()

  return (
    <Grid container direction="column" className={classes.padding}>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="firstName"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.firstName']}
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="lastName"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.lastName']}
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="phone"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.phone']}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="email"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.email']}
                required={true}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const PhysiotherapistProfileForm: React.FC<FormProps<
  Physiotherapist,
  PhysiotherapistInput
>> = createForm({
  validation: Ft.validation,
  FormFields: ProfileFormFields,
})
