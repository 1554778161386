/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import styled from '@emotion/styled'
import Button from '@material-ui/core/Button'
import { useConnections } from 'shared/context/connections'
import { useSession } from 'shared/context/session'
import { Caller } from 'shared/types/Caller'
import { Availability } from 'shared/types/Availability'
import * as S from 'shared/data/session'
import * as Cns from 'shared/data/connections'
import { Icons } from 'shared/components/Icons'
import { IconButton } from 'shared/components/Button'
import { t } from 'shared/translations'

type ActionCallProps = {
  participant: Caller
  availability?: Availability
  renderer?: React.FC<ActionCallRendererProps>
}

export const ActionCall: React.FC<ActionCallProps> = ({
  participant,
  availability,
  renderer = ActionCallIcon,
}) => {
  const { state, actions } = useConnections()
  const session = useSession()
  const role = S.selectRole(session.state)
  const canIMakeCalls = Cns.canMakeCalls(role)
  const amIBusy = Cns.isBusy(state)
  const canTheyBeCalled = Cns.canBeCalled(availability)

  const call = () => {
    actions.callUp(participant)
  }

  if (!canIMakeCalls) {
    return null
  }

  return renderer({
    disabled: amIBusy || !canTheyBeCalled,
    call,
  })
}

// --- ACTION RENDERERS ---

// Below we just play with action's visual presentation.
// Behavior of all the components should be identical.

type ActionCallRendererProps = {
  disabled: boolean
  call: () => void
}

export const ActionCallIcon: React.FC<ActionCallRendererProps> = ({ disabled, call }) => {
  return (
    <IconButton disabled={disabled} onClick={call} title={t['action.call']}>
      <Icons.Call />
    </IconButton>
  )
}

export const ActionCallLargeButton: React.FC<ActionCallRendererProps> = ({ disabled, call }) => {
  return (
    <LargeButton disabled={disabled} onClick={call} title={t['action.enterChat']}>
      <span>{t['action.call']}</span>
      <Icons.Call />
    </LargeButton>
  )
}

export const LargeActionFloatingBox = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 8px;
  right: 16px;
`

const LargeButton = styled(Button)`
  && {
    min-width: 160px;
    padding: 10px 24px;
    padding-left: 0px;
    border-radius: 24px;
    background: lightcyan;
    text-transform: none;
    & > span.MuiButton-label {
      & > span:first-of-type {
        margin-right: 32px;
      }
      & > svg {
        position: absolute;
        right: 0;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        padding: 10px;
        background: lightblue;
        color: white;
      }
    }
  }
`
