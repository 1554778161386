import { Notification } from 'shared/types/Notification'
import { UseRemoteEntityReducer, useRemoteEntityReducer } from 'shared/hooks/useRemoteEntityReducer'
import {
  UseRemoteCollectionReducer,
  useRemoteCollectionReducer,
} from 'shared/hooks/useRemoteCollectionReducer'

export const useEntityReducer: UseRemoteEntityReducer<Notification> = useRemoteEntityReducer
export const useCollectionReducer: UseRemoteCollectionReducer<
  Notification
> = useRemoteCollectionReducer
