import {
  FetchMany,
  FetchOne,
  CreateOne,
  UpdateOne,
  DeleteOne,
  PatchOne,
  fetchMany,
  fetchOne,
  createOne,
  updateOne,
  deleteOne,
  activateOne,
  deactivateOne,
} from 'shared/api/crud'
import { Meta } from 'shared/types/Meta'
import {
  Physiotherapist,
  PhysiotherapistInput,
  PhysiotherapistParams,
  PhysiotherapistsQuery,
} from 'shared/types/Physiotherapist'

const resource = 'physiotherapists'

export type PhysiotherapistsPayload = {
  physiotherapists: Physiotherapist[]
  meta: Meta
}
export type PhysiotherapistPayload = { physiotherapist: Physiotherapist }

export const fetchPhysiotherapists: FetchMany<
  PhysiotherapistsPayload,
  PhysiotherapistsQuery
> = fetchMany(resource)
export const fetchPhysiotherapist: FetchOne<PhysiotherapistPayload> = fetchOne(resource)
export const createPhysiotherapist: CreateOne<
  PhysiotherapistPayload,
  PhysiotherapistInput & PhysiotherapistParams
> = createOne(resource)
export const updatePhysiotherapist: UpdateOne<
  PhysiotherapistPayload,
  PhysiotherapistInput & Physiotherapist
> = updateOne(resource)
export const deletePhysiotherapist: DeleteOne = deleteOne(resource)
export const activatePhysiotherapist: PatchOne<PhysiotherapistPayload> = activateOne(resource)
export const deactivatePhysiotherapist: PatchOne<PhysiotherapistPayload> = deactivateOne(resource)
