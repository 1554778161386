import { FetchMany, FetchOne, PatchOne, fetchMany, fetchOne, patchOne } from 'shared/api/crud'
import { Meta } from 'shared/types/Meta'
import { Notification, NotificationsQuery } from 'shared/types/Notification'

const resource = 'notifications'

export type NotificationsPayload = { notifications: Notification[]; meta: Meta;}
export type NotificationPayload = { notification: Notification }

export const fetchNotifications: FetchMany<NotificationsPayload, NotificationsQuery> = fetchMany(
  resource
)
export const fetchNotification: FetchOne<NotificationPayload> = fetchOne(resource)
export const markAsReadNotification: PatchOne<NotificationPayload> = patchOne(
  notificationId => `notifications/${notificationId}/markAsRead`
)
