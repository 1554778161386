/** @jsx jsx */
import React, { useState } from 'react'
import * as Api from 'shared/api'
import { jsx, css } from '@emotion/core'
import logo from 'shared/assets/rehabee_logo_small.svg'
import { colors } from 'shared/styles/colors'
import { TextField } from '@material-ui/core'
import { t } from 'shared/translations'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

export const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('')

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    Api.resetPassword(email)
      .then()
      .catch();
  }
  const disabled = !email;


  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 30px;
      `}
    >
      <img src={logo} alt="RehaBee" />
      <div
        css={css`
          margin: 40px 0px;
        `}
      >
        {t['title.resetDescription']}
      </div>
      <form
        onSubmit={submit}
        css={css`
          width: 300px;
          & > input {
            width: 100%;
          }
        `}
      >
        <TextField
          type="text"
          label={t['property.email']}
          autoFocus
          value={email}
          onChange={e => setEmail(e.target.value)}
          css={css`
            color: ${colors.mainOrange};
            width: 100%;
            
          `}

        />

        <button
          type="submit"
          disabled={disabled}
          css={css`
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            background: ${colors.mainOrange};
            color: white;
            text-decoration: none;
            padding: 8px 20px;
            border-radius: 3px;
            border: 1px solid ${colors.mainOrange};
            &[disabled] {
              background: white;
              color: ${colors.mainOrange};
              border-color: ${colors.mainOrange};
              cursor: default;
            }
          `}
        >
          {t['action.send']}
        </button>
        <div css={cssResetPassBox}>
          <ResetPassLink to={'/'}>{t['action.backToLogin']}</ResetPassLink>
        </div>
      </form>
    </div>
  )
}

const cssResetPassBox = css`
  margin-top: 20px;
  text-align: center;
`

const ResetPassLink = styled(Link)`
  && {
    text-decoration: underline;
    color: ${colors.mediumGrey};
  }
`
