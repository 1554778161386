import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Stm from 'shared/data/settlement'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { ListHeader } from 'shared/components/ListHeader'
import { ListFilters } from 'shared/components/ListFilters'
import { SettlementsList } from 'shared/views/SettlementsList'
import { t } from 'shared/translations'
import { link } from 'modules/routes'
import { Params } from 'modules/company/routes'

export const CompanySettlements: React.FC = () => {
  const { match } = useRouter<Params>()
  const { state, collection } = Stm.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy } = Stm.useQueries()
  const { companyId } = match.params
  useEffect(() => {
    let cancelled = false
    collection.loading()
    Api.fetchSettlements({...query, company: [companyId]})
      .then(res => !cancelled && collection.success(res.settlements, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = true
    }
  }, [collection, query])

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <ListHeader title={t['title.settlements']} />
        <ListFilters searchTerm={query.searchTerm || ''} searchBy={searchBy} />
        <SettlementsList
          state={state}
          order={query.order}
          loadNext={loadNext}
          sortBy={sortBy}
          rowLink={settlement =>
            link['/company/:companyId/settlements/:settlementId']({
              companyId: match.params.companyId,
              settlementId: settlement.id,
            })
          }
        />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
