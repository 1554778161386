import React, { useEffect, useState } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Ft from 'shared/data/physiotherapist'
import { Availability } from 'shared/types/Availability'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { PhysiotherapistView } from 'shared/views/PhysiotherapistView'
import { Params } from 'modules/patient/routes'

export const PatientPhysiotherapistView: React.FC = () => {
  const { match } = useRouter<Params>()
  const { state, entity } = Ft.useEntityReducer()
  const [availability, setAvailability] = useState<Availability>()
  const { physiotherapistId } = match.params
  const { data } = state

  useEffect(() => {
    let cancelled = false
    if (physiotherapistId) {
      entity.loading()
      Api.fetchPhysiotherapist(physiotherapistId)
        .then(res => !cancelled && entity.success(res.physiotherapist))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, physiotherapistId])

  useEffect(() => {
    let cancelled = false
    if (physiotherapistId) {
      Api.getUserAvailability(physiotherapistId)
        .then(res => !cancelled && setAvailability(res.user.availability))
        .catch(res => !cancelled && setAvailability(undefined))
    }
    return () => {
      cancelled = true
    }
  }, [physiotherapistId])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <PhysiotherapistView physiotherapist={data} availability={availability} />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
