import React from 'react'
import { IconButton, FooterButton } from 'shared/components/Button'
import { Icons } from 'shared/components/Icons'
import { t } from 'shared/translations'

type ActionSaveProps = {
  save: () => void
  disabled?: boolean
  renderAsButton?: boolean
}

export const ActionSave: React.FC<ActionSaveProps> = ({ save, disabled, renderAsButton }) => {
  return renderAsButton ? (
    <FooterButton disabled={disabled} onClick={save} title={t['action.save']}>
      <Icons.ConfirmButton />
      {t['action.save']}
    </FooterButton>
  ) : (
    <IconButton disabled={disabled} onClick={save} title={t['action.save']}>
      <Icons.ConfirmButton />
    </IconButton>
  )
}
