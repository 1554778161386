/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import { useConnections } from 'shared/context/connections'
import { Caller } from 'shared/types/Caller'
import { ConnectionHeader, ConnectionLoader } from 'shared/components/Connection'
import { TeleportToModals } from 'shared/views/portals/PortalWithModals'
import { ActionDisconnect } from 'shared/views/actions/ActionDisconnect'

type PopupConnectionProps = {
  caller: Caller
}

export const PopupFinishedConnection: React.FC<PopupConnectionProps> = ({ caller }) => {
  const { actions } = useConnections()

  const closeModal = () => {
    actions.dismissFinished()
  }

  return (
    <TeleportToModals>
      <Modal open={true} onClose={closeModal} closeAfterTransition>
        <PopupPaper>
          <HeaderBox>
            <ConnectionHeader participant={caller} finished={true} />
          </HeaderBox>
          <ContentBox>
            <ConnectionLoader participant={caller} />
          </ContentBox>
          <ActionsBox>
            <ActionDisconnect disconnect={closeModal} />
          </ActionsBox>
        </PopupPaper>
      </Modal>
    </TeleportToModals>
  )
}

const PopupPaper = styled(Paper)`
  && {
    width: 420px;
    margin: 64px auto;
    padding: 16px;
    border-radius: 2px;
  }
`

const HeaderBox = styled.div``

const ContentBox = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 8px 0 16px;
}
`

const ActionsBox = styled.div`
  min-height: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
