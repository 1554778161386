import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { CompanyPhysiotherapists } from './Physiotherapists'
import { CompanyPhysiotherapistAdd } from './PhysiotherapistAdd'
import { CompanyPhysiotherapistEdit } from './PhysiotherapistEdit'
import { path } from 'modules/routes'

export const CompanyPhysiotherapistsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={path['/company/:companyId/physiotherapists']}
        component={CompanyPhysiotherapists}
      />
      <Route
        exact
        path={path['/company/:companyId/physiotherapists/add']}
        component={CompanyPhysiotherapistAdd}
      />
      <Route
        exact
        path={path['/company/:companyId/physiotherapists/:physiotherapistId']}
        component={CompanyPhysiotherapistEdit}
      />
    </Switch>
  )
}
