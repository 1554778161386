import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import { generatePath } from 'react-router'
import { Params } from 'modules/routes'

export const prefix = process.env.REACT_APP_PREFIX || ''

export type CreateLink<P extends object = Params> = (pathname: string) => (params?: P) => string

export const createLink: CreateLink = pathname => (params): string => {
  return prefix + generatePath(pathname, omitBy(params, isNil))
}
