import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { PatientPhysiotherapists } from './Physiotherapists'
import { PatientPhysiotherapistView } from './PhysiotherapistView'
import { path } from 'modules/routes'

export const PatientPhysiotherapistsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={path['/patient/:patientId/physiotherapists']}
        component={PatientPhysiotherapists}
      />
      <Route
        exact
        path={path['/patient/:patientId/physiotherapists/:physiotherapistId']}
        component={PatientPhysiotherapistView}
      />
    </Switch>
  )
}
