import { FetchOne, fetchOne } from 'shared/api/crud'
import { SetOfExercisesStatistics } from 'shared/types/SetOfExercisesStatistics'

const resource = 'setsOfExercisesStatistics'

export type SetOfExercisesStatisticsPayload = {
  setOfExercisesStatistics: SetOfExercisesStatistics
}

export const fetchSetOfExercisesStatistics: FetchOne<SetOfExercisesStatisticsPayload> = fetchOne(
  resource
)
