import React from 'react'
import { Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Ft from 'shared/data/physiotherapist'
import { useToasts } from 'shared/context/toasts'
import { PhysiotherapistInput } from 'shared/types/Physiotherapist'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { FormFooter } from 'shared/components/FormFooter'
import { FormHeader } from 'shared/views/headers/FormHeader'
import { PhysiotherapistForm } from 'shared/views/PhysiotherapistForm'
import { createAddFormFieldValidator } from 'shared/utils/validation'
import { t } from 'shared/translations'
import { link } from 'modules/routes'
import { Params } from 'modules/superadmin/routes'

export const SuperAdminPhysiotherapistAdd: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, entity } = Ft.useEntityReducer()
  const backToList = link['/superadmin/physiotherapists'](match.params)

  if (state.data) {
    return <Redirect to={backToList} />
  }

  const save = (input: PhysiotherapistInput) => {
    entity.loading()
    Api.createPhysiotherapist(Ft.payloadCreate(input))
      .then(res => {
        toasts.success(`Utworzono fizjoterapeutę`)
        entity.success(res.physiotherapist)
      })
      .catch(err => {
        toasts.error(`Nie udało się utworzyć fizjoterapeuty`)
        entity.failure(err)
      })
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <FormHeader back={backToList} title={t['title.physiotherapist.add']} />
        <PhysiotherapistForm
          createFieldValidator={createAddFormFieldValidator}
          state={state}
          save={save}
          headerActions={[]}
          footerActions={[]}
        />
        <FormFooter />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
