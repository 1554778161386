import { ConnectionsState } from './state'
import { Role } from 'shared/types/Role'
import { Availability } from 'shared/types/Availability'

/**
 * Check if user is participating in a connection, which means:
 * a) connected to a chat room
 * b) making an outgoing call
 */
export const isBusy = (s: ConnectionsState) => Boolean(s.activeConnection)

/**
 * Check if user role can receive incoming calls.
 * If not, we may skip some unnecessary setup, like establishing connection to server-sent events.
 * For example, we don't want **superadmin** user to be receiving any calls.
 */
export const canReceiveCalls = (role?: Role) => role === 'patient' || role === 'physiotherapist'

/**
 * Check if user role can make outgoing calls.
 * If not, we may want to block/hide some parts of UI related to making calls.
 * For example, we don't want **superadmin** user to be calling other users.
 */
export const canMakeCalls = (role?: Role) => role === 'patient' || role === 'physiotherapist'

/**
 * Check if user role can use incoming or outgoing connections at all.
 * If not, we may block/hide some functionalities
 */
export const canConnect = (role?: Role) => canReceiveCalls(role) || canMakeCalls(role)

/**
 * Check if call action should be enabled at this time.
 * If the other user is offline, busy, or their status can't be established, we don't allow making calls.
 * 
 * TODO FIXME block calling up when availability status is undefined (haven't confirmed if user is online).
 * Unblocking it temporarily now for easier testing
 */
export const canBeCalled = (availability?: Availability) =>
  !availability || availability === 'inactive' || availability === 'online'
