/** @jsx jsx */
import React, { useEffect, useRef } from 'react'
import { jsx, css } from '@emotion/core'
import Video, { Room, RemoteParticipant, RemoteTrack } from 'twilio-video'
import { ChatConfiguration } from 'shared/types/ChatConfiguration'
import { explain } from 'shared/utils/error'

type TwilioVideoChatProps = {
  chat: ChatConfiguration
  cameraOn: boolean
  microphoneOn: boolean
}

export const TwilioVideoChat: React.FC<TwilioVideoChatProps> = ({
  chat,
  cameraOn,
  microphoneOn,
}) => {
  const { room, token } = chat

  // let localMediaRef = useRef<HTMLDivElement>(null)
  let remoteMediaRef = useRef<HTMLDivElement>(null)
  let roomRef = useRef<Room | null>(null)

  useEffect(() => {
    Video.createLocalTracks().then(localTracks => {
      console.log('TwilioVideoChat localTracks', localTracks.map(t => t.kind))

      // Join the Room with the token from the server and the
      // LocalParticipant's Tracks.
      return Video.connect(token, {
        name: room,
        tracks: localTracks,
      }).then(roomJoined, error => {
        console.log('TwilioVideoChat could not connect to Twilio: ' + explain(error))
      })
    })

    // Disconnect when exiting
    return () => {
      const room = roomRef.current
      if (room) {
        console.log('TwilioVideoChat leaving room')
        room.disconnect()
      }
    }
  }, [room, token])

  // Successfully connected!
  const roomJoined = (room: Room) => {
    roomRef.current = room

    // Log your Client's LocalParticipant in the Room
    const localParticipant = room.localParticipant
    console.log(`TwilioVideoChat joined room as "${localParticipant.identity}"`)

    // Log any Participants already connected to the Room
    room.participants.forEach((participant: RemoteParticipant) => {
      console.log(`TwilioVideoChat participant "${participant.identity}" is already connected`)

      participant.tracks.forEach(publication => {
        const remoteMediaContainer = remoteMediaRef.current
        if (remoteMediaContainer) {
          if (publication.isSubscribed) {
            const track = publication.track
            if (track && (track.kind === 'video' || track.kind === 'audio')) {
              remoteMediaContainer.appendChild(track.attach())
            }
          }
        }
      })

      participant.on('trackSubscribed', (track: RemoteTrack) => {
        const remoteMediaContainer = remoteMediaRef.current
        if (remoteMediaContainer) {
          if (track.kind === 'video' || track.kind === 'audio') {
            remoteMediaContainer.appendChild(track.attach())
          }
        }
      })
    })

    // Attach the Participant's Media to a <div> element.
    room.on('participantConnected', (participant: RemoteParticipant) => {
      console.log(`TwilioVideoChat participant "${participant.identity}" just connected`)

      participant.tracks.forEach(publication => {
        const remoteMediaContainer = remoteMediaRef.current
        if (remoteMediaContainer) {
          if (publication.isSubscribed) {
            const track = publication.track
            if (track && (track.kind === 'video' || track.kind === 'audio')) {
              remoteMediaContainer.appendChild(track.attach())
            }
          }
        }
      })

      participant.on('trackSubscribed', (track: RemoteTrack) => {
        const remoteMediaContainer = remoteMediaRef.current
        if (remoteMediaContainer) {
          if (track.kind === 'video' || track.kind === 'audio') {
            remoteMediaContainer.appendChild(track.attach())
          }
        }
      })
    })

    room.on('disconnected', (room: Room) => {
      console.log('TwilioVideoChat disconnected')
      // Detach the local media elements.
      // Make sure we're not accidentally broadcasting video/audio tracks in the background
      // (after videochat has been closed)
      room.localParticipant.tracks.forEach(publication => {
        const track = publication.track
        if (track.kind === 'video' || track.kind === 'audio') {
          track.stop()
          track.detach().forEach(element => element.remove())
        }
        publication.unpublish()
      })
    })
  }

  // Allow user to toggle their camera
  useEffect(() => {
    const room = roomRef.current
    if (room && cameraOn === false) {
      console.log('TwilioVideoChat turning off camera')
      room.localParticipant.videoTracks.forEach(publication => {
        const track = publication.track
        track.stop()
        track.detach().forEach(element => element.remove())
        publication.unpublish()
      })
    }
  }, [cameraOn])

  // Allow user to toggle their microphone
  useEffect(() => {
    const room = roomRef.current
    if (room && microphoneOn === false) {
      console.log('TwilioVideoChat turning off microphone')
      room.localParticipant.audioTracks.forEach(publication => {
        const track = publication.track
        track.stop()
        track.detach().forEach(element => element.remove())
        publication.unpublish()
      })
    }
  }, [microphoneOn])

  return (
    <div>
      <div ref={remoteMediaRef} id="remote-media" css={cssRemoteMediaBox} />
    </div>
  )
}

const cssRemoteMediaBox = css`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  & video {
    width: inherit;
    height: inherit;
  }
`
