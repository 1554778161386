/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { H4 } from 'shared/components/Heading'
import { colors } from 'shared/styles/colors'

export const FormStepHeader: React.FC<{
  icon: number | string | React.ReactNode
  title: React.ReactNode
  color?: string
  onClick?: () => void
  actions?: React.ReactNode
}> = ({ icon, title, color, onClick, actions }) => {
  const clickable = typeof onClick === 'function'
  return (
    <div css={cssHeaderBox}>
      <div css={cssHeaderSection}>
        {clickable ? (
          <button type="button" css={cssIconButton(color)} onClick={onClick}>
            {icon}
          </button>
        ) : (
          <div css={cssIconIndex(color)}>{icon}</div>
        )}
        <H4 onClick={onClick} css={cssHeading(clickable)}>
          {title}
        </H4>
      </div>
      <div css={cssHeaderSection}>{actions}</div>
    </div>
  )
}

const cssHeaderBox = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`
const cssHeaderSection = css`
  display: flex;
  flex-direction: row;
`
const cssIconButton = (color?: string) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border: none;
  border-radius: 32px;
  background: ${color || '#999'};
  color: white;
  font-weight: bold;
  outline: none;
  box-shadow: none;
  cursor: pointer;
`
const cssIconIndex = (color?: string) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border: none;
  border-radius: 32px;
  background: ${color || colors.mainOrange};
  color: white;
  font-weight: bold;
`
const cssHeading = (clickable: boolean) => css`
  cursor: ${clickable ? 'pointer' : 'auto'};
`
