import React, { useEffect } from 'react'
import * as Api from 'shared/api'
import * as Ex from 'shared/data/exercise'
import { Exercise } from 'shared/types/Exercise'
import { Modal, ModalTitle } from 'shared/components/Modal'
import { Search } from 'shared/components/Search'
import { TableRowActions } from 'shared/components/TableRowActions'
import { ActionPlayVideo } from 'shared/views/actions/ActionPlayVideo'
import { ExercisesListSmall } from 'shared/views/ExercisesListSmall'
import { t } from 'shared/translations'

type ModalProps = {
  cancel: () => void
  select: (ex: Exercise) => void
}

export const ModalSelectExercise: React.FC<ModalProps> = ({ cancel, select }) => {
  const cache = Ex.useCachedExercises()
  const { state, collection } = Ex.useCollectionReducer(cache)
  const { query, loadNext, searchBy, sortBy } = Ex.useQueries()

  useEffect(() => {
    let cancelled = false
    collection.loading()
    Api.fetchExercises(query)
      .then(res => !cancelled && collection.success(res.exercises, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = true
    }
  }, [collection, query])

  return (
    <Modal open={true} onClose={cancel}>
      <ModalTitle>{t['exercise.add']}</ModalTitle>
      <Search placeholder={t['placeholder.search_exercise']} value={query.searchTerm || ''} onChange={evt => searchBy(evt.target.value)} />
      <ExercisesListSmall
        select={select}
        state={state}
        order={query.order}
        loadNext={loadNext}
        sortBy={sortBy}
        renderActions={props => {
          const ex = props.rowData as Exercise
          return (
            <TableRowActions>
              <ActionPlayVideo fileId={ex.id} />
            </TableRowActions>
          )
        }}
      />
    </Modal>
  )
}
