import { Reducer } from 'react'
import { ToastsAction } from './actions'
import { ToastsState } from './state'

export const initialState: ToastsState = []

export const reducer: Reducer<ToastsState, ToastsAction> = (state, action) => {
  switch (action.type) {
    case 'toasts/add': {
      return [...state, action.payload]
    }
    case 'toasts/dismiss': {
      return state.filter(toast => toast.key !== action.payload)
    }
  }
}
