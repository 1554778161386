import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import omitBy from 'lodash/omitBy'
import mapValues from 'lodash/mapValues'
import { ValidationErrors } from 'shared/types/Error'
import { normalizeDateTime, denormalizeDateTime } from 'shared/attributes/date'
import { t } from 'shared/translations'

export type FieldConfig<FieldValue = any, JSONValue = FieldValue> = {
  required?: boolean /** TODO add support for dynamic required fields? */
  defaultValue?: FieldValue
  validate?: (value: FieldValue, values?: any) => string | undefined
  normalize?: (value: FieldValue) => JSONValue
  denormalize?: (value: JSONValue) => FieldValue | undefined
}

export const TEXT: FieldConfig<string> = {
  defaultValue: '',
  normalize: trim,
}

export const INT: FieldConfig<string, number> = {
  defaultValue: '',
  normalize: Number,
  denormalize: String,
}

export const BOOL: FieldConfig<boolean> = {
  defaultValue: false,
}

export const DATE: FieldConfig<Date, string> = {
  defaultValue: new Date(),
  normalize: normalizeDateTime,
  denormalize: denormalizeDateTime,
}

// --- NORMALIZE USER INPUT ---
// FieldValue (form) --> JSONValue (http payload)

type Normalize<Input extends object = any, InputNormalized extends object = Input> = (
  fields: Record<keyof Input, FieldConfig>
) => (input: Input) => InputNormalized

const normalize: Normalize = fields => input => {
  let output: any = {}

  Object.entries(fields).forEach(([key, config]) => {
    const fieldValue = input[key]
    // TODO
    // First check if we should skip this field from output
    // if (config.shouldOmit && config.shouldOmit(fieldValue, input)) {
    //   return
    // }
    // Check if this value should be normalized before sending to server
    output[key] = config.normalize ? config.normalize(fieldValue) : fieldValue
  })

  return output
}

// --- DENORMALIZE JSON VALUES ---
// JSONValue (http payload) --> FieldValue (form)

type Denormalize<Data extends object = any, Input extends object = any> = (
  fields: Record<keyof Input, FieldConfig>
) => (data: Data) => Input

const denormalize: Denormalize = fields => data => {
  let input: any = {}

  Object.entries(fields).forEach(([key, config]) => {
    const jsonValue = data[key]

    // Check if this value should be denormalized before passing to form
    if (!isNil(jsonValue)) {
      input[key] = config.denormalize ? config.denormalize(jsonValue) : jsonValue
    }
  })

  return input
}

// --- GET INITIAL VALUES ---

export type GetInitialValues<Input extends object = any, Data extends object = any> = (
  fields: Record<keyof Input, FieldConfig>
) => (data?: Data) => Input

export const getInitialValues: GetInitialValues = fields => data => {
  let input: any = { ...mapValues(fields, config => config.defaultValue) } // defaults

  if (data) {
    input = { ...input, ...denormalize(fields)(data) }
  }

  console.log('--- getInitialValues', input)
  return input
}

// --- VALIDATE USER INPUT ---

export type Validate<Input extends object = any> = (
  fields: Record<keyof Input, FieldConfig>
) => (input: Input) => ValidationErrors<Input>

export const validate: Validate = fields => input => {
  let errors: ValidationErrors = {}

  Object.entries(fields).forEach(([key, config]) => {
    const value = input[key]

    if (config.required) {
      if (isBlank(value)) {
        errors[key] = t['not_blank']
      }
    }
    if (config.validate && !isBlank(value)) {
      const error = config.validate(value, input)
      if (error) {
        errors[key] = error
      }
    }
  })

  console.log('--- validate', errors, input)
  return errors
}

// --- PREPARE API CREATE PAYLOAD ---

export type PayloadCreate<Input extends object = any, InputNormalized extends object = Input> = (
  fields: Record<keyof Input, FieldConfig>
) => (input: Input) => InputNormalized

export const payloadCreate: PayloadCreate = fields => input => {
  const normalized = normalize(fields)(input)
  const output = omitBy(normalized, isBlank)

  console.log('--- payloadCreate', output)
  return output
}

// --- PREPARE API UPDATE PAYLOAD ---

export type PayloadUpdate<Data extends object = any, Input extends object = any> = (
  fields: Record<keyof Input, FieldConfig>
) => (data: Data, input: Input) => Data

export const payloadUpdate: PayloadUpdate = fields => (data, input) => {
  const normalized = normalize(fields)(input)
  const normalizedNotBlank = omitBy(normalized, isBlank)
  let output: any = {}

  Object.entries(data).forEach(([key, serverValue]) => {
    if (!(key in input)) {
      // First of all, preserve all original values that are not part of user input.
      // These are often fields like "id", "state", related ids etc.
      // Since they are not editable inside a form, we leave them alone.
      output[key] = serverValue
    } else {
      // For each field that is displayed on form as part of user input check if that input is blank.
      // If so, we skip that value altogether, so that field will get nullified on server side on save.
      // This way:
      // - empty fields will be skipped when creating a new object
      // - existing values can be nullified on edit, by just setting that value to a blank
      if (key in normalizedNotBlank) {
        // Field value is not a blank, we use that (normalized) value.
        output[key] = normalizedNotBlank[key]
      }
    }
  })

  console.log('--- payloadUpdate', output)
  return output
}

// --- HELPERS ---

/**
 * Check if value is blank
 */
export const isBlank = (v: any): boolean => {
  if (isNil(v)) return true
  if (typeof v === 'string' && !trim(v)) return true
  return false
}
