import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as SetEx from 'shared/data/setOfExercises'
import { useToasts } from 'shared/context/toasts'
import { SetOfExercisesInput } from 'shared/types/SetOfExercises'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { SetOfExercisesForm } from 'shared/views/SetOfExercisesForm'
import { ActionOpenPreview } from 'shared/views/actions/ActionOpenPreview'
import { ActionSaveAsTemplate } from 'shared/views/actions/ActionSaveAsTemplate'
import { createEditFormFieldValidator } from 'shared/utils/validation'
import { Params } from 'modules/physiotherapist/routes'

export const PhysiotherapistPatientTabSetsEdit: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, entity } = SetEx.useEntityReducer()
  const { physiotherapistId, setId } = match.params
  const { data } = state

  useEffect(() => {
    let cancelled = false
    if (setId) {
      entity.loading()
      Api.fetchSetOfExercises(setId)
        .then(res => !cancelled && entity.success(res.setOfExercises))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, setId])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const save = (input: SetOfExercisesInput) => {
    entity.loading()
    Api.updateSetOfExercises(SetEx.payloadUpdate(data, input))
      .then(res => {
        toasts.success(`Zapisano zmiany`)
        entity.success(res.setOfExercises)
      })
      .catch(err => {
        toasts.error(`Nie udało się zapisać zmian`)
        entity.failure(err)
      })
  }

  const saveAsTemplate = (input: SetOfExercisesInput) => {
    Api.createTemplate({
      physiotherapist: physiotherapistId,
      name: input.name,
      duration: input.duration,
      custom: input.custom,
      onceDay: input.onceDay,
      everyNumberDay: input.everyNumberDay,
      templateExercisesParameters: input.exercisesParameters,
    })
      .then(() => {
        toasts.success(`Zapisano szablon`)
      })
      .catch(() => {
        toasts.error(`Nie udało się zapisać szablonu`)
      })
  }

  return (
    <ErrorBoundary>
      <SetOfExercisesForm
        createFieldValidator={createEditFormFieldValidator}
        state={state}
        save={save}
        headerActions={[
          formik => (
            <ActionOpenPreview
              values={formik.values.exercisesParameters}
              disabled={state.pending}
              renderAsButton={false}
            />
          ),
          formik => (
            <ActionSaveAsTemplate
              save={() => saveAsTemplate(formik.values)}
              disabled={state.pending}
              renderAsButton={false}
            />
          ),
        ]}
        footerActions={[
          formik => (
            <ActionOpenPreview
              values={formik.values.exercisesParameters}
              disabled={state.pending}
              renderAsButton={true}
            />
          ),
          formik => (
            <ActionSaveAsTemplate
              save={() => saveAsTemplate(formik.values)}
              disabled={state.pending}
              renderAsButton={true}
            />
          ),
        ]}
      />
    </ErrorBoundary>
  )
}
