/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/core'
import { colors } from 'shared/styles/colors'

// Add some delay before showing loading spinners.
// If loading finishes before this time threshold, we can skip showing the spinner.
// This way there will be less spinners blinking in and out quickly.
const delay = 250

export const Loading: React.FC = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    let cancelled = false
    setTimeout(() => !cancelled && setShow(true), delay)
    return () => {
      cancelled = true
    }
  }, [])

  return show ? (
    <div css={cssSpinnerBox}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        viewBox="0 0 300 300"
      >
        <polygon points="300,150 225,280 75,280 0,150 75,20 225,20"></polygon>
        <polygon points="300,150 225,280 75,280 0,150 75,20 225,20"></polygon>
      </svg>
    </div>
  ) : null
}

const cssSpinnerBox = css`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  & > svg {
    max-width: 70px;
    max-height: 60px;
    & > polygon {
      fill: transparent;
      stroke: ${colors.mainOrange};
      stroke-width: 6;
      animation-name: pulse;
      animation-duration: 1.8s;
      animation-easing: ease-in-out;
      animation-iteration-count: infinite;
      transform-origin: center;
    }
    & > polygon:nth-of-type(1) {
      animation-delay: 0.9s;
      transform: scale(0.36);
      transform-origin: center;
    }
    & > polygon:nth-of-type(2) {
      animation-delay: 0s;
      transform: scale(1);
      transform-origin: center;
    }
  }
`
