import { useState } from 'react'
import { Patient, PatientsQuery } from 'shared/types/Patient'
import { Order } from 'shared/types/Order'
import { Queries, createQueries } from 'shared/utils/query'

export const defaultOrder: Order<Patient> = { firstName: 'asc' }

const initialQuery: PatientsQuery = {
  order: defaultOrder,
  offset: 0,
  limit: 16,
}

export const useQueries = () => {
  const [query, updateQuery] = useState(initialQuery)
  const queries: Queries<Patient> = createQueries({ query, updateQuery })

  return { query, ...queries }
}
