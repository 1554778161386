import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { CompanySettlements } from './Settlements'
import { CompanySettlementView } from './SettlementView'
import { path } from 'modules/routes'

export const CompanySettlementsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={path['/company/:companyId/settlements']} component={CompanySettlements} />
      <Route
        exact
        path={path['/company/:companyId/settlements/:settlementId']}
        component={CompanySettlementView}
      />
    </Switch>
  )
}
