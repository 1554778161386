import { useReducer, useMemo } from 'react'
import * as RE from 'shared/types/RemoteEntity'

export type UseRemoteEntityReducer<D extends object = any> = (
  initialState?: RE.RemoteEntity<D>
) => {
  state: RE.RemoteEntity<D>
  entity: RE.RemoteEntityActions<D>
}

export const useRemoteEntityReducer: UseRemoteEntityReducer = initialState => {
  const [state, dispatch] = useReducer(RE.remoteEntityReducer, initialState || RE.idle)
  const entity = useMemo(() => RE.createEntityActions(dispatch), [dispatch])

  return {
    state,
    entity,
  }
}
