import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Ps from 'shared/data/payment'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { ListHeader } from 'shared/components/ListHeader'
import { ListFilters } from 'shared/components/ListFilters'
import { PaymentsList } from 'shared/views/PaymentsList'
import { t } from 'shared/translations'
import { Params } from 'modules/patient/routes'

export const PatientPayments: React.FC = () => {
  const { match } = useRouter<Params>()
  const { state, collection } = Ps.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy } = Ps.useQueries()
  const { patientId } = match.params

  useEffect(() => {
    let cancelled = false
    if (patientId) {
      collection.loading()
      Api.fetchPayments({ ...query, patients: [patientId] })
        .then(res => !cancelled && collection.success(res.payments, res.meta))
        .catch(err => !cancelled && collection.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [collection, patientId, query])

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <ListHeader title={t['title.payments']} />
        <ListFilters searchTerm={query.searchTerm || ''} searchBy={searchBy} />
        <PaymentsList state={state} order={query.order} loadNext={loadNext} sortBy={sortBy} />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
