import {
  isYesterday,
  isToday,
  isTomorrow,
  isSameMonth,
  isAfter,
  isBefore,
  startOfDay,
  startOfMonth,
  endOfDay,
  lastDayOfMonth,
  addDays,
  addWeeks,
  subDays,
  parseISO,
  format,
} from 'date-fns'
import capitalize from 'lodash/capitalize'
import { UTCDate, UTCDateTime, isUTC, isUTCDateTime } from 'shared/types/UTC'
import { OcurrenceInput } from 'shared/types/Ocurrence'
import { t, localeOptions } from 'shared/translations'

export const formatUTCDate = (date: Date): UTCDate => format(date, 'yyyy-MM-dd')
export const formatUTCDateTime = (date: Date): UTCDateTime =>
  format(date, 'yyyy-MM-dd') + 'T00:00:00+00:00'

/**
 * Get today's UTC date
 * @example '2020-10-30'
 */
export const today = (): UTCDate => formatUTCDate(new Date())

/**
 * Get start of today's UTC datetime
 * @example '2020-10-30T00:00:00+00:00'
 */
export const startOfToday = (): UTCDateTime => formatUTCDateTime(startOfDay(new Date()))

// --- DISPLAY DATES TO THE USER ---

/**
 * Get short, translated description of a nearby day.
 * @param date - Date object
 * @example 'jutro'
 */
export const toNearbyDayLabel = (date: Date): string => {
  if (isYesterday(date)) {
    return t['date.yesterday'].toLocaleLowerCase()
  }
  if (isToday(date)) {
    return t['date.today'].toLocaleLowerCase()
  }
  if (isTomorrow(date)) {
    return t['date.tomorrow'].toLocaleLowerCase()
  }
  return ''
}

/**
 * Get short, translated description of the given day.
 * @param datetime - datetime string in ISO format
 * @example 'Jan 18'
 */
export const toShortDayLabel = (datetime: string): string => {
  if (!isUTC(datetime)) {
    return datetime
  }

  const date = parseISO(datetime)
  if (isYesterday(date)) {
    return t['date.yesterday']
  }
  if (isToday(date)) {
    return t['date.today']
  }
  if (isTomorrow(date)) {
    return t['date.tomorrow']
  }

  return capitalize(format(date, 'MMM dd', localeOptions))
}

/**
 * Get short, translated description of the given day
 * @param datetime - datetime string in ISO format
 */
export const toShortDayHourLabel = (datetime: string): string => {
  if (!isUTC(datetime)) {
    return datetime
  }

  const date = parseISO(datetime)
  if (isYesterday(date)) {
    return `${t['date.yesterday'] + ' o ' + format(date, 'kk:mm', localeOptions)}`
  }
  if (isToday(date)) {
    return `${t['date.today'] + ' o ' + format(date, 'kk:mm', localeOptions)}`
  }
  if (isTomorrow(date)) {
    return `${t['date.tomorrow'] + ' o ' + format(date, 'kk:mm', localeOptions)}`
  }

  return capitalize(
    `${format(date, 'MMM dd', localeOptions)} o ${format(date, 'kk:mm', localeOptions)}`
  )
}

/**
 * Get translated description of month + year.
 * @param datetime - datetime string in ISO format
 * @example 'January 2020'
 */
export const toMonthAndYear = (datetime: string): string => {
  if (!isUTCDateTime(datetime)) {
    return datetime
  }

  const date = parseISO(datetime)
  return capitalize(format(date, 'LLLL yyyy', localeOptions))
}

/**
 * Get date string in yyyy-MM-dd format
 * @param datetime - datetime string in ISO format
 * @example '2020-01-23'
 */
export const toDateLabel = (datetime: string): string => {
  if (!isUTCDateTime(datetime)) {
    return datetime
  }

  const date = parseISO(datetime)
  return format(date, 'yyyy-MM-dd')
}

/**
 * Get dates for first and last days of given month
 * @param datetime - datetime string in ISO format
 * @example '2020-12-01 - 2020-12-31'
 */
export const toMonthDateRange = (datetime: string): string => {
  if (!isUTCDateTime(datetime)) {
    return datetime
  }

  const date = parseISO(datetime)
  const start = format(startOfMonth(date), 'yyyy-MM-dd')
  const end = format(lastDayOfMonth(date), 'yyyy-MM-dd')
  return t['date.range'](start, end)
}

/**
 * Get date range based on starting date and duration in weeks
 * @param datetime - datetime string in ISO format
 * @param duration - duration in weeks
 * @example '2020-12-01 - 2020-12-14'
 */
export const toDateRangeLabel = (datetime: string, duration: number): string => {
  if (!isUTCDateTime(datetime)) {
    return datetime
  }

  const date = parseISO(datetime)
  const start = format(date, 'yyyy-MM-dd')
  const end = format(getEndDate(datetime, duration), 'yyyy-MM-dd')
  return t['date.range'](start, end)
}

// --- CHECK & COMPARE DATES ---

/**
 * Check if month in element's date is different from the previous one.
 * We assume the array contains objects with `date` fields and is sorted by dates.
 * @param days - array of elements with dates
 * @param index - index of the element we want to check
 */
export const isDifferentMonth = (days: OcurrenceInput[], index: number): boolean => {
  return index > 0 && !isSameMonth(parseISO(days[index].date), parseISO(days[index - 1].date))
}

/**
 * Check if date is from before start of today.
 * @param date - date to check
 */
export const isPastDay = (date: Date | null): boolean =>
  date instanceof Date && isBefore(date, startOfDay(new Date()))

/**
 * Check if UTC date is from before start of today
 * @param day - date to check
 */
export const isPastUTCDate = (day: UTCDate): boolean => isPastDay(parseISO(day))

/**
 * Given a starting date + duration in weeks, check whether a day is within that range.
 * @param dateStart - starting date
 * @param durationWeeks - number of weeks
 * @param day - day to check
 */
export const shouldDisableDay = (dateStart: Date, durationWeeks: number) => (
  day: Date | null
): boolean => {
  if (!day) return false
  const startOfFirstDay = startOfDay(dateStart)
  const endOfLastDay = endOfDay(subDays(addWeeks(dateStart, durationWeeks), 1))
  return isBefore(day, startOfFirstDay) || isAfter(day, endOfLastDay)
}

/**
 * Get all days within a date range
 * @param dateStart - starting date
 * @param durationWeeks - number of weeks
 * @param step - step between days
 */
export const getUTCDatesWithinRange = (
  dateStart: Date,
  durationWeeks: number,
  step: number | undefined = 1
): UTCDate[] => {
  const endDate = addWeeks(dateStart, durationWeeks)
  let days: UTCDate[] = []
  let day = dateStart

  while (isBefore(day, endDate)) {
    days.push(formatUTCDate(day))
    day = addDays(day, Math.max(1, step))
  }

  return days
}

/**
 * Given a UTC date return occurrence object
 * @example createOcurrence('2020-01-02') === { date: '2020-01-02T00:00:00+00:00' }
 */
export const createOcurrence = (day: UTCDate): OcurrenceInput => {
  return {
    date: day + 'T00:00:00+00:00',
  }
}

export const getEndDate = (datetime: string, duration: number): Date => {
  const date = parseISO(datetime)
  return subDays(addWeeks(date, duration), 1)
}

export const getEndDay = (datetime: string, duration: number): string => {
  const date = getEndDate(datetime, duration)
  return formatUTCDateTime(date)
}

export const isSameDate = (day1: Date | string, day2: Date | string): boolean => {
  const d1 = day1 instanceof Date ? formatUTCDate(day1) : day1.slice(0, 10)
  const d2 = day2 instanceof Date ? formatUTCDate(day2) : day2.slice(0, 10)
  return d1 === d2
}
