import React from 'react'
import { SetOfExercisesStatistics } from 'shared/types/SetOfExercisesStatistics'
import { Icons } from 'shared/components/Icons'

export const getColor = (setStats?: SetOfExercisesStatistics): 'grey' | 'green' | 'orange' => {
  if (!setStats) return 'grey'
  return setStats.progress >= 95 ? 'green' : 'orange'
}

export const getIcon = (setStats?: SetOfExercisesStatistics): React.ReactNode => {
  if (!setStats) return <Icons.Pose />
  return setStats.state === 'completed' ? <Icons.Done /> : <Icons.StarOutlined />
}
