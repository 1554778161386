import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Cp from 'shared/data/company'
import { useToasts } from 'shared/context/toasts'
import { Company } from 'shared/types/Company'
import { UUID } from 'shared/types/UUID'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { ButtonLink } from 'shared/components/Link'
import { ListHeader } from 'shared/components/ListHeader'
import { ListFilters } from 'shared/components/ListFilters'
import { TableRowActions } from 'shared/components/TableRowActions'
import { CompaniesList } from 'shared/views/CompaniesList'
import { ActionLinkEdit } from 'shared/views/actions/ActionLinkEdit'
import { ActionToggleActiveStatus } from 'shared/views/actions/ActionToggleActiveStatus'
import { t } from 'shared/translations'
import { link } from 'modules/routes'
import { Params } from 'modules/superadmin/routes'

export const SuperAdminCompanies: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, collection, entity } = Cp.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy, updateStatus } = Cp.useQueries()

  useEffect(() => {
    let cancelled = false
    collection.loading()
    Api.fetchCompanies(query)
      .then(res => !cancelled && collection.success(res.companies, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = true
    }
  }, [collection, query])

  const activateCompany = (id: UUID) => {
    Api.activateCompany(id)
      .then(res => {
        toasts.success(`Aktywowano placówkę`)
        entity.success(res.company)
      })
      .catch(() => {
        toasts.error(`Nie udało się aktywować placówki`)
      })
  }

  const deactivateCompany = (id: UUID) => {
    Api.deactivateCompany(id)
      .then(res => {
        toasts.success(`Dezaktywowano placówkę`)
        entity.success(res.company)
      })
      .catch(() => {
        toasts.error(`Nie udało się dezaktywować placówki`)
      })
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <ListHeader
          title={t['title.companies']}
          action={
            <ButtonLink to={link['/superadmin/companies/add'](match.params)}>
              {t['title.company.add']}
            </ButtonLink>
          }
        />
        <ListFilters
          searchTerm={query.searchTerm || ''}
          status={query.status}
          searchBy={searchBy}
          updateStatus={updateStatus}
        />
        <CompaniesList
          state={state}
          order={query.order}
          loadNext={loadNext}
          sortBy={sortBy}
          rowLink={company =>
            link['/company/:companyId']({
              companyId: company.id,
            })
          }
          renderActions={props => {
            const c = props.rowData as Company
            return (
              <TableRowActions>
                <ActionLinkEdit
                  to={link['/superadmin/companies/:companyId']({
                    companyId: c.id,
                  })}
                />
                <ActionToggleActiveStatus
                  status={c.state}
                  activate={() => activateCompany(c.id)}
                  deactivate={() => deactivateCompany(c.id)}
                />
              </TableRowActions>
            )
          }}
        />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
