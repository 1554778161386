import { useState } from 'react'
import { SetOfExercisesByDay, SetsOfExercisesByDayQuery } from 'shared/types/SetOfExercises'
import { Queries, createQueries } from 'shared/utils/query'

const initialQuery: SetsOfExercisesByDayQuery = {
  offset: 0,
  limit: 16,
}

export const useQueries = () => {
  const [query, updateQuery] = useState(initialQuery)
  const queries: Queries<SetOfExercisesByDay> = createQueries({ query, updateQuery })

  return { query, ...queries }
}
