import { SetOfExercisesInput } from 'shared/types/SetOfExercises'
import { Ocurrence, OcurrenceInput } from 'shared/types/Ocurrence'
import { getUTCDatesWithinRange, createOcurrence, isPastUTCDate } from 'shared/utils/date'

/**
 * Calculate calendar dates for given exercise set, based on currently selected
 * - start date
 * - duration (in weeks)
 * - intervals between exercise set instances (in days)
 * - number of times exercise set should be done per day
 * ...as well as, in case of editing existing set
 * - frequencies
 *
 * ~~~ IMPORTANT! ~~~
 * We should only ever modify future dates!
 * We can't mess with existing occurrences (calendar dates) from the past.
 * These are exercises that may be already done by the patient, have their results, statistics etc.
 * Accidentally deleting or changing dates on such occurrences would break all exercise history!
 * ~~~ ~~~
 *
 * @example
 * selectOcurrencesWithinRange({
 *   dateStart: new Date(2020, 0, 20), // starts on Jan 20, 2020
 *   duration: 1, // lasts one week
 *   everyNumberDay: 3, // assign exercises every 3 days
 *   onceDay: 2, // assign exercises 2 times per day
 * })
 * ==
 * [
 *   { date: '2020-01-20T00:00:00+00:00' }, { date: '2020-01-20T00:00:00+00:00' },
 *   { date: '2020-01-23T00:00:00+00:00' }, { date: '2020-01-23T00:00:00+00:00' },
 *   { date: '2020-01-26T00:00:00+00:00' }, { date: '2020-01-26T00:00:00+00:00' },
 * ]
 */
export const selectOcurrencesWithinRange = (
  input: SetOfExercisesInput
): (Ocurrence | OcurrenceInput)[] => {
  const { dateStart, duration, everyNumberDay = 1, onceDay = 1, frequencies } = input

  // Double-check that starting date and duration are set before doing any calculations
  // Otherwise trying to select a calendar range will blow up
  if (dateStart && duration) {
    // 1. Take existing occurrences from the past
    const occurrencesPast = frequencies.filter(occ => isPastUTCDate(occ.date))

    // 2. Calculate new occurrences from today onwards
    const daysWithinRange = getUTCDatesWithinRange(dateStart, duration, everyNumberDay)
    const daysWithinRangeNotPast = daysWithinRange.filter(day => !isPastUTCDate(day))
    const daysWithinRangeNotPastWithArity =
      onceDay > 1
        ? daysWithinRangeNotPast.flatMap(day => Array(onceDay).fill(day))
        : daysWithinRangeNotPast
    const occurrencesFuture = daysWithinRangeNotPastWithArity.map(createOcurrence)

    // 3. Merge the results into new array of calendar dates
    return occurrencesPast.concat(occurrencesFuture)
  }

  // Return existing occurrences by default
  return frequencies
}
