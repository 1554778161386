import React, { useContext, useMemo, useReducer } from 'react'
import {
  SessionState,
  SessionAction,
  SessionActions,
  reducer,
  initialState,
} from 'shared/data/session'

// --- GLOBAL SESSION CONTEXT ---

type SessionRedux = {
  state: SessionState
  dispatch: React.Dispatch<SessionAction>
}

/**
 * Current state of user's session lives here
 */
const SessionContext: React.Context<SessionRedux> = React.createContext({
  state: initialState,
} as SessionRedux)

// --- PROVIDE SESSION ---

export const SessionProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <SessionContext.Provider value={{ state, dispatch }}>{children}</SessionContext.Provider>
}

// --- USE SESSION ---

export const useSession = () => {
  const { state, dispatch } = useContext(SessionContext)

  // ~~~ Note ~~~
  // Actions just need a dispatcher to work, they themselves are not going to change.
  // We memoize them to make sure they will not be recalculated on every state change.
  // That would risk running into infinite loops during rendering.
  const actions: SessionActions = useMemo(
    () => ({
      login: () =>
        dispatch({
          type: 'AUTH/CREDENTIALS',
        }),
      verifyToken: token =>
        dispatch({
          type: 'AUTH/TOKEN',
          payload: token,
        }),
      failure: error =>
        dispatch({
          type: 'AUTH.FAILURE',
          payload: error,
        }),
      success: session =>
        dispatch({
          type: 'AUTH.SUCCESS',
          payload: session,
        }),
      logout: () =>
        dispatch({
          type: 'LOGOUT',
        }),
    }),
    [dispatch]
  )

  return {
    state,
    actions,
  }
}
