import React from 'react'
import NativeSelect from '@material-ui/core/NativeSelect'
import { Entity } from 'shared/types/Entity'
import { UUID } from 'shared/types/UUID'

export type SelectNameProps = {
  options: string[]
  value?: string
  onChange: (name?: string) => void
}

export const SelectName: React.FC<SelectNameProps> = ({ options, value, onChange }) => {
  return (
    <NativeSelect fullWidth value={value} onChange={evt => onChange(evt.target.value)}>
      <option value="" />
      {options.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </NativeSelect>
  )
}

export type SelectEntityProps<Option extends Entity = any> = {
  options: Option[]
  getOptionLabel: (option: Option) => string
  value?: UUID
  onChange: (id?: UUID) => void
}

export const SelectEntity: React.FC<SelectEntityProps> = ({
  options,
  getOptionLabel,
  value,
  onChange,
}) => {
  return (
    <NativeSelect value={value} onChange={evt => onChange(evt.target.value)}>
      <option value="" />
      {options.map(option => (
        <option key={option.id} value={option.id}>
          {getOptionLabel(option)}
        </option>
      ))}
    </NativeSelect>
  )
}
