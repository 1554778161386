import mapValues from 'lodash/mapValues'
import isString from 'lodash/isString'
import isError from 'lodash/isError'
import isPlainObject from 'lodash/isPlainObject'
import { ServerError, ValidationErrors } from 'shared/types/Error'
import { Translations, t } from 'shared/translations'

type TranslateErrors<D extends object = any, Input extends object = any> = (
  err: ServerError<D>
) => ValidationErrors<Input>

/**
 * Translate API validation error codes to human-readable messages
 */
export const translateErrors: TranslateErrors = err =>
  err.errors ? mapValues(err.errors.fields, reasons => reasons && reasons[0] && t[reasons[0]]) : {}

/**
 * Try to turn any kind of input into a human-readable message
 */
export const explain = (value: any): string => {
  if (isString(value)) {
    if (value in t && typeof t[value as keyof Translations] === 'string') {
      return String(t[value as keyof Translations])
    }
    return value
  }
  if (isError(value)) return explain(value.message)
  if (isPlainObject(value) && value.message) return explain(value.message)
  return String(value)
}
