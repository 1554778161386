import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import * as S from 'shared/data/session'
import { useSession } from 'shared/context/session'
import { Loading } from 'shared/components/Loading'
import { isPublicRoute, isModuleRoute, canAccess } from 'modules/access'
import { entry } from 'modules/routes'
import { ResetPassword } from 'Auth/ResetPassword'
import { Login } from 'Auth/Login'
import { UpdatePassword } from '../UpdatePassword'

/**
 * Wrap stuff in <Authenticate/> to make sure it will only be seen by **authenticated** user.
 * Note: We don't check user role or id here, just checking if the user is logged in or not.
 * For more fine-grained access control, see <Authorize /> component instead.
 *
 * @example
 * <Authenticate>
 *   <div>Only logged in user will see this</div>
 * </Authenticate>
 */
export const Authenticate: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { location } = useRouter()
  const { state } = useSession()
  const isAuthenticated = S.isAuthenticated(state)
  const isVerifyingToken = S.isVerifyingToken(state)
  const session = state.session

  if (session) {
    // There are three cases when we want to redirect user automatically
    // 1. User has valid session, but is on a "public" route like login or forgot password page
    // 2. User tries to log into some weird route that doesn't match a public or module path
    // 3. User had valid session, but is trying to access a module that their role doesn't have
    // access for (patient trying to access superadmin module etc)
    //
    // In all cases we redirect user to **entry** route, which is based on user role and id
    // For a patient that can be /patients/{patient.id} and so on.
    if (
      isPublicRoute(location.pathname) ||
      !isModuleRoute(location.pathname) ||
      !canAccess(location.pathname, session)
    ) {
      return <Redirect to={entry(session)} />
    }
  }

  return isAuthenticated ? (
    <React.Fragment>{children}</React.Fragment>
  ) : isVerifyingToken ? (
    <Loading />
  ) : (
    <PublicPages />
  )
}

const PublicPages = () => {
  return (
    <Switch>
      <Route exact path="/updatePassword/:activationCode" component={UpdatePassword} />
      <Route exact path="/resetPassword" component={ResetPassword} />
      <Route exact path="/login" component={Login} />
      <Route component={Login} />
    </Switch>
  )
}
