/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { ServerError } from 'shared/types/Error'
import { H3 } from 'shared/components/Heading'
import { explain } from 'shared/utils/error'
import { t } from 'shared/translations'

type ProblemProps = {
  error?: ServerError | string
}

export const Problem: React.FC<ProblemProps> = ({ error }) => {
  return (
    <div css={cssProblemBox}>
      <H3>{t['error']}</H3>
      {error && <p css={cssProblemMessage}>{explain(error)}</p>}
    </div>
  )
}

const cssProblemBox = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 30vh;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  max-width: 600px;
`
const cssProblemMessage = css`
  font-size: 0.875rem;
`
