import React from 'react'
import * as Api from 'shared/api'
import { UUID } from 'shared/types/UUID'
import { Physiotherapist } from 'shared/types/Physiotherapist'
import { GetLabel, Fetch } from 'shared/types/Label'
import { RemoteLabel } from 'shared/components/RemoteLabel'

type PhysiotherapistLabelProps = {
  id: UUID
}

const getLabel: GetLabel<Physiotherapist> = pt => `${pt.firstName} ${pt.lastName}`
const fetch: Fetch<Physiotherapist> = id =>
  Api.fetchPhysiotherapist(id).then(res => res.physiotherapist)

export const PhysiotherapistLabel: React.FC<PhysiotherapistLabelProps> = ({ id }) => {
  return <RemoteLabel id={id} getLabel={getLabel} fetch={fetch} />
}
