import React, { useContext, useMemo, useReducer } from 'react'
import { ToastsState, ToastsAction, ToastsActions, reducer, initialState } from 'shared/data/toasts'

// --- GLOBAL TOASTS CONTEXT ---

type ToastsRedux = {
  state: ToastsState
  dispatch: React.Dispatch<ToastsAction>
}

/**
 * Current state of application toasts lives here
 */
const ToastsContext: React.Context<ToastsRedux> = React.createContext({
  state: initialState,
} as ToastsRedux)

// --- PROVIDE TOASTS ---

export const ToastsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <ToastsContext.Provider value={{ state, dispatch }}>{children}</ToastsContext.Provider>
}

// --- USE TOASTS ---

export const useToasts = () => {
  const { state, dispatch } = useContext(ToastsContext)

  // ~~~ Note ~~~
  // Actions just need a dispatcher to work, they themselves are not going to change.
  // We memoize them to make sure they will not be recalculated on every state change.
  // That would risk running into infinite loops during rendering.
  const actions: ToastsActions = useMemo(
    () => ({
      success: message =>
        dispatch({
          type: 'toasts/add',
          payload: {
            key: new Date().getTime(),
            variant: 'success',
            message,
          },
        }),
      error: message =>
        dispatch({
          type: 'toasts/add',
          payload: {
            key: new Date().getTime(),
            variant: 'error',
            message,
          },
        }),
      dismiss: key =>
        dispatch({
          type: 'toasts/dismiss',
          payload: key,
        }),
    }),
    [dispatch]
  )

  return {
    state,
    actions,
  }
}
