import { matchPath } from 'react-router'
import useRouter from 'use-react-router'
import mapValues from 'lodash/mapValues'
import { CreateLink, createLink, prefix } from 'shared/utils/url'

export type Params = {
  patientId: string
  setId?: string
  physiotherapistId?: string
}

const routes = {
  '/patient/:patientId': undefined,
  '/patient/:patientId/profile': undefined,
  '/patient/:patientId/sets': undefined,
  '/patient/:patientId/sets/:setId': undefined,
  '/patient/:patientId/sets/:setId/exercises': undefined,
  '/patient/:patientId/sets/:setId/history': undefined,
  '/patient/:patientId/physiotherapists': undefined,
  '/patient/:patientId/physiotherapists/:physiotherapistId': undefined,
  '/patient/:patientId/payments': undefined,
} as const

export const path = mapValues(routes, (v, k) => prefix + k)
export const link = mapValues(routes, (v, k) => (createLink as CreateLink<Params>)(k))

export const root = prefix + '/patient'
export const entry = '/patient/:patientId'

export const useParams = () => {
  const { location } = useRouter<Params>()
  const match = matchPath<Params>(location.pathname, prefix + entry)
  return (match && match.params) || undefined
}
