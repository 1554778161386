/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import MaterialModal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Paper from '@material-ui/core/Paper'
import { H4 } from 'shared/components/Heading'
import { ActionClose } from 'shared/views/actions/ActionClose'

type ModalProps = {
  open: boolean
  onClose: () => void
}

export const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  children,
  open,
  onClose,
}) => {
  return (
    <MaterialModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      css={cssModal}
    >
      <Paper css={cssPaper}>
        <div css={cssClose}>
          <ActionClose close={onClose} />
        </div>
        {children}
      </Paper>
    </MaterialModal>
  )
}

export const ModalTitle = styled(H4)`
  min-height: 24px;
  margin-bottom: 16px;
  font-weight: 600;
`

const cssModal = css`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const cssPaper = css`
  && {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    max-width: calc(70vw - 16px);
    max-height: calc(84vh - 16px);
    min-width: 600px;
    min-height: 400px;
    width: calc(70vw - 16px);
    height: calc(84vh - 16px);
    padding: 16px;
    border-radius: 0;
  }
`
const cssClose = css`
  position: absolute;
  right: 8px;
  top: 8px;
`
