import React from 'react'
import * as Ft from 'shared/data/physiotherapist'
import { ListProps } from 'shared/types/List'
import { SetOfExercises } from 'shared/types/SetOfExercises'
import { Column } from 'shared/types/Table'
import { Wrapper } from 'shared/components/Wrapper'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { StatusText } from 'shared/views/text/StatusText'
import { createScrollHandler } from 'shared/utils/scroll'
import { toDateRangeLabel } from 'shared/utils/date'
import { t } from 'shared/translations'
import { SetsOfExercisesTable } from './SetsOfExercisesTable'

export const SetsOfExercisesList: React.FC<ListProps<SetOfExercises>> = ({
  state,
  order,
  loadNext,
  sortBy,
  rowLink,
}) => {
  const { data, meta } = state
  const [[key, value = 'asc']] = Object.entries(order || Ft.defaultOrder)

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const handleScroll = createScrollHandler({ data, meta, loadNext })

  return (
    <Wrapper>
      <SetsOfExercisesTable
        columns={dataColumns}
        rowCount={data.length}
        rowGetter={row => data[row.index]}
        rowLink={rowLink}
        handleScroll={handleScroll}
        handleRequestSort={sortBy}
        orderKey={key as keyof SetOfExercises}
        orderValue={value}
      />
    </Wrapper>
  )
}

const dataColumns: Column<SetOfExercises>[] = [
  { id: 'number', label: t['property.number'], width: 150, minWidth: 100 },
  { id: 'name', label: t['property.name'], width: 300, minWidth: 160 },
  {
    id: 'state',
    label: t['property.state'],
    width: 100,
    minWidth: 80,
    cellRenderer: props => {
      const { state } = props.rowData as SetOfExercises
      return <StatusText status={state} />
    },
  },
  {
    id: 'physiotherapistFullName',
    label: t['property.physiotherapistFullName'],
    width: 300,
    minWidth: 160,
  },
  {
    id: 'dateStart',
    label: t['property.validityPeriod'],
    width: 300,
    minWidth: 100,
    cellRenderer: props => {
      const { dateStart, duration } = props.rowData as SetOfExercises
      return toDateRangeLabel(dateStart.substring(0,10), duration)
    },
  },
]
