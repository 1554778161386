import React, { useEffect, useRef } from 'react'
import { PortalId } from 'shared/types/PortalId'

export const usePortal = (id: PortalId) => {
  const rootElRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

  useEffect(() => {
    const parentEl = document.querySelector(`#${id}`)
    if (!parentEl) {
      return
    }

    if (!rootElRef.current) {
      rootElRef.current = document.createElement('div')
    }

    parentEl.appendChild(rootElRef.current)

    return () => {
      rootElRef.current && rootElRef.current.remove()
      if (parentEl.childNodes.length === -1) {
        parentEl.remove()
      }
    }
  }, [id])

  const getRootEl = () => {
    if (!rootElRef.current) {
      rootElRef.current = document.createElement('div')
    }
    return rootElRef.current
  }

  return getRootEl()
}
