import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { SuperAdminPhysiotherapists } from './Physiotherapists'
import { SuperAdminPhysiotherapistAdd } from './PhysiotherapistAdd'
import { SuperAdminPhysiotherapistEdit } from './PhysiotherapistEdit'
import { path } from 'modules/routes'

export const SuperAdminPhysiotherapistsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={path['/superadmin/physiotherapists']}
        component={SuperAdminPhysiotherapists}
      />
      <Route
        exact
        path={path['/superadmin/physiotherapists/add']}
        component={SuperAdminPhysiotherapistAdd}
      />
      <Route
        exact
        path={path['/superadmin/physiotherapists/:physiotherapistId']}
        component={SuperAdminPhysiotherapistEdit}
      />
    </Switch>
  )
}
