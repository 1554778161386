/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { portalWithModals } from 'shared/views/portals/PortalWithModals'

export const AppModals: React.FC = () => {
  return <div id={portalWithModals} css={cssFullscreen} />
}

const cssFullscreen = css`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
