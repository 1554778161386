import React, { useEffect } from 'react'
import * as Api from 'shared/api'
import * as T from 'shared/data/template'
import { Template } from 'shared/types/Template'
import { Modal, ModalTitle } from 'shared/components/Modal'
import { Search } from 'shared/components/Search'
import { TemplatesListSmall } from 'shared/views/TemplatesListSmall'
import { t } from 'shared/translations'
import useRouter from 'use-react-router'
import { Params } from '../../../modules/physiotherapist'

type ModalProps = {
  cancel: () => void
  select: (ex: Template) => void
}

export const ModalSelectTemplate: React.FC<ModalProps> = ({ cancel, select }) => {
  const { match } = useRouter<Params>()
  const { state, collection } = T.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy } = T.useQueries()
  const { physiotherapistId } = match.params

  useEffect(() => {
    let cancelled = false
    collection.loading()
    Api.fetchTemplates({...query, physiotherapists: [physiotherapistId]})
      .then(res => !cancelled && collection.success(res.template, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = true
    }
  }, [collection, query])

  return (
    <Modal open={true} onClose={cancel}>
      <ModalTitle>{t['title.templates']}</ModalTitle>
      <Search placeholder={t['placeholder.search_template']} value={query.searchTerm || ''} onChange={evt => searchBy(evt.target.value)} />
      <TemplatesListSmall
        select={select}
        state={state}
        order={query.order}
        loadNext={loadNext}
        sortBy={sortBy}
      />
    </Modal>
  )
}
