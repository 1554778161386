import React from 'react'
import { NotificationType } from 'shared/types/Notification'
import { Icons } from 'shared/components/Icons'

type NotificationIconProps = {
  type: NotificationType
}

export const NotificationIcon: React.FC<NotificationIconProps> = ({ type }) => {
  switch (type) {
    case 'set_of_exercises_created':
    case 'set_of_exercises_changed':
    case 'set_of_exercises_expired': {
      return <Icons.Pose />
    }
    case 'set_of_exercises_undone': {
      return <Icons.ReportProblem />
    }
    case 'set_of_exercises_prompt': {
      return <Icons.Alarm />
    }
    case 'set_of_exercises_payment_failed':
    case 'set_of_exercises_paid': {
      return <Icons.Payment />
    }
    case 'profile_data_changed':
    case 'physiotherapist_created':
    case 'uncertain':
    case 'invitation':
    case 'invoice': {
      return <Icons.StarOutlined />
    }
  }
}
