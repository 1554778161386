import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Pt from 'shared/data/patient'
import { useToasts } from 'shared/context/toasts'
import { PatientInput } from 'shared/types/Patient'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { FormFooter } from 'shared/components/FormFooter'
import { FormHeader } from 'shared/views/headers/FormHeader'
import { PatientProfileForm } from 'shared/views/PatientProfileForm'
import { createEditFormFieldValidator } from 'shared/utils/validation'
import { Params, link } from 'modules/patient/routes'

export const PatientProfile: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, entity } = Pt.useEntityReducer()
  const { patientId } = match.params
  const { data } = state
  const backToEntry = link['/patient/:patientId']({ patientId: match.params.patientId })

  useEffect(() => {
    let cancelled = false
    if (patientId) {
      entity.loading()
      Api.fetchPatient(patientId)
        .then(res => !cancelled && entity.success(res.patient))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, patientId])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const save = (input: PatientInput) => {
    entity.loading()
    Api.updatePatient(Pt.payloadUpdate(data, input))
      .then(res => {
        toasts.success(`Zapisano zmiany`)
        entity.success(res.patient)
      })
      .catch(err => {
        toasts.error(`Nie udało się zapisać zmian`)
        entity.failure(err)
      })
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <FormHeader back={backToEntry} title={'Profil'} />
        <PatientProfileForm
          createFieldValidator={createEditFormFieldValidator}
          state={state}
          save={save}
          headerActions={[]}
          footerActions={[]}
        />
        <FormFooter />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
