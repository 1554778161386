import React from 'react'
import { SetOfExercisesByDay } from 'shared/types/SetOfExercises'
import { TimelineProps, Timeline } from 'shared/components/Timeline'
import { TimelineEntryPastSet } from 'shared/views/timeline/TimelineEntryPastSet'
import { TimelineEntryFutureSet } from 'shared/views/timeline/TimelineEntryFutureSet'

const SetsOfExercisesByDayTimeline: React.FC<TimelineProps<SetOfExercisesByDay>> = Timeline

type SetsOfExercisesByDayTimelineProps = {
  entries: SetOfExercisesByDay[]
  selected?: SetOfExercisesByDay
  select: (entry: SetOfExercisesByDay) => void
}

export const SetsOfExercisesByDayPastTimeline: React.FC<SetsOfExercisesByDayTimelineProps> = props => {
  return (
    <SetsOfExercisesByDayTimeline
      {...props}
      renderEntry={entry => (
        <TimelineEntryPastSet
          entry={entry}
          isSelected={Boolean(props.selected && props.selected.occurrenceId === entry.occurrenceId)}
        />
      )}
    />
  )
}

export const SetsOfExercisesByDayFutureTimeline: React.FC<SetsOfExercisesByDayTimelineProps> = props => {
  return (
    <SetsOfExercisesByDayTimeline
      {...props}
      renderEntry={entry => (
        <TimelineEntryFutureSet
          entry={entry}
          isSelected={Boolean(props.selected && props.selected.occurrenceId === entry.occurrenceId)}
        />
      )}
    />
  )
}
