import { Session } from 'shared/types/Session'
import { prefix } from 'shared/utils/url'
import * as Cp from './company'
import * as Ft from './physiotherapist'
import * as Pt from './patient'

const PUBLIC_ROUTES = ['/', '/login', '/resetPassword', 'updatePassword']
const MODULE_PREFIXES = ['/superadmin', '/company', '/physiotherapist', '/patient']

/**
 * Check if pathname is a public route (doesn't require any authentication)
 */
export const isPublicRoute = (pathname: string): boolean =>
  PUBLIC_ROUTES.map(p => prefix + p).includes(pathname)

/**
 * Check if pathname belongs to some of the modules
 */
export const isModuleRoute = (pathname: string): boolean =>
  MODULE_PREFIXES.map(p => prefix + p).some(p => pathname.startsWith(p))

/**
 * Check if pathname can be accessed by the user based on their current session
 */
export const canAccess = (pathname: string, session?: Session): boolean => {
  // Everyone has access to public routes
  if (isPublicRoute(pathname)) {
    return true
  }

  // This is not a public route, unauthenticated users are not allowed here
  if (!session) {
    return false
  }

  // This is not a public route, user is logged in
  // Let's check their session (role, id) to see if they don't try to access part of application
  // that's forbidden for them.
  switch (session.role) {
    case 'superadmin':
      return true
    case 'company': {
      return [
        Cp.link['/company/:companyId']({ companyId: session.userId }), // Us
        Ft.root, // Our physiotherapists
        Pt.root, // Out patients
      ].some(allowedRoot => pathname.startsWith(allowedRoot))
    }
    case 'physiotherapist': {
      return [
        Ft.link['/physiotherapist/:physiotherapistId']({ physiotherapistId: session.userId }),
        Pt.root, // My patients
      ].some(allowedRoot => pathname.startsWith(allowedRoot))
    }
    case 'patient': {
      const allowedRoot = Pt.link['/patient/:patientId']({ patientId: session.userId }) // Me
      return pathname.startsWith(allowedRoot)
    }
    default:
      return false
  }
}
