import { FetchOne, PatchOne, fetchOne, patchOne } from 'shared/api/crud'
import { UUID } from 'shared/types/UUID'
import { ChatConfiguration } from 'shared/types/ChatConfiguration'

export type ChatConfigurationPayload = {
  chat: ChatConfiguration
}

export const fetchChatConfiguration: FetchOne<ChatConfigurationPayload> = fetchOne(
  (callerId: UUID, receiverId: UUID) => `chats/configuration/${callerId}/${receiverId}`
)

export const callUp: PatchOne<any> = patchOne(
  (callerId: UUID, receiverId: UUID) => `chats/callUp/${callerId}/${receiverId}`
)

export const hangUp: PatchOne<any> = patchOne(
  (callerId: UUID, receiverId: UUID) => `chats/hangUp/${callerId}/${receiverId}`
)
