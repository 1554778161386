import { useState } from 'react'
import { Exercise } from 'shared/types/Exercise'
import { Query } from 'shared/types/Query'
import { Order } from 'shared/types/Order'
import { Queries, createQueries } from 'shared/utils/query'

export const defaultOrder: Order<Exercise> = { name: 'asc' }

const initialQuery: Query<Exercise> = {
  order: defaultOrder,
  offset: 0,
  limit: 16,
}

export const useQueries = () => {
  const [query, updateQuery] = useState(initialQuery)
  const queries: Queries<Exercise> = createQueries({ query, updateQuery })

  return { query, ...queries }
}
