import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Ft from 'shared/data/physiotherapist'
import { useToasts } from 'shared/context/toasts'
import { PhysiotherapistInput } from 'shared/types/Physiotherapist'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { FormFooter } from 'shared/components/FormFooter'
import { FormHeader } from 'shared/views/headers/FormHeader'
import { PhysiotherapistForm } from 'shared/views/PhysiotherapistForm'
import { ActionChangeStatus } from 'shared/views/actions/ActionChangeStatus'
import { ActionResetPassword } from 'shared/views/actions/ActionResetPassword'
import { createEditFormFieldValidator } from 'shared/utils/validation'
import { link } from 'modules/routes'
import { Params } from 'modules/superadmin/routes'

export const SuperAdminPhysiotherapistEdit: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, entity } = Ft.useEntityReducer()
  const [redirect, setRedirect] = useState<string>()
  const { physiotherapistId } = match.params
  const { data } = state
  const backToList = link['/superadmin/physiotherapists'](match.params)

  useEffect(() => {
    let cancelled = false
    if (physiotherapistId) {
      entity.loading()
      Api.fetchPhysiotherapist(physiotherapistId)
        .then(res => !cancelled && entity.success(res.physiotherapist))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, physiotherapistId])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const save = (input: PhysiotherapistInput) => {
    entity.loading()
    Api.updatePhysiotherapist(Ft.payloadUpdate(data, input))
      .then(res => {
        toasts.success(`Zapisano zmiany`)
        entity.success(res.physiotherapist)
        setRedirect(backToList)
      })
      .catch(err => {
        toasts.success(`Nie udało się zapisać zmian`)
        entity.failure(err)
      })
  }

  const activate = () => {
    entity.loading()
    Api.activatePhysiotherapist(data.id)
      .then(res => {
        toasts.success(`Aktywowano fizjoterapeutę`)
        entity.success(res.physiotherapist)
      })
      .catch(() => {
        toasts.error(`Nie udało się aktywować fizjoterapeuty`)
      })
  }

  const deactivate = () => {
    entity.loading()
    Api.deactivatePhysiotherapist(data.id)
      .then(res => {
        toasts.success(`Dezaktywowano fizjoterapeutę`)
        entity.success(res.physiotherapist)
      })
      .catch(() => {
        toasts.error(`Nie udało się dezaktywować fizjoterapeuty`)
      })
  }

  const resetPassword = () => {
    Api.resetPassword(data.email)
      .then(() => toasts.success(`Wysłano email na adres "${data.email}"`))
      .catch(() => toasts.error(`Nie udało się wysłać emaila`))
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <FormHeader
          back={backToList}
          title={`${data.firstName} ${data.lastName}`}
          status={data.state}
        />
        <PhysiotherapistForm
          createFieldValidator={createEditFormFieldValidator}
          state={state}
          save={save}
          headerActions={[
            () => (
              <ActionChangeStatus
                activate={activate}
                deactivate={deactivate}
                status={data.state}
                disabled={state.pending}
                renderAsButton={false}
              />
            ),
            () => (
              <ActionResetPassword
                resetPassword={resetPassword}
                disabled={state.pending}
                renderAsButton={false}
              />
            ),
          ]}
          footerActions={[
            () => (
              <ActionChangeStatus
                activate={activate}
                deactivate={deactivate}
                status={data.state}
                disabled={state.pending}
                renderAsButton={true}
              />
            ),
            () => (
              <ActionResetPassword
                resetPassword={resetPassword}
                disabled={state.pending}
                renderAsButton={true}
              />
            ),
          ]}
        />
        <FormFooter />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
