import { apiUrl } from '../config'
import { request } from '../utils'
import { Credentials } from 'shared/types/Credentials'
import { Session } from 'shared/types/Session'
import { getXTokenHeader } from '../config'

const authenticate = 'authenticate'

export const login = (credentials: Credentials): Promise<Session> =>
  request.post(`${apiUrl}/${authenticate}`, credentials)

export const verifyToken = (token: string): Promise<Session> =>
  request.get(`${apiUrl}/${authenticate}/${token}`, {
    headers: {
      ...getXTokenHeader(),
    },
  })

export const logout = (token: string): Promise<void> =>
  request.delete(`${apiUrl}/${authenticate}/${token}`, {
    headers: {
      ...getXTokenHeader(),
    },
  })


