/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import trim from 'lodash/trim'
import * as Ft from 'shared/data/physiotherapist'
import { ListProps } from 'shared/types/List'
import { Exercise } from 'shared/types/Exercise'
import { Column } from 'shared/types/Table'
import { Wrapper } from 'shared/components/Wrapper'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { ActionPlayVideo, ActionPlayVideoPreview } from 'shared/views/actions/ActionPlayVideo'
import { createScrollHandler } from 'shared/utils/scroll'
import { t } from 'shared/translations'
import { ExercisesTable } from './ExercisesTable'

export const ExercisesList: React.FC<ListProps<Exercise>> = ({
  state,
  order,
  loadNext,
  sortBy,
  rowLink,
}) => {
  const { data, meta } = state
  const [[key, value = 'asc']] = Object.entries(order || Ft.defaultOrder)

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const handleScroll = createScrollHandler({ data, meta, loadNext })

  return (
    <Wrapper>
      <ExercisesTable
        columns={dataColumns}
        rowHeight={100}
        rowCount={data.length}
        rowGetter={({ index }) => data[index]}
        rowLink={rowLink}
        handleScroll={handleScroll}
        handleRequestSort={sortBy}
        orderKey={key as keyof Exercise}
        orderValue={value}
      />
    </Wrapper>
  )
}

const dataColumns: Column<Exercise>[] = [
  {
    id: 'name',
    label: t['property.name'],
    width: 500,
    cellRenderer: props => {
      const { name } = props.rowData
      return (
        <div css={cssCell}>
          <div css={cssExerciseName}>{name}</div>
        </div>
      )
    },
  },
  {
    id: 'description',
    label: t['property.description'],
    width: 900,
    sortable: false,
    cellRenderer: props => {
      const ex = props.rowData as Exercise
      return (
        <div css={cssCell}>
          <div css={cssDescription}>{trim(ex.description.substr(0, 300))}...</div>
        </div>
      )
    },
  },
  {
    id: 'actions',
    label: '',
    width: 200,
    sortable: false,
    cellRenderer: props => {
      const ex = props.rowData as Exercise
      return (
        <div css={cssCell}>
          <ActionPlayVideo
            fileId={ex.id}
            description={ex.description}
            renderer={ActionPlayVideoPreview}
          />
        </div>
      )
    },
  },
]

const cssCell = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  justify-content: space-between;
  min-height: 100px;
  display: table;
`
const cssDescription = css`
  text-overflow: hidden;
  display: flex;
  flex-direction: column;
  border: unset;
  overflow: hidden;
  font-size: 0.875rem;
  justify-content: flex-start;
  white-space: pre-line;
`
const cssExerciseName = css`
  white-space: pre-line;
`
