/** @jsx jsx */
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { colors } from 'shared/styles/colors'

export const IconLink = styled(Link)`
  width: 40px;
  height: 40px;
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  overflow: visible;
  font-size: 2.142857142857143rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  margin: 0;
  cursor: pointer;
  display: inline-flex;
  outline: none;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  -moz-appearance: none;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

export const ButtonLink = styled(Link)`
  background: ${colors.mainOrange};
  color: white;
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 3px;
`
