/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Icons } from 'shared/components/Icons'
import { IconButton } from 'shared/components/Button'
import { t } from 'shared/translations'
import { colors } from 'shared/styles/colors'

type ActionDisconnectProps = {
  disconnect: () => void
}

export const ActionDisconnect: React.FC<ActionDisconnectProps> = ({ disconnect }) => {
  return (
    <IconButton onClick={disconnect} title={t['action.disconnect']} css={cssRed}>
      <Icons.Close />
    </IconButton>
  )
}

const cssRed = css`
  background-color: ${colors.additionalRed} !important;
  color: white !important;
`
