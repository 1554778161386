/**
 * @example '2020-01-31'
 */
export type UTCDate = string

/**
 * @example '2020-01-31T00:00:00+00:00'
 */
export type UTCDateTime = string

const UTC_DATE = /^\d{4}-\d{2}-\d{2}$/
const UTC_DATETIME = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+00:00$/

/**
 * Check for expected date ISO format `yyyy-MM-dd`
 * This should be useful for date-range filter queries ("from" date, "to" date).
 */
export const isUTCDate = (v: any): v is UTCDate => typeof v === 'string' && UTC_DATE.test(v)

/**
 * Check for expected datetime ISO format `yyyy-MM-ddTHH:mm:ss+00:00`
 * This formatting (with the timezone +00:00) is specifically required by the server.
 */
export const isUTCDateTime = (v: any): v is UTCDateTime =>
  typeof v === 'string' && UTC_DATETIME.test(v)

/**
 * Check for either one of recognizable UTC formats
 */
export const isUTC = (v: any) => isUTCDate(v) || isUTCDateTime(v)
