import React from 'react'
import uniqBy from 'lodash/uniqBy'
import * as Ft from 'shared/data/physiotherapist'
import { ListProps } from 'shared/types/List'
import { Column } from 'shared/types/Table'
import { Template } from 'shared/types/Template'
import { ExerciseParameters } from 'shared/types/ExerciseParameters'
import { Wrapper } from 'shared/components/Wrapper'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { createScrollHandler } from 'shared/utils/scroll'
import { t } from 'shared/translations'
import { ExerciseLabel } from 'shared/views/labels/ExerciseLabel'
import { TemplatesTable } from './TemplatesTable'

type SmallListProps = {
  select: (ex: Template) => void
}

export const TemplatesListSmall: React.FC<ListProps<Template> & SmallListProps> = ({
  state,
  order,
  loadNext,
  sortBy,
  select,
}) => {
  const { data, meta } = state
  const [[key, value = 'asc']] = Object.entries(order || Ft.defaultOrder)

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const handleScroll = createScrollHandler({ data, meta, loadNext })

  const columns = dataColumns

  return (
    <Wrapper>
      <TemplatesTable
        columns={columns}
        rowCount={data.length}
        rowGetter={row => data[row.index]}
        handleScroll={handleScroll}
        handleRequestSort={sortBy}
        orderKey={key as keyof Template}
        orderValue={value}
        onRowClick={row => select(data[row.index])}
      />
    </Wrapper>
  )
}

const dataColumns: Column<Template>[] = [
  { id: 'name', label: t['property.name'], width: 800 },
  {
    id: 'templateExercisesParameters',
    label: t['title.exercises'],
    width: 1200,
    cellRenderer: props => {
      const { templateExercisesParameters } = props.rowData as Template
      return (
        <React.Fragment>
          {uniqBy(templateExercisesParameters, 'exercise').map((xp: ExerciseParameters) => (
            <ExerciseLabel key={xp.exercise} id={xp.exercise} />
          ))}
        </React.Fragment>
      )
    },
  },
]
