import { useReducer, useMemo } from 'react'
import * as RC from 'shared/types/RemoteCollection'
import * as RE from 'shared/types/RemoteEntity'

export type UseRemoteCollectionReducer<D extends object = any> = (
  initialState?: RC.RemoteCollection<D>
) => {
  state: RC.RemoteCollection<D>
  collection: RC.RemoteCollectionActions<D>
  entity: RE.RemoteEntityActions<D>
}

export const useRemoteCollectionReducer: UseRemoteCollectionReducer = initialState => {
  const [state, dispatch] = useReducer(RC.remoteCollectionReducer, initialState || RC.idle)
  const collection = useMemo(() => RC.createCollectionActions(dispatch), [dispatch])
  const entity = useMemo(() => RE.createEntityActions(dispatch), [dispatch])

  return {
    state,
    collection,
    entity,
  }
}
