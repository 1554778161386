/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Icons } from 'shared/components/Icons'
import { IconButton } from 'shared/components/Button'

type ActionToggleCameraProps = {
  on: boolean
  toggle: () => void
}

export const ActionToggleCamera: React.FC<ActionToggleCameraProps> = ({ on, toggle }) => {
  return (
    <IconButton onClick={toggle} title={on ? 'Wyłącz kamerę' : 'Włącz kamerę'} css={cssGrey}>
      {on ? <Icons.Videocam /> : <Icons.VideocamOff />}
    </IconButton>
  )
}

const cssGrey = css`
  background-color: #666 !important;
  color: white !important;
`
