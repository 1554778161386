/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'
import { portalWithFooterActions } from 'shared/views/portals/PortalWithFooterActions'

export const FormFooter: React.FC = () => {
  return <Footer id={portalWithFooterActions} />
}

// By default this footer will have no content, so it will have 0 height.
// Once something is rendered into footer actions portal, this form footer will expand accordingly.
// However, we want to keep some max height, so that teleported stuff will not break the layout.
export const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  & > div {
    display: flex;
  }
  & > div > div:not(:first-of-type) {
    margin-left: 16px;
  }
`
