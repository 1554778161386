import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import isFunction from 'lodash/isFunction'
import { Status } from 'shared/types/Status'
import { Icons } from 'shared/components/Icons'
import { IconButton } from 'shared/components/Button'
import { t } from 'shared/translations'

type ActionToggleActiveStatusProps = {
  status?: Status
  activate?: () => void
  deactivate?: () => void
}

export const ActionToggleActiveStatus: React.FC<ActionToggleActiveStatusProps> = ({
  status,
  activate,
  deactivate,
}) => {
  switch (status) {
    case 'new':
    case 'active': {
      return !isFunction(deactivate) ? null : (
        <Tooltip title={t['action.deactivate']}>
          <IconButton aria-label={t['action.deactivate']} onClick={deactivate}>
            <Icons.Close />
          </IconButton>
        </Tooltip>
      )
    }
    case 'inactive': {
      return !isFunction(activate) ? null : (
        <Tooltip title={t['action.activate']}>
          <IconButton aria-label={t['action.activate']} onClick={activate}>
            <Icons.Check />
          </IconButton>
        </Tooltip>
      )
    }
    default:
      return null
  }
}
