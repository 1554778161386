import React from 'react'
import clsx from 'clsx'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useToasts } from 'shared/context/toasts'
import * as Ts from 'shared/data/toasts'
import { Icons } from 'shared/components/Icons'

const variantIcon = {
  success: Icons.Check,
  error: Icons.Error,
}

const variantAutoHide = {
  success: 4000,
  error: null,
}

const useSnackbarStyles = makeStyles((theme: Theme) => ({
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface Props {
  className?: string
  message?: string
  onClose?: () => void
  variant: keyof typeof variantIcon
}

function ToastContentWrapper(props: Props) {
  const classes = useSnackbarStyles()
  const { className, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  return (
    <SnackbarContent
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <Icons.Close className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  )
}

export const AppToasts: React.FC = () => {
  const { state, actions } = useToasts()
  const toast = Ts.selectCurrentToast(state)

  const close = () => {
    if (toast) {
      actions.dismiss(toast.key)
    }
  }

  return toast ? (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={true}
      onClose={close}
      autoHideDuration={variantAutoHide[toast.variant]}
    >
      <ToastContentWrapper onClose={close} variant={toast.variant} message={toast.message} />
    </Snackbar>
  ) : null
}
