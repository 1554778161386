import React from 'react'
import styled from '@emotion/styled'
import { useSession } from 'shared/context/session'
import { IconLink } from 'shared/components/Link'
import { entry } from 'modules/routes'
import logo from 'shared/assets/rehabee_logo_small.svg'

export const AppLogo: React.FC = () => {
  const { state } = useSession()
  const { session } = state

  return session ? (
    <Logo to={entry(session)}>
      <img src={logo} alt="RehaBee" />
    </Logo>
  ) : null
}

const Logo = styled(IconLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: auto;
  width: 11rem;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
  background: white;
  font-weight: bold;
  img {
    width: 10rem;
  }
  &:hover {
    cursor: pointer;
    background: white;
  }
`
