import * as Storage from 'shared/storage'

export const apiUrl = process.env.REACT_APP_API_URL

const getHeaders = () => {
  const token = Storage.loadSessionToken()
  return {
    'Content-Type': 'application/json',
    ...(token && { 'X-Token': token }),
  }
}

export const getXTokenHeader = () => {
  const token = Storage.loadSessionToken()
  return {
    ...(token && { 'X-Token': token }),
  }
}

export const defaults = {
  head: () => ({
    method: 'HEAD',
  }),
  get: () => ({
    method: 'GET',
    headers: getHeaders(),
  }),
  post: () => ({
    method: 'POST',
    headers: getHeaders(),
  }),
  put: () => ({
    method: 'PUT',
    headers: getHeaders(),
  }),
  patch: () => ({
    method: 'PATCH',
    headers: getHeaders(),
  }),
  delete: () => ({
    method: 'DELETE',
    headers: getHeaders(),
  }),
}
