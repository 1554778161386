import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as SetEx from 'shared/data/setOfExercises'
import * as Layout from 'shared/components/Layout'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { Tabs } from 'shared/components/Tabs'
import { t } from 'shared/translations'
import { PatientSetExpired } from './SetExpired'
import { PatientSetTabExercises } from './SetTabExercises'
import { PatientSetTabHistory } from './SetTabHistory'
import { link, path } from 'modules/routes'
import { Params } from 'modules/patient/routes'

export const PatientSet: React.FC = () => {
  const { match } = useRouter<Params>()
  const { state, entity } = SetEx.useEntityReducer()
  const { data } = state
  const { setId } = match.params

  useEffect(() => {
    let cancelled = false
    if (setId) {
      entity.loading()
      Api.fetchSetOfExercises(setId)
        .then(res => !cancelled && entity.success(res.setOfExercises))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, setId])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const tabs = [
    {
      label: t['title.setOfExercises'],
      to: link['/patient/:patientId/sets/:setId/exercises'](match.params),
    },
    {
      label: t['title.exercisesHistory'],
      to: link['/patient/:patientId/sets/:setId/history'](match.params),
    },
  ]

  return (
    <Layout.FlexColumn>
      <Tabs tabs={tabs} />
      {data.state === 'inactive' ? (
        <PatientSetExpired setOfExercises={data} />
      ) : (
        <Switch>
          <Route
            exact
            path={path['/patient/:patientId/sets/:setId']}
            component={() => (
              <Redirect to={link['/patient/:patientId/sets/:setId/exercises'](match.params)} />
            )}
          />
          <Route
            exact
            path={path['/patient/:patientId/sets/:setId/exercises']}
            component={PatientSetTabExercises}
          />
          <Route
            exact
            path={path['/patient/:patientId/sets/:setId/history']}
            component={PatientSetTabHistory}
          />
        </Switch>
      )}
    </Layout.FlexColumn>
  )
}
