/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'

export const FlexRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
`

export const FlexColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const FlexColumnScrollable = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`

export const FlexLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

/**
 * ------------------------------
 *  Master  |     Details
 *          |
 *          |
 * ------------------------------
 */
export const Master = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 0;
  padding-top: 20px;
  margin: 0;
  width: 360px;
      background: #fafafa;
  flex-direction: column;
`

export const MasterHeader = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0 16px;
  margin: 0;
`
export const MasterSection = styled.section`
  overflow-y: auto;
  width: 100%;
  height: calc(100% - 36px - 16px - 16px);
  padding: 0 16px;
  margin: 16px 0;
`

/**
 * ------------------------------
 *  Master  |     Details
 *          |
 *          |
 * ------------------------------
 */
export const Details = styled.div`
  overflow-y: auto;
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 360px;
  right: 0;
  padding: 0;
  margin: 0;
  flex-direction: column;
`

export const DetailsHeader = styled.header`
  position: sticky;
  top: 0;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 16px;
`
export const DetailsSection = styled.section`
  width: 100%;
  padding: 0 16px;
  margin: 16px 0;
`
export const DetailsFooter = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 16px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

export const ScrollableColumn: React.FC<React.PropsWithChildren<{ height: number }>> = ({
  children,
  height,
}) => {
  return <div css={cssScrollableColumn(height)}>{children}</div>
}

const cssScrollableColumn = (height: number) => css`
  height: ${height}px;
  padding: 0 16px;
  overflow-y: scroll;
`

/**
 * back     content                                    actions
 * -----------------------------------------------------------------
 *  (<) TITLE [status]                           (action1) (action2)
 *      additional info 1
 *      additional info 2
 * -----------------------------------------------------------------
 */
type HeaderProps = {
  renderBack?: React.ReactNode
  renderContent: React.ReactNode
  renderActions?: React.ReactNode
}

export const Header: React.FC<HeaderProps> = ({ renderBack, renderContent, renderActions }) => {
  return (
    <header css={cssHeader}>
      <div css={cssHeaderStart}>
        {renderBack}
        <div css={cssHeaderContent}>{renderContent}</div>
      </div>
      <div css={cssHeaderEnd}>{renderActions}</div>
    </header>
  )
}

const cssHeader = css`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 16px;
  background: white;
`
const cssHeaderStart = css`
  display: flex;
  flex: 1;
  padding: 8px 0;
`
const cssHeaderEnd = css`
  display: flex;
  flex: 0;
  flex-basis: auto;
  padding: 8px 0;
`
const cssHeaderContent = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 8px;
`
