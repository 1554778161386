/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Availability } from 'shared/types/Availability'
import { colors } from 'shared/styles/colors'
import { t } from 'shared/translations'

type AvailabilityStatusProps = {
  availability: Availability
}

export const AvailabilityStatus: React.FC<AvailabilityStatusProps> = ({ availability }) => {
  const status = StatusStyle[availability]

  return <div css={cssStatus(status)}>{t[availability]}</div>
}

type StatusStyle = {
  color: string
  isFull: boolean
}

const StatusStyle: Record<Availability, StatusStyle> = {
  offline: { color: colors.mediumGrey, isFull: true },
  inactive: { color: colors.actionGreen, isFull: false },
  online: { color: colors.actionGreen, isFull: true },
  busy: { color: colors.actionRed, isFull: true },
}

const cssStatus = (status: StatusStyle) => css`
  margin-left: 40px;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.8rem;
  color: ${colors.lightestGrey};
  background: ${status.isFull ? status.color : 'transparent'};
`
