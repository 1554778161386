import React from 'react'
import * as Ft from 'shared/data/physiotherapist'
import { ListProps } from 'shared/types/List'
import { Exercise } from 'shared/types/Exercise'
import { Column } from 'shared/types/Table'
import { Wrapper } from 'shared/components/Wrapper'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { createScrollHandler } from 'shared/utils/scroll'
import { t } from 'shared/translations'
import { ExercisesTable } from './ExercisesTable'

type SmallListProps = {
  select: (ex: Exercise) => void
}

export const ExercisesListSmall: React.FC<ListProps<Exercise> & SmallListProps> = ({
  state,
  order,
  loadNext,
  sortBy,
  renderActions,
  select,
}) => {
  const { data, meta } = state
  const [[key, value = 'asc']] = Object.entries(order || Ft.defaultOrder)

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const handleScroll = createScrollHandler({ data, meta, loadNext })

  let columns = renderActions
    ? [...dataColumns, { ...actionColumn, cellRenderer: renderActions }]
    : dataColumns

  return (
    <Wrapper>
      <ExercisesTable
        columns={columns}
        rowCount={data.length}
        rowGetter={row => data[row.index]}
        handleScroll={handleScroll}
        handleRequestSort={sortBy}
        orderKey={key as keyof Exercise}
        orderValue={value}
        onRowClick={row => select(data[row.index])}
      />
    </Wrapper>
  )
}

const dataColumns: Column<Exercise>[] = [{ id: 'name', label: t['property.name'], width: 1800 }]
const actionColumn: Column<Exercise> = {
  id: 'actions',
  label: '',
  width: 200,
}
