import AccessibilityNew from '@material-ui/icons/AccessibilityNew'
import AccessibilityNewOutlined from '@material-ui/icons/AccessibilityNewOutlined'
import AccessTime from '@material-ui/icons/AccessTime'
import AddCommentOutlined from '@material-ui/icons/AddCommentOutlined'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import Alarm from '@material-ui/icons/Alarm'
import ArrowBack from '@material-ui/icons/ArrowBack'
import Assignment from '@material-ui/icons/Assignment'
import Business from '@material-ui/icons/Business'
import Calendar from '@material-ui/icons/CalendarToday'
import Call from '@material-ui/icons/Call'
import Check from '@material-ui/icons/Check'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Close from '@material-ui/icons/Close'
import Create from '@material-ui/icons/Create'
import CreateOutlined from '@material-ui/icons/CreateOutlined'
import CreditCard from '@material-ui/icons/CreditCard'
import Delete from '@material-ui/icons/Delete'
import DeleteForever from '@material-ui/icons/DeleteForever'
import Done from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'
import HourglassFull from '@material-ui/icons/HourglassFull'
import HowToReg from '@material-ui/icons/HowToReg'
import KeyboardTab from '@material-ui/icons/KeyboardTab'
import List from '@material-ui/icons/List'
import LockOutlined from '@material-ui/icons/LockOutlined'
import MailOutline from '@material-ui/icons/MailOutline'
import Menu from '@material-ui/icons/Menu'
import Mic from '@material-ui/icons/Mic'
import MicOff from '@material-ui/icons/MicOff'
import MoreVert from '@material-ui/icons/MoreVert'
import Notifications from '@material-ui/icons/Notifications'
import NotificationsActiveOutlined from '@material-ui/icons/NotificationsActiveOutlined'
import Pause from '@material-ui/icons/Pause'
import People from '@material-ui/icons/People'
import PeopleOutlined from '@material-ui/icons/PeopleOutline'
import Person from '@material-ui/icons/Person'
import PersonOutline from '@material-ui/icons/PersonOutline'
import Phone from '@material-ui/icons/Phone'
import PlayArrow from '@material-ui/icons/PlayArrow'
import Portrait from '@material-ui/icons/Portrait'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import Refresh from '@material-ui/icons/Refresh'
import ReportProblem from '@material-ui/icons/ReportProblem'
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline'
import Save from '@material-ui/icons/Save'
import Search from '@material-ui/icons/Search'
import StarOutlined from '@material-ui/icons/StarOutlined'
import Subject from '@material-ui/icons/Subject'
import Timelapse from '@material-ui/icons/Timelapse'
import Update from '@material-ui/icons/Update'
import Videocam from '@material-ui/icons/Videocam'
import VideocamOff from '@material-ui/icons/VideocamOff'
import Visibility from '@material-ui/icons/Visibility'

export const Icons = {
  Activate: CheckCircleOutline,
  AddButton: AddCircleOutline,
  AddComment: AddCommentOutlined,
  Alarm,
  ArrowBack,
  ArrowTo: KeyboardTab,
  Assignment,
  Calendar,
  Call,
  Check,
  Close,
  Company: Business,
  ConfirmButton: Done,
  Deactivate: RemoveCircleOutline,
  Delete,
  DeleteForever,
  Done: Done,
  Edit: EditIcon,
  EditButton: Create,
  EditedForm: CreateOutlined,
  Email: MailOutline,
  Error: ErrorOutline,
  Frequency: Update,
  List,
  Lock: LockOutlined,
  Logout: PowerSettingsNew,
  Menu,
  Mic,
  MicOff,
  Missed: PriorityHigh,
  More: MoreVert,
  Notes: Subject,
  Notifications,
  NotificationsActive: NotificationsActiveOutlined,
  Pause,
  Payment: CreditCard,
  People,
  PeopleOutlined,
  Person,
  PersonOutline,
  Phone,
  Play: PlayArrow,
  Portrait,
  Pose: AccessibilityNew,
  PoseOutlined: AccessibilityNewOutlined,
  Refresh,
  ReportProblem,
  Save,
  Search,
  StarOutlined,
  StatusChange: HowToReg,
  Time: AccessTime,
  Timelapse,
  TimeStart: HourglassFull,
  TimeEnd: HourglassEmpty,
  Videocam,
  VideocamOff,
  Visibility,
}
