import React, { useContext, useMemo, useReducer } from 'react'
import {
  ConnectionsState,
  ConnectionsAction,
  ConnectionsActions,
  reducer,
  initialState,
} from 'shared/data/connections'

// --- GLOBAL CONNECTIONS CONTEXT ---

type ConnectionsRedux = {
  state: ConnectionsState
  dispatch: React.Dispatch<ConnectionsAction>
}

/**
 * Current state of connections server-event connections lives here
 */
const ConnectionsContext: React.Context<ConnectionsRedux> = React.createContext({
  state: initialState,
} as ConnectionsRedux)

// --- PROVIDE CONNECTIONS ---

export const ConnectionsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <ConnectionsContext.Provider value={{ state, dispatch }}>
      {children}
    </ConnectionsContext.Provider>
  )
}

// --- USE CONNECTIONS

export const useConnections = () => {
  const { state, dispatch } = useContext(ConnectionsContext)

  // ~~~ Note ~~~
  // Actions just need a dispatcher to work, they themselves are not going to change.
  // We memoize them to make sure they will not be recalculated on every state change.
  // That would risk running into infinite loops during rendering.
  const actions: ConnectionsActions = useMemo(
    () => ({
      connectionStart: caller =>
        dispatch({
          type: 'CONNECTION_START',
          caller,
        }),
      connectionEnd: caller =>
        dispatch({
          type: 'CONNECTION_END',
          caller,
        }),
      dismissIncomingConnection: userId =>
        dispatch({
          type: 'DISMISS_INCOMING_CONNECTION',
          userId,
        }),
      acceptIncomingConnection: caller =>
        dispatch({
          type: 'ACCEPT_INCOMING_CONNECTION',
          caller,
        }),
      callUp: caller =>
        dispatch({
          type: 'CALL_UP',
          caller,
        }),
      disconnect: userId =>
        dispatch({
          type: 'DISCONNECT',
          userId,
        }),
      dismissFinished: () =>
        dispatch({
          type: 'DISMISS_FINISHED',
        }),
    }),
    [dispatch]
  )

  return {
    state,
    actions,
    dispatch,
  }
}
