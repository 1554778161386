import { Session } from 'shared/types/Session'
import { prefix } from 'shared/utils/url'
import * as Cp from './company'
import * as Pt from './patient'
import * as Ft from './physiotherapist'
import * as SA from './superadmin'

/**
 * All possible query param names, collected from each module
 */
type ParamName = keyof Cp.Params | keyof Pt.Params | keyof Ft.Params | keyof SA.Params

/**
 * All possible query params, collected from each module
 *
 * Gathering all possible params in one type makes it easier
 * to write reusable components that have to check some query param,
 * regardless in which module (under which route) it is being used
 *
 * This way a component using this **Params** type can check for
 * params like *patientId* or *companyId* in any module
 */
export type Params = Record<ParamName, string | undefined>

/**
 * All possible Route paths, collected from each module
 */
export const path = {
  ...Cp.path,
  ...Pt.path,
  ...Ft.path,
  ...SA.path,
}

/**
 * All possible links, collected from each module
 */
export const link = {
  ...Cp.link,
  ...Pt.link,
  ...Ft.link,
  ...SA.link,
}

/**
 * Select entry route based on user role
 */
export const entry = (session: Session): string => {
  switch (session.role) {
    case 'superadmin':
      return SA.link['/superadmin']()
    case 'company':
      return Cp.link['/company/:companyId']({
        companyId: session.userId,
      })
    case 'physiotherapist':
      return Ft.link['/physiotherapist/:physiotherapistId']({
        physiotherapistId: session.userId,
      })
    case 'patient':
      return Pt.link['/patient/:patientId']({
        patientId: session.userId,
      })
  }
}

/**
 * Select exit route to redirect to after logout
 */
export const exit = (): string => {
  return prefix + '/login'
}
