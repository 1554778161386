import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field, FieldProps } from 'formik'
import * as Cp from 'shared/data/company'
import { FormProps, FormFieldsProps } from 'shared/types/Form'
import { Company, CompanyInput } from 'shared/types/Company'
import { createForm } from 'shared/components/Form'
import { TextField } from 'shared/components/FormField/TextField'
import { useFormStyles } from 'shared/styles/form'
import { t } from 'shared/translations'

const CompanyFormFields: React.FC<FormFieldsProps<CompanyInput>> = ({ fieldValidator }) => {
  const classes = useFormStyles()

  return (
    <Grid container direction="column" className={classes.padding}>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12} md={6}>
          <Field
            name="name"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.name']}
                required={Cp.required.includes('name')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="phone"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.phone']}
                required={Cp.required.includes('phone')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="email"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.email']}
                required={Cp.required.includes('email')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="voivodeship"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.voivodeship']}
                required={Cp.required.includes('voivodeship')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="city"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.city']}
                required={Cp.required.includes('city')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="postalCode"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.postalCode']}
                required={Cp.required.includes('postalCode')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="street"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.street']}
                required={Cp.required.includes('street')}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={1}>
          <Field
            name="houseNumber"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.houseNumber']}
                required={Cp.required.includes('houseNumber')}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={1}>
          <Field
            name="apartmentNumber"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.apartmentNumber']}
                required={Cp.required.includes('apartmentNumber')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="accountNumber"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.accountNumber']}
                required={Cp.required.includes('accountNumber')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="bankName"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.bankName']}
                required={Cp.required.includes('bankName')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Field
            name="nip"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.nip']}
                required={Cp.required.includes('nip')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="description"
            render={(props: FieldProps) => (
              <TextField
                field={props.field}
                form={props.form}
                state={fieldValidator(props)}
                label={t['property.description']}
                required={Cp.required.includes('description')}
                multiline={true}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export const CompanyForm: React.FC<FormProps<Company, CompanyInput>> = createForm({
  validation: Cp.validation,
  FormFields: CompanyFormFields,
})
