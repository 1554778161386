import React, { useEffect, useState } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as SetByDay from 'shared/data/setOfExercisesByDay'
import { SetOfExercisesByDay } from 'shared/types/SetOfExercises'
import * as Layout from 'shared/components/Layout'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { EmptyList } from 'shared/components/EmptyList'
import { Search } from 'shared/components/Search'
import { SetsOfExercisesByDayPastTimeline } from 'shared/views/SetsOfExercisesByDayTimeline'
import { SetOfExercisesByDayResult } from 'shared/views/SetOfExercisesByDayResult'
import { today } from 'shared/utils/date'
import { t } from 'shared/translations'
import { Params } from 'modules/patient/routes'

export const PatientSetTabHistory: React.FC = () => {
  const { match } = useRouter<Params>()
  const { state, collection } = SetByDay.useCollectionReducer()
  const { query, searchBy } = SetByDay.useQueries()
  const [selected, select] = useState<SetOfExercisesByDay>()
  const { patientId } = match.params
  const { data } = state
  const nothingSelected = !selected

  useEffect(() => {
    let cancelled = false
    if (patientId) {
      collection.loading()
      Api.fetchSetsOfExercisesByDay({
        ...query,
        patients: [patientId],
        availabilityStatuses: 'archival',
        date: { to: today() },
        order: { date: 'desc' },
      })
        .then(res => {
          if (!cancelled) {
            collection.success(res.setsOfExercisesByDay, res.meta)
            // After loading results automatically select the first one.
            // This is just for nicer UX, so that user doesn't start with blank view.
            if (nothingSelected && res.setsOfExercisesByDay.length > 0) {
              select(res.setsOfExercisesByDay[0])
            }
          }
        })
        .catch(err => !cancelled && collection.failure(err))
      return () => {
        cancelled = true
      }
    }
  }, [collection, patientId, query, nothingSelected])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const noActiveSets = data.length === 0 && !query.searchTerm && !query.states
  if (noActiveSets) {
    return <EmptyList title={t['sets.empty']} hint={t['sets.empty.hint']} />
  }

  return (
    <Layout.FlexRow>
      <Layout.Master>
        <Layout.MasterHeader>
          <Search type="search" placeholder={t['placeholder.search']} value={query.searchTerm} onChange={e => searchBy(e.target.value)} />
        </Layout.MasterHeader>
        <Layout.MasterSection>
          <SetsOfExercisesByDayPastTimeline entries={data} selected={selected} select={select} />
        </Layout.MasterSection>
      </Layout.Master>
      <Layout.Details>
        {selected ? <SetOfExercisesByDayResult setOfExercisesByDay={selected} /> : null}
      </Layout.Details>
    </Layout.FlexRow>
  )
}
