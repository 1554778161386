import React from 'react'
import { SetOfExercisesByDay } from 'shared/types/SetOfExercises'
import {
  TimelineEntryIcon,
  TimelineEntryTitle,
  TimelineEntryContent,
} from 'shared/components/Timeline'
import { Icons } from 'shared/components/Icons'
import { SetOfExercisesExerciseCounter } from 'shared/views/labels/SetOfExercisesExerciseCounter'

type TimelineEntryProps = {
  entry: SetOfExercisesByDay
  isSelected: boolean
}

export const TimelineEntryFutureSet: React.FC<TimelineEntryProps> = ({ entry, isSelected }) => {
  const size = isSelected ? 'large' : 'normal'
  const variant = isSelected ? 'orange' : 'grey'

  return (
    <React.Fragment>
      <TimelineEntryIcon size={size} variant={variant} icon={<Icons.PoseOutlined />} />
      <TimelineEntryTitle>
        <div>{entry.name}</div>
        <div>{entry.date.substring(0, 10)}</div>
      </TimelineEntryTitle>
      <TimelineEntryContent>
        <SetOfExercisesExerciseCounter id={entry.id} />
      </TimelineEntryContent>
    </React.Fragment>
  )
}
