import { t } from 'shared/translations'

const VALID_HEIGHT = /^[0-9]{2,3}$/

export const validateHeight = (val: any): string | undefined => {
  let str = val
  if (typeof val === 'number') {
    str = String(val)
  }
  if (typeof str !== 'string') return t['invalid']
  if (str.length < 2) return t['too_short']
  if (str.length > 3) return t['too_long']
  if (!VALID_HEIGHT.test(str)) return t['invalid']
}
