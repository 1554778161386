import React from 'react'
import { Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as SetEx from 'shared/data/setOfExercises'
import { useToasts } from 'shared/context/toasts'
import { SetOfExercisesInput } from 'shared/types/SetOfExercises'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { SetOfExercisesForm } from 'shared/views/SetOfExercisesForm'
import { ActionOpenPreview } from 'shared/views/actions/ActionOpenPreview'
import { ActionSaveAsTemplate } from 'shared/views/actions/ActionSaveAsTemplate'
import { createAddFormFieldValidator } from 'shared/utils/validation'
import { link } from 'modules/routes'
import { Params } from 'modules/physiotherapist/routes'

export const PhysiotherapistPatientTabSetsAdd: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, entity } = SetEx.useEntityReducer()
  const { physiotherapistId, patientId } = match.params
  const backToList = link['/physiotherapist/:physiotherapistId/patients/:patientId/sets'](
    match.params
  )

  if (state.data) {
    return <Redirect to={backToList} />
  }


  const save = (input: SetOfExercisesInput) => {
    if (patientId) {
      entity.loading()
      Api.createSetOfExercises({
        ...SetEx.payloadCreate(input),
        physiotherapist: physiotherapistId,
        patient: patientId,
      })
        .then(res => {
          toasts.success(`Utworzono zestaw ćwiczeń`)
          entity.success(res.setOfExercises)
        })
        .catch(err => {
          toasts.success(`Nie udało się utworzyć zestawu ćwiczeń`)
          entity.failure(err)
        })
    }
  }

  const saveAsTemplate = (input: SetOfExercisesInput) => {
    Api.createTemplate({
      physiotherapist: physiotherapistId,
      name: input.name,
      duration: input.duration,
      custom: input.custom,
      onceDay: input.onceDay,
      everyNumberDay: input.everyNumberDay,
      templateExercisesParameters: input.exercisesParameters,
    })
      .then(() => {
        toasts.success(`Zapisano szablon`)
      })
      .catch(() => {
        toasts.error(`Nie udało się zapisać szablonu`)
      })
  }

  return (
    <ErrorBoundary>
      <SetOfExercisesForm
        createFieldValidator={createAddFormFieldValidator}
        state={state}
        save={save}
        headerActions={[
          formik => (
            <ActionOpenPreview
              values={formik.values.exercisesParameters}
              disabled={state.pending}
              renderAsButton={false}
            />
          ),
          formik => (
            <ActionSaveAsTemplate
              save={() => saveAsTemplate(formik.values)}
              disabled={state.pending}
              renderAsButton={false}
            />
          ),
        ]}
        footerActions={[
          formik => (
            <ActionOpenPreview
              values={formik.values.exercisesParameters}
              disabled={state.pending}
              renderAsButton={true}
            />
          ),
          formik => (
            <ActionSaveAsTemplate
              save={() => saveAsTemplate(formik.values)}
              disabled={state.pending}
              renderAsButton={true}
            />
          ),
        ]}
      />
    </ErrorBoundary>
  )
}
