import React from 'react'
import { FieldProps } from 'formik'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import { DatePickerProps, DatePicker } from '@material-ui/pickers'
import { FieldState, FieldVariant } from 'shared/types/Form/FieldState'
import { Icons } from 'shared/components/Icons'

interface DateFieldProps extends FieldProps, DatePickerProps {
  state: FieldState
}

export const DateField: React.FC<DateFieldProps> = ({ field, form, state, ...pickerProps }) => {
  const classes = useDateFieldStyles()
  const { pending, variant, helperText } = state
  const variantProps = dateFieldVariant(classes, variant)

  return (
    <DatePicker
      disableToolbar
      fullWidth={true}
      variant="inline"
      format="yyyy-MM-dd"
      id={`date-picker-${field.name}`}
      disabled={pending}
      helperText={helperText}
      {...field}
      {...variantProps}
      {...pickerProps}
    />
  )
}

const dateFieldVariant = (classes: Classes, fieldVariant?: FieldVariant) => {
  switch (fieldVariant) {
    case 'error':
      return {
        className: classes.dateField_error,
        InputProps: {
          endAdornment: (
            <InputAdornment className={classes.error} position="end" variant="outlined">
              <Icons.Error />
            </InputAdornment>
          ),
        },
      }
    case 'edited':
      return {
        className: classes.dateField_edited,
        InputProps: {
          endAdornment: (
            <InputAdornment className={classes.edited} position="end" variant="outlined">
              <Icons.EditedForm />
            </InputAdornment>
          ),
        },
      }
    default:
      return {
        className: classes.dateField,
      }
  }
}

type Classes = Record<
  'dateField' | 'dateField_edited' | 'dateField_error' | 'error' | 'edited',
  string
>

const useDateFieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    dateField: {
      marginBottom: theme.spacing(2),
    },
    dateField_edited: {
      marginBottom: theme.spacing(2),
      '& label': {
        color: 'orange',
      },
      '& label.Mui-focused': {
        color: 'orange',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'orange',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'orange',
      },
    },
    dateField_error: {
      marginBottom: theme.spacing(2),
      '& label': {
        color: 'red',
      },
      '& label.Mui-focused': {
        color: 'red',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'red',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'red',
      },
      '& .MuiFormHelperText-root': {
        color: 'red',
      },
    },
    error: {
      color: 'red',
    },
    edited: {
      color: 'orange',
    },
  })
)
