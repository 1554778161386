/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Availability } from 'shared/types/Availability'
import { colors } from 'shared/styles/colors'
import { t } from 'shared/translations'

type AvailabilityInfoProps = {
  availability: Availability
}

export const AvailabilityInfo: React.FC<AvailabilityInfoProps> = ({ availability }) => {
  switch (availability) {
    case 'offline': {
      return (
        <div css={cssTooltip}>
          <span css={cssTooltipTitle}>{t['info.offline.tooltip']}</span>
          <span css={cssTooltipText}>{t['info.offline']}</span>
        </div>
      )
    }
    case 'busy': {
      return (
        <div css={cssTooltip}>
          <span css={cssTooltipTitle}>{t['info.busy.tooltip']}</span>
          <span css={cssTooltipText}>{t['info.offline']}</span>
        </div>
      )
    }
    case 'online':
    case 'inactive': {
      return <div></div>
    }
  }
}

const cssTooltip = css`
  margin-top: 20px;
  margin-left: 20px;
  padding: 10px;
  background: ${colors.lightGrey};
  display: flex;
  flex-direction: column;
  width: 20%;
  position: relative;
  color: ${colors.mediumDarkGrey};
  :after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid ${colors.lightGrey};
    left: -8px;
    top: 12px;
  }
`

const cssTooltipTitle = css`
  font-size: 0.7rem;
  font-weight: bold;
  margin-bottom: 5px;
`

const cssTooltipText = css`
  font-size: 0.6rem;
`
