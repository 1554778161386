import {
  FetchMany,
  FetchOne,
  CreateOne,
  PatchOne,
  fetchMany,
  fetchOne,
  createOne,
  activateOne,
  deactivateOne,
} from 'shared/api/crud'
import { Meta } from 'shared/types/Meta'
import { Template, TemplatesQuery, TemplateParams } from 'shared/types/Template'

const resource = 'templates'

// TODO FIXME API - TemplatesPayload should have field named "templates" for consistency and general sanity.
export type TemplatesPayload = { template: Template[]; meta: Meta }
export type TemplatePayload = { template: Template }

export const fetchTemplates: FetchMany<TemplatesPayload, TemplatesQuery> = fetchMany(resource)
export const fetchTemplate: FetchOne<TemplatePayload> = fetchOne(resource)
export const createTemplate: CreateOne<TemplatePayload, TemplateParams> = createOne(resource)
export const activateTemplate: PatchOne<TemplatePayload> = activateOne(resource)
export const deactivateTemplate: PatchOne<TemplatePayload> = deactivateOne(resource)
