import React from 'react'
import { Status } from 'shared/types/Status'
import { TextVariant, Text } from 'shared/components/Text'
import { t } from 'shared/translations'

type StatusTextProps = {
  status: Status
}

const statusTextVariant: Record<Status, TextVariant> = {
  new: 'grey',
  active: 'green',
  inactive: 'orange',
}

export const StatusText: React.FC<StatusTextProps> = ({ status }) => {
  return <Text label={t[status]} variant={statusTextVariant[status]} />
}
