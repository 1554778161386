/** @jsx jsx */
import styled from '@emotion/styled'

export const H1 = styled.h1`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
`

export const H2 = styled.h2`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
`

export const H3 = styled.h3`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
`

export const H4 = styled.h4`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
`
