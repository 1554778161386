import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { SuperAdminPatients } from './Patients'
import { SuperAdminPatientAdd } from './PatientAdd'
import { SuperAdminPatientEdit } from './PatientEdit'
import { path } from 'modules/routes'

export const SuperAdminPatientsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={path['/superadmin/patients']} component={SuperAdminPatients} />
      <Route exact path={path['/superadmin/patients/add']} component={SuperAdminPatientAdd} />
      <Route
        exact
        path={path['/superadmin/patients/:patientId']}
        component={SuperAdminPatientEdit}
      />
    </Switch>
  )
}
