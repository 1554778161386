import { FetchOne, fetchOne } from 'shared/api/crud'
import { File } from 'shared/types/File'
import { UUID } from 'shared/types/UUID'
import { apiUrl } from '../config'
import { request } from '../utils'

const resource = 'files'

export type FilePayload = { file: File }

export const fetchFile: FetchOne<FilePayload> = fetchOne(resource)

export const checkFile = (fileId: UUID, token: string): Promise<any> => {
  return request.head(`${apiUrl}/files/${fileId}.mp4?X-Token=${token}`)
}
