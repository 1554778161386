import { FetchMany, FetchOne, fetchMany, fetchOne } from 'shared/api/crud'
import { Meta } from 'shared/types/Meta'
import { Settlement, SettlementsQuery } from 'shared/types/Settlement'

const resource = 'settlements'

export type SettlementsPayload = {
  settlements: Settlement[]
  meta: Meta
}
export type SettlementPayload = { settlement: Settlement }

export const fetchSettlements: FetchMany<SettlementsPayload, SettlementsQuery> = fetchMany(resource)
export const fetchSettlement: FetchOne<SettlementPayload> = fetchOne(resource)
