/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { Caller } from 'shared/types/Caller'

type ConnectionLoaderProps = {
  participant: Caller
  size?: Size
}

type Size = 'normal' | 'large'

export const ConnectionLoader: React.FC<ConnectionLoaderProps> = ({
  participant,
  size = 'normal',
}) => {
  return (
    <div css={cssCircleBox(size)}>
      <div css={cssCircleInner(size)}>
        {participant.firstName.charAt(0)}
        {participant.lastName.charAt(0)}
      </div>
      <div css={cssPulse1(size)}></div>
      <div css={cssPulse2(size)}></div>
      <div css={cssPulse3(size)}></div>
      <div css={cssPulse4(size)}></div>
    </div>
  )
}

const cssCircleBox = (size: Size) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${size === 'large' ? 240 : 160}px;
  height: ${size === 'large' ? 240 : 160}px;
  margin: 0;
  transform: translate(0, 0);
  border-radius: 100%;
`
const cssCircleInner = (size: Size) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: ${size === 'large' ? 120 : 80}px;
  height: ${size === 'large' ? 120 : 80}px;
  transform: translate(0, 0);
  background: steelblue;
  border-radius: 100%;
  font-size: ${size === 'large' ? 48 : 32}px;
  font-weight: bold;
  color: #e9e9e9;
`
const cssPulse = (size: Size) => `
  position: absolute;
  left: 0;
  top: 0;
  width: ${size === 'large' ? 240 : 160}px;
  height: ${size === 'large' ? 240 : 160}px;
  background: lightsteelblue;
  border: 1px solid rgb(156, 183, 216);
  border-radius: 100%;
  z-index: -1;
  text-align: center;
  transform: scale(0.1, 0.1);
`
const cssPulse1 = (size: Size) => css`
  ${cssPulse(size)}
  animation: rings 2s 0.5s ease-out infinite;
`
const cssPulse2 = (size: Size) => css`
  ${cssPulse(size)}
  animation: rings 2s 1s ease-out infinite;
`
const cssPulse3 = (size: Size) => css`
  ${cssPulse(size)}
  animation: rings 2s 1.5s ease-out infinite;
`
const cssPulse4 = (size: Size) => css`
  ${cssPulse(size)}
  animation: rings 2s 2s ease-out infinite;
`
