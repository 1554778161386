import {
  FetchMany,
  FetchOne,
  CreateOne,
  PatchOne,
  fetchMany,
  fetchOne,
  createOne,
  patchOne,
} from 'shared/api/crud'
import { Meta } from 'shared/types/Meta'
import { Payment, PaymentInput, PaymentsQuery } from 'shared/types/Payment'

const resource = 'payments'

export type PaymentsPayload = { payments: Payment[]; meta: Meta }
export type PaymentPayload = { payment: Payment }

export const fetchPayments: FetchMany<PaymentsPayload, PaymentsQuery> = fetchMany(resource)
export const fetchPayment: FetchOne<PaymentPayload> = fetchOne(resource)
export const createPayment: CreateOne<PaymentPayload, PaymentInput> = createOne(resource)
export const changeStatePayment: PatchOne<PaymentPayload> = patchOne(
  paymentId => `${resource}/${paymentId}/changeState` // TODO FIXME missing parameter
)
