import { Dispatch } from 'react'
import { ServerError } from 'shared/types/Error'

/**
 * Generic type **RemoteEntity** represents a server/database object
 * The point is to capture all possible states, taking into account
 * communication with the server. That includes following states:
 * - **initial (idle)** - before anything is fetched
 * - **loading** - no data yet, but request has been sent
 * - **success** - loaded data from server
 * - **failure** - server responded with some error
 * - **reloading** - we have some data already, but have to send a request to refresh it
 *
 * @example
 * RemoteEntity<Company> // company object loaded from the server
 */
export type RemoteEntity<D extends object = any> = {
  pending: boolean
  error?: ServerError<D>
  data?: D
}

// ACTIONS

/**
 * **RemoteEntityAction** represents the following possible things that may happen:
 * - **loading** - we just sent a request. this can be a GET, POST, PUT or PATCH request,
 *   but the important thing here is that it relates to *ONE specific object* in the db
 * - **failure** - server responded with some error. there may be validation errors
 *   specific to this object, that's why `ServerError<D>` is parametrized here
 * - **success** - server responded with payload containing type `D`
 */
export type RemoteEntityAction<D extends object = any> =
  | {
      resolved: false
    }
  | {
      resolved: 'FAILURE'
      error: ServerError<D>
    }
  | {
      resolved: 'SUCCESS'
      data: D
    }

export type Loading = () => {
  resolved: false
}

export type Failure<D extends object = any> = (
  error: ServerError<D>
) => {
  resolved: 'FAILURE'
  error: ServerError<D>
}

export type Success<D extends object = any> = (
  data: D
) => {
  resolved: 'SUCCESS'
  data: D
}

export const loading: Loading = () => ({ resolved: false })
export const failure: Failure = error => ({ resolved: 'FAILURE', error })
export const success: Success = data => ({ resolved: 'SUCCESS', data })

// DISPATCHERS

export type RemoteEntityActions<D extends object = any> = {
  loading: () => void
  failure: (error: ServerError<D>) => void
  success: (data: D) => void
}

type CreateRemoteEntityActions<D extends object = any> = (
  dispatch: Dispatch<RemoteEntityAction<D>>
) => RemoteEntityActions<D>

export const createEntityActions: CreateRemoteEntityActions = dispatch => {
  return {
    loading: () => dispatch(loading()),
    failure: error => dispatch(failure(error)),
    success: data => dispatch(success(data)),
  }
}

// REDUCER

/**
 * **RemoteEntityReducer**
 */
export type RemoteEntityReducer<D extends object = any> = (
  state: RemoteEntity<D>,
  action: RemoteEntityAction<D>
) => RemoteEntity<D>

export const remoteEntityReducer: RemoteEntityReducer = (state, action) => {
  switch (action.resolved) {
    case false:
      return {
        pending: true,
        ...(state.data && { data: state.data }),
      }
    case 'FAILURE':
      return {
        pending: false,
        error: action.error,
        ...(state.data && { data: state.data }),
      }
    case 'SUCCESS':
      return {
        pending: false,
        data: action.data,
      }
    default:
      return state
  }
}

export const idle: RemoteEntity = { pending: false }
