/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { ExerciseParameters } from 'shared/types/ExerciseParameters'
import { ExerciseStatistic } from 'shared/types/ExerciseStatistic'
import { Icons } from 'shared/components/Icons'
import { AdditionalInfo } from 'shared/components/AdditionalInfo'
import { ExerciseLabel } from 'shared/views/labels/ExerciseLabel'
import { ExerciseStatisticIcon } from 'shared/views/icons/ExerciseStatisticIcon'
import { secondsToMinutes } from 'shared/utils/time'
import { t } from 'shared/translations'

type ExerciseStatisticsProps = {
  exercisesParameters: ExerciseParameters
  exerciseStatistic?: ExerciseStatistic
}

export const ExerciseStatisticsView: React.FC<ExerciseStatisticsProps> = ({
  exercisesParameters,
  exerciseStatistic,
}) => {
  if (!exerciseStatistic) {
    return null
  }

  return (
    <div css={cssExerciseStatisticsView}>
      <div>
        <div css={cssExercise}>
          <div css={cssIcon}>
            <ExerciseStatisticIcon statistic={exerciseStatistic} />
          </div>
          <ExerciseLabel id={exercisesParameters.exercise} />
          {exercisesParameters.estimatedDuration && (
            <AdditionalInfo
              icon={Icons.Pose}
              text={t['exercise.duration'](secondsToMinutes(exercisesParameters.estimatedDuration))}
            />
          )}
          {exercisesParameters.repetitionsPerSeries && (
            <AdditionalInfo
              icon={Icons.Refresh}
              text={t['exercise.repetitionsDone'](
                exerciseStatistic.repetitionsPerSeriesCompleted,
                exercisesParameters.repetitionsPerSeries
              )}
            />
          )}
        </div>
        <div css={cssPause}>
          <div css={cssIcon}>
            <Icons.ConfirmButton />
          </div>
          <div css={cssName}>{t['exercise.pause']}</div>
          <AdditionalInfo
            icon={Icons.Pause}
            text={t['exercise.pauseAfterComplete'](
              secondsToMinutes(exercisesParameters.restDurationUponComplete)
            )}
          />
        </div>
      </div>
    </div>
  )
}

const cssColor = (color: string) => css`
  color: ${color};
`
const cssExerciseStatisticsView = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-left: 11px;
  padding-right: 16px;
  & > div:nth-of-type(1) {
    display: flex;
    flex: 2;
    flex-direction: column;
    border-left: 2px dashed #ddd;
    padding-left: 24px;
  }
  & > div:nth-of-type(2) {
    display: flex;
    flex: 1;
    font-size: 0.75rem;
    padding: 4px 8px;
  }
  & > div:nth-of-type(3) {
    display: flex;
    flex: 1;
  }
`
const cssExercise = css`
  position: relative;
`
const cssPause = css`
  position: relative;
  margin: 16px 0;
  color: #999;
`
const cssIcon = css`
  position: absolute;
  width: 24px;
  height: 24px;
  left: -37px;
  top: 0;
  border-radius: 40px;
  border: none;
  background: #ddd;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  & > svg {
    font-size: 1rem;
  }
`
const cssName = css`
  margin-bottom: 8px;
`
