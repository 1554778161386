/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useState } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import Paper from '@material-ui/core/Paper'
import { Status } from 'shared/types/Status'
import {
  IconButton,
  FooterButton,
  ModalEscapeButton,
  ModalConfirmButton,
} from 'shared/components/Button'
import { Icons } from 'shared/components/Icons'
import { TeleportToModals } from 'shared/views/portals/PortalWithModals'
import { t } from 'shared/translations'

type ActionChangeStatusProps = {
  status: Status
  activate?: () => void
  deactivate?: () => void
  disabled?: boolean
  renderAsButton?: boolean
}

export const ActionChangeStatus: React.FC<ActionChangeStatusProps> = ({
  activate,
  deactivate,
  status,
  disabled,
  renderAsButton,
}) => {
  const [open, setOpen] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(status)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  const submit = () => {
    if (selectedStatus !== status) {
      switch (selectedStatus) {
        case 'active': {
          activate && activate()
          break
        }
        case 'inactive': {
          deactivate && deactivate()
          break
        }
        default: {
        }
      }
    }
    closeModal()
  }

  return (
    <React.Fragment>
      {renderAsButton ? (
        <FooterButton disabled={disabled} onClick={openModal} title={t['action.changeStatus']}>
          <Icons.StatusChange />
          {t['action.changeStatus']}
        </FooterButton>
      ) : (
        <IconButton disabled={disabled} onClick={openModal} title={t['action.changeStatus']}>
          <Icons.StatusChange />
        </IconButton>
      )}

      {open && (
        <TeleportToModals>
          <Modal
            open={open}
            onClose={closeModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            css={css`
              margin-top: 20%;
            `}
          >
            <Paper css={cssPaper}>
              <div css={cssTopStatusModalButtons}>
                <span>{t['action.changeStatus']}</span>
                <IconButton
                  disabled={disabled}
                  css={cssCloseButton}
                  onClick={closeModal}
                  title={t['action.close']}
                >
                  <Icons.Close />
                </IconButton>
              </div>
              <div css={cssSelectSection}>
                <p>{t['property.state']} : </p>
                <select
                  css={cssStatusSelect}
                  onChange={e => setSelectedStatus(e.target.value as Status)}
                >
                  <option value="" />
                  <option value="active">{t['active']}</option>
                  <option value="inactive">{t['inactive']}</option>
                </select>
              </div>
              <div css={cssStatusButtons}>
                <ModalEscapeButton onClick={closeModal}>Zamknij</ModalEscapeButton>
                <ModalConfirmButton onClick={submit}>Zapisz</ModalConfirmButton>
              </div>
            </Paper>
          </Modal>
        </TeleportToModals>
      )}
    </React.Fragment>
  )
}

const cssPaper = css`
  width: 30%;
  height: 30%;
  min-width: 140px;
  min-height: 80px;
  background: #ddd;
  margin: 10px;
  margin: 0 auto;
  text-align: center;
`
const cssCloseButton = css`
  position: sticky;
  right: 0;
`
const cssTopStatusModalButtons = css`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const cssStatusSelect = css`
  background: transparent;
  margin-left: 10px;
  height: 40px;
`
const cssSelectSection = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`
const cssStatusButtons = css`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`
