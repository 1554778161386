import React from 'react'
import { H4 } from 'shared/components/Heading'

type ConnectionProblemProps = {
  message: string
}

export const ConnectionProblem: React.FC<ConnectionProblemProps> = ({ message }) => {
  return (
    <div>
      {/** TODO add some nice icon for errors? */}
      <H4>{message}</H4>
    </div>
  )
}
