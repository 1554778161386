import React from 'react'
import ReactDOM from 'react-dom'
import { usePortal } from 'shared/hooks/usePortal'
import { PortalId } from 'shared/types/PortalId'

type PortalProps = {
  id: PortalId
}

export const Portal: React.FC<React.PropsWithChildren<PortalProps>> = ({ id, children }) => {
  const target = usePortal(id)
  return ReactDOM.createPortal(children, target)
}
