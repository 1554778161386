import { Token, isToken } from 'shared/types/Token';

export const SESSION_TOKEN = 'rehabee-session-token';

export const loadSessionToken = (): Token | undefined => {
  return sessionStorage.getItem(SESSION_TOKEN) || undefined;
};

export const saveSessionToken = (sessionToken: string) => {
  if (isToken(sessionToken)) {
    sessionStorage.setItem(SESSION_TOKEN, sessionToken);
  }
};

export const clearSessionToken = (sessionToken?: Token) => {
  if (
    typeof sessionToken === 'undefined' ||
    sessionToken === sessionStorage.getItem(SESSION_TOKEN)
  ) {
    sessionStorage.removeItem(SESSION_TOKEN);
  }
};
