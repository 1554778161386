/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import Slide from '@material-ui/core/Slide'
import { useLayout } from 'shared/context/layout'
import { NotificationsList } from 'shared/views/NotificationsList'

export const AppPanel: React.FC = () => {
  const { state } = useLayout()

  return state.hasOpenPanel ? (
    <Slide direction="left" timeout={240} in={state.hasOpenPanel}>
      <aside css={cssAside}>
        <NotificationsList />
      </aside>
    </Slide>
  ) : null
}

const cssAside = css`
  position: fixed;
  z-index: 20;
  top: 60px;
  right: 0;
  width: 430px;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ccc;
  background: white;
`
