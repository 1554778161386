import React, { useEffect } from 'react'
import * as Api from 'shared/api'
import * as S from 'shared/data/session'
import { ConnectionsProvider } from 'shared/context/connections'
import { NotificationsProvider } from 'shared/context/notifications'
import { ToastsProvider } from 'shared/context/toasts'
import { useSession } from 'shared/context/session'
import { explain } from 'shared/utils/error'
import { Authenticate } from 'Auth/Authenticate'
import { AppLayout } from './AppLayout'
import { AppConnections } from './AppConnections'

export const App: React.FC = () => {
  const { state, actions } = useSession()
  const token = S.selectToken(state)

  // Any time we first see a session token, validate it to make sure it's not stale.
  // (this should happen on page refresh)
  useEffect(() => {
    if (token) {
      actions.verifyToken(token)
      Api.verifyToken(token)
        .then(session => actions.success(session))
        .catch(err => actions.failure(explain(err)))
    }
  }, [actions, token])

  // Make sure contexts with user-specific data only exist while user is authenticated.
  // We don't want things like notifications to outlive user session.
  return (
    <Authenticate>
      <ConnectionsProvider>
        <NotificationsProvider>
          <ToastsProvider>
            <AppLayout />
            <AppConnections />
          </ToastsProvider>
        </NotificationsProvider>
      </ConnectionsProvider>
    </Authenticate>
  )
}
