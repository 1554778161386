import React, { useState } from 'react'
import { Template } from 'shared/types/Template'
import { ButtonDefault } from 'shared/components/Button'
import { ModalSelectTemplate } from 'shared/views/modals/ModalSelectTemplate'
import { TeleportToModals } from 'shared/views/portals/PortalWithModals'

type ActionSelectTemplateProps = {
  onSelect: (template: Template) => void
}

export const ActionSelectTemplate: React.FC<ActionSelectTemplateProps> = ({ onSelect }) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)
  const select = (template: Template) => {
    onSelect(template)
    closeModal()
  }

  return (
    <React.Fragment>
      <ButtonDefault type="button" onClick={openModal}>
        Wczytaj z szablonu{/** TODO translate */}
      </ButtonDefault>

      {open && (
        <TeleportToModals>
          <ModalSelectTemplate cancel={closeModal} select={select} />
        </TeleportToModals>
      )}
    </React.Fragment>
  )
}
