/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Icons } from 'shared/components/Icons'
import { IconButton } from 'shared/components/Button'

type ActionToggleMicrophoneProps = {
  on: boolean
  toggle: () => void
}

export const ActionToggleMicrophone: React.FC<ActionToggleMicrophoneProps> = ({ on, toggle }) => {
  return (
    <IconButton onClick={toggle} title={on ? 'Wyłącz mikrofon' : 'Włącz mikrofon'} css={cssGrey}>
      {on ? <Icons.Mic /> : <Icons.MicOff />}
    </IconButton>
  )
}

const cssGrey = css`
  background-color: #666 !important;
  color: white !important;
`
