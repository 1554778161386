import { Caller, isCaller } from 'shared/types/Caller'

export type ConnectionEvent = {
  status: 'connection_start' | 'connection_end'
  caller: Caller
}

export const isConnectionEvent = (v: any): v is ConnectionEvent => {
  return (
    Boolean(v) &&
    typeof v === 'object' &&
    ['connection_start', 'connection_end'].includes(v.status) &&
    isCaller(v.caller)
  )
}
