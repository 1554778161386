/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { TextField } from '@material-ui/core'
import { Redirect, Link } from 'react-router-dom'
import useRouter from 'use-react-router'
import logo from 'shared/assets/rehabee_logo_small.svg'
import * as Api from 'shared/api'
import * as Storage from 'shared/storage'
import { useSession } from 'shared/context/session'
import { Loading } from 'shared/components/Loading'
import { ButtonPrimary } from 'shared/components/Button'
import { explain } from 'shared/utils/error'
import { colors } from 'shared/styles/colors'
import { t } from 'shared/translations'
import { entry } from 'modules/routes'

export const Login: React.FC = () => {
  const { location } = useRouter()
  const { state, actions } = useSession()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { pending, error, session } = state

  if (session) {
    const entryPath = entry(session)
    if (!location.pathname.startsWith(entryPath)) {
      return <Redirect to={entryPath} />
    }
  }

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    actions.login()
    Api.login({ email, password })
      .then(session => {
        Storage.saveSessionToken(session.token)
        actions.success(session)
      })
      .catch(err => actions.failure(explain(err)))
  }

  const disabled = !email || !password || Boolean(pending)

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding: 30px;
      `}
    >
      <Img src={logo} />
      <form
        onSubmit={submit}
        css={css`
          width: 300px;
          & > input {
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            text-align: center;
          `}
        >
          {error}
        </div>
        <TextField
          type="text"
          label={t['property.login']}
          autoFocus
          value={email}
          disabled={Boolean(pending)}
          onChange={e => setEmail(e.target.value)}
          css={css`
            color: ${colors.mainOrange};
            width: 100%;
            margin-bottom: 10px;
          `}
        />
        {/* <div>{errors.email}</div> */}
        <TextField
          type="password"
          label={t['property.password']}
          value={password}
          disabled={Boolean(pending)}
          onChange={e => setPassword(e.target.value)}
          css={css`
            color: ${colors.mainOrange};
            width: 100%;
          `}
        />

        {/* TODO translate*/}
        {/* <div>{errors.password}</div> */}
        {pending ? (
          <Loading />
        ) : (
          <ButtonPrimary type="submit" disabled={disabled} css={cssLoginButton}>
            {t['action.login']}
          </ButtonPrimary>
        )}
        <div css={cssResetPassBox}>
          <ResetPassLink to={'/resetPassword'}>{t['action.resetPassword']}</ResetPassLink>
        </div>
      </form>
    </div>
  )
}

const Img = styled.img`
  padding-bottom: 50px;
`
const cssResetPassBox = css`
  margin-top: 20px;
  text-align: center;
`
const ResetPassLink = styled(Link)`
  && {
    text-decoration: underline;
    color: ${colors.mediumGrey};
  }
`

const cssLoginButton = css`
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`
