import { UUID } from './UUID'

export type Caller = {
  id: UUID
  firstName: string
  lastName: string
}

export const isCaller = (v: any): v is Caller => {
  return (
    Boolean(v) &&
    typeof v === 'object' &&
    typeof v.id === 'string' &&
    typeof v.firstName === 'string' &&
    typeof v.lastName === 'string'
  )
}
