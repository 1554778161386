import styled from '@emotion/styled'
import { colors } from 'shared/styles/colors'

export const Search = styled.input`
  width: 100%;
  font-size: 16px;
  height: 36px;
  padding: 0 8px;
  border: 1px solid #eee;
  border-radius: 0px;
  background: ${colors.searchBarGrey};
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-size: 14px;
    color: ${colors.mediumDarkGrey};
  }
 
`
