import React from 'react'
import { Formik } from 'formik'
import Grid from '@material-ui/core/Grid'
import { FormConfig, FormProps } from 'shared/types/Form'
import { translateErrors } from 'shared/utils/error'
import { useFormStyles } from 'shared/styles/form'
import { TeleportToHeaderActions } from 'shared/views/portals/PortalWithHeaderActions'
import { TeleportToFooterActions } from 'shared/views/portals/PortalWithFooterActions'
import { ActionSave } from 'shared/views/actions/ActionSave'

type CreateForm<D extends object = any, Input extends object = any> = (
  config: FormConfig<D, Input>
) => React.FC<FormProps<D, Input>>

export const createForm: CreateForm = config => props => {
  const classes = useFormStyles()
  const { validation, FormFields } = config

  const serverValidationErrors = props.state.error && translateErrors(props.state.error)
  const initialValues = validation.getInitialValues(props.state.data)
  const validateForm = validation.validate

  const fieldValidator = props.createFieldValidator({
    data: props.state.data,
    errors: serverValidationErrors,
    pending: props.state.pending,
  })

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      validateOnChange={false}
      validateOnBlur={true}
      enableReinitialize={true}
      onSubmit={(values, formikActions) => {
        props.save(values)
        formikActions.setErrors({})
        formikActions.setTouched({})
      }}
    >
      {formik => (
        <Grid container className={classes.root}>
          <TeleportToHeaderActions>
            <ActionSave
              save={formik.submitForm}
              disabled={props.state.pending}
              renderAsButton={false}
            />
            {props.headerActions.map(action => action(formik))}
          </TeleportToHeaderActions>

          <form id="form" method="get" autoComplete="off">
            <FormFields formik={formik} fieldValidator={fieldValidator} />
          </form>

          <TeleportToFooterActions>
            <ActionSave
              save={formik.submitForm}
              disabled={props.state.pending}
              renderAsButton={true}
            />
            {props.footerActions.map(action => action(formik))}
          </TeleportToFooterActions>
        </Grid>
      )}
    </Formik>
  )
}
