import { useState } from 'react'
import { Template, TemplatesQuery } from 'shared/types/Template'
import { Order } from 'shared/types/Order'
import { Queries, createQueries } from 'shared/utils/query'

export const defaultOrder: Order<Template> = { name: 'asc' }

const initialQuery: TemplatesQuery = {
  order: defaultOrder,
  offset: 0,
  limit: 16,
}

export const useQueries = () => {
  const [query, updateQuery] = useState(initialQuery)
  const queries: Queries<Template> = createQueries({ query, updateQuery })

  return { query, ...queries }
}
