/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import useRouter from 'use-react-router'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import * as Api from 'shared/api'
import * as S from 'shared/data/session'
import * as Storage from 'shared/storage'
import { useSession } from 'shared/context/session'
import { useLayout } from 'shared/context/layout'
import { useNotifications } from 'shared/context/notifications'
import { Notification } from 'shared/types/Notification'
import { Icons } from 'shared/components/Icons'
import { IconButton } from 'shared/components/Button'
import { colors } from 'shared/styles/colors'
import { t } from 'shared/translations'
import { AppBreadcrumbs } from './AppBreadcrumbs'
import { AppLogo } from './AppLogo'
import { entry, exit } from 'modules/routes'

export const AppHeader: React.FC = () => {
  const { history } = useRouter()
  const session = useSession()
  const layout = useLayout()
  const { state, collection } = useNotifications()
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const me = S.selectUserId(session.state)

  useEffect(() => {
    let cancelled = false
    collection.loading()
    Api.fetchNotifications({ users: me})
      .then(res => !cancelled && collection.success(res.notifications, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = true
    }
  }, [collection])

  const toggleSidebar = () => {
    layout.actions.toggleSidebar()
  }

  const toggleNotifications = () => {
    layout.actions.togglePanel()
  }

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const onMenuClose = () => {
    setMenuAnchor(null)
  }

  const logout = () => {
    onMenuClose()
    const token = Storage.loadSessionToken()
    Storage.clear()
    if (token) {
      session.actions.logout()
      Api.logout(token)
    }
    history.push(exit())
  }

  const navigateToProfile = () => {
    onMenuClose()
    if (session.state.session) {
      history.push(entry(session.state.session) + '/profile')
    }
  }

  return (
    <Header>
      <Flex>
        <IconButton onClick={toggleSidebar}>
          <Icons.Menu />
        </IconButton>
        <AppLogo />
        <AppBreadcrumbs />
      </Flex>
      <Actions>
        <Tooltip title={t['title.notifications']}>
          <IconButton
            onClick={toggleNotifications}
            aria-label={t['title.notifications']}
            style={{ position: 'relative' }}
          >
            <Icons.NotificationsActive />
            {state.data && <NotificationsBubble notifications={state.data} />}
          </IconButton>
        </Tooltip>
        <IconButton onClick={onMenuClick} aria-controls="AppHeaderMenu" aria-haspopup="true">
          <HeaderNameLetters>
            {session.state.session? session.state.session.role[0].toUpperCase() : "..." }
          </HeaderNameLetters>
        </IconButton>
        <Menu
          id="AppHeaderMenu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={onMenuClose}
        >
          <MenuItem onClick={navigateToProfile}>Profil</MenuItem>
          <MenuItem onClick={logout}>Wyloguj</MenuItem>
        </Menu>
      </Actions>
    </Header>
  )
}

const NotificationsBubble: React.FC<{ notifications: Notification[] }> = ({ notifications }) => {
  const unread = notifications.filter(n => n.state === 'new').length
  return unread > 0 ? <Bubble>{unread}</Bubble> : null
}

const Header = styled.header`
  position: relative;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-bottom: 1px solid ${colors.lightGrey};
  background: white;
`

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
`

const Actions = styled.div`
  padding: 0 8px;
`

const Bubble = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  background: ${colors.mainOrange};
  min-width: 16px;
  height: 16px;
  border-radius: 16px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`

const HeaderNameLetters = styled.div`
  color: ${colors.mediumGrey};
`