import React from 'react'
import { Status } from 'shared/types/Status'
import { TagVariant, Tag } from 'shared/components/Tag'
import { t } from 'shared/translations'

type StatusTagProps = {
  status: Status
}

const statusTagVariant: Record<Status, TagVariant> = {
  new: 'grey',
  active: 'green',
  inactive: 'orange',
}

export const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
  return <Tag label={t[status]} variant={statusTagVariant[status]} />
}
