/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import * as Api from 'shared/api'
import { apiUrl } from 'shared/api/config'
import { useSession } from 'shared/context/session'
import { UUID } from 'shared/types/UUID'
import * as S from 'shared/data/session'
import * as Layout from 'shared/components/Layout'
import { Modal, ModalTitle } from 'shared/components/Modal'
import { IconButton } from 'shared/components/Button'
import { Icons } from 'shared/components/Icons'
import { VideoPlayer } from 'shared/components/VideoPlayer'
import { TeleportToModals } from 'shared/views/portals/PortalWithModals'
import { colors } from 'shared/styles/colors'
import { t } from 'shared/translations'
import { Token } from 'shared/types/Token'
import { ExerciseParameters } from '../../types/ExerciseParameters'

type ActionPlayVideoProps = {
  fileId: UUID
  description?: string
  renderer?: React.FC<ActionPlayVideoRendererProps>

}

export const ActionPlayVideo: React.FC<ActionPlayVideoProps> = ({
  fileId,
  description,
  renderer = ActionPlayVideoIcon,

}) => {
  const session = useSession()
  const [open, setOpen] = useState(false)
  const token = S.selectToken(session.state)
  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <React.Fragment>
      {renderer({
        token,
        fileId,
        disabled: !token,
        play: openModal,
      })}

      {open && (
        <TeleportToModals>
          <Modal open={open} onClose={closeModal}>
            <ModalTitle>{t['title.videoPreview']}</ModalTitle>
            <Layout.FlexColumnScrollable css={cssLimitHeight}>
              <VideoPlayer src={`${apiUrl}/files/${fileId}.mp4?X-Token=${token}`} />
              <div css={cssDescriptionWrapper}>
                <div css={cssDescription}>{description}</div>
              </div>
            </Layout.FlexColumnScrollable>
          </Modal>
        </TeleportToModals>
      )}
    </React.Fragment>
  )
}

// --- ACTION RENDERERS ---

// Below we just play with action's visual presentation.
// Behavior of all the components should be identical.

type ActionPlayVideoRendererProps = {
  token?: Token
  fileId: UUID
  play: () => void
  disabled?: boolean
}

export const ActionPlayVideoIcon: React.FC<ActionPlayVideoRendererProps> = ({ play, disabled }) => {
  return (
    <IconButton disabled={disabled} onClick={play} title={t['action.play']}>
      <Icons.Play />
    </IconButton>
  )
}

export const ActionPlayVideoPreview: React.FC<ActionPlayVideoRendererProps> = ({
  token,
  fileId,
  play,
  disabled,
}) => {
  // If the image fails to load (thumbnail for a video may be missing), just hide the broken image and use fallback
  const [hasVideo, setHasVideo] = useState<boolean>()
  const [hasImage, setHasImage] = useState<boolean>(true)

  useEffect(() => {
    let cancelled = false
    if (fileId && token) {
      Api.checkFile(fileId, token)
        .then(() => !cancelled && setHasVideo(true))
        .catch(() => !cancelled && setHasVideo(false))
    }
    return () => {
      cancelled = true
    }
  }, [fileId, token])

  const onImageLoadError = (evt: any) => {
    const element: HTMLImageElement | undefined = evt.target
    if (element && element.tagName === 'IMG') {
      element.remove()
      setHasImage(false)
    }
  }

  return (
    <div css={cssVideoPreview}>
      {hasVideo === true ? (
        <button type="button" disabled={disabled} onClick={play} css={cssVideoPreviewButton}>
          <div css={cssVideoButton}>
            <div css={cssImageWrapper}>
              <img
                src={`${apiUrl}/images/${fileId}.png?X-Token=${token}`}
                onError={onImageLoadError}
                alt="Exercise model"
                css={cssButtonImage}
              />
              <div css={cssDarkOverlay}></div>
            </div>
            {hasImage ? (
              <div css={cssPlaySymbol}>
                <Icons.Play />
              </div>
            ) : (
              <div css={cssNoImgSymbol}>
                <Icons.Pose />
              </div>
            )}
          </div>
        </button>
      ) : hasVideo === false ? (
        <div css={cssNoVideo}>
          <Icons.VideocamOff />
        </div>
      ) : null /** waiting for response from server... */}
    </div>
  )
}

const cssDarkOverlay = css`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
const cssImageWrapper = css`
  position: relative;
`
const cssDescriptionWrapper = css`
  width: 800px;
  background-color: ${colors.lightestGrey};
`
const cssDescription = css`
  padding: 15px 20px;
  white-space: pre-line;
`
const cssVideoPreview = css`
  width: 100%;
  //height: 80px;
  min-width: 140px;
  //min-height: 80px;
  background: #ddd;
  margin: 10px;
  display: table-cell;
`
const cssVideoPreviewButton = css`
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  background-color: ${colors.lightestGrey};
  border: none;
`
const cssVideoButton = css`
  text-align: center;
  display: flex;
  justify-content: center;
  background-color: ${colors.lightestGrey};
  position: relative;
`
const cssButtonImage = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`
const cssPlaySymbol = css`
  position: absolute;
  background-color: ${colors.lightestGrey};
  border-radius: 8px;
  width: 30px;
  height: 24px;
  left: 40%;
  top: 35%;
  z-index: 3;
`
const cssNoVideo = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: ${colors.lightestGrey};
  svg {
    font-size: 40px;
    opacity: 0.8;
    width: 100%;
  }
`
const cssNoImgSymbol = css`
  svg {
    font-size: 40px;
    width: 100%;
    min-width: 140px;
    opacity: 0.8;
  }
`
const cssLimitHeight = css`
  height: calc(100% - 50px);
  * {
    margin: 0 auto;
  }
`
