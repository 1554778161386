/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/core'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import { FieldProps } from 'formik'
import { FieldState } from 'shared/types/Form/FieldState'
import { Icons } from 'shared/components/Icons'
import { isEnter } from 'shared/utils/event'

export interface MultiValueBaseProps<Input extends object = any> extends FieldProps<Input> {
  state: FieldState
  label: string
  placeholder: string
  placeholderEdit?: string
}

export interface MultiValueListProps<D extends object = any, Input extends object = any> {
  icon: React.ReactNode
  // Values loaded from server will have ids (D).
  // Values created locally will not have ids yet (Input).
  values?: (D | Input)[]
  onChange: (vs: (D | Input)[]) => void
  toText: (v: D | Input) => string
  create: (s: string) => Input
  edit: (v: D | Input, s: string) => D | Input
  multiline?: boolean
}

export interface MultiValueFieldProps<D extends object = any, Input extends object = any>
  extends MultiValueBaseProps<Input>,
    MultiValueListProps<D, Input> {}

export const MultiValueField: React.FC<MultiValueFieldProps> = ({
  state,
  label,
  placeholder,
  placeholderEdit,
  icon,
  values,
  onChange,
  toText,
  create,
  edit,
  multiline,
}) => {
  const [nextName, setNextName] = useState('')
  const { pending, helperText } = state

  const addValue = () => {
    if (nextName.length > 0) {
      onChange([...(values || []), create(nextName)])
      setNextName('')
    }
  }

  const addValueOnEnter = (evt: React.KeyboardEvent) => {
    if (!multiline && isEnter(evt)) {
      addValue()
    }
  }

  const editValue = (index: number, text: string) => {
    if (values && values[index]) {
      onChange(values.map((entry, i) => (i === index ? edit(entry, text) : entry)))
    }
  }

  const removeValue = (index: number) => {
    if (values && values[index]) {
      onChange(values.filter((_, i) => i !== index))
    }
  }

  return (
    <React.Fragment>
      <InputLabel css={cssLabel}>{label}</InputLabel>
      {values &&
        values.map((value, i) => (
          <div key={i} css={cssValue}>
            <TextField
              type="text"
              fullWidth
              placeholder={placeholderEdit}
              multiline={multiline}
              disabled={pending}
              value={toText(value)}
              onChange={e => editValue(i, e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    css={cssIcon({ clickable: false, draft: false })}
                  >
                    {icon}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={() => removeValue(i)}
                    css={cssIcon({ clickable: true, draft: false })}
                  >
                    <Icons.Close />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        ))}
      <div css={cssNextValue}>
        <TextField
          type="text"
          fullWidth
          placeholder={placeholder}
          multiline={multiline}
          disabled={pending}
          value={nextName}
          onChange={e => setNextName(e.target.value)}
          onKeyUp={addValueOnEnter}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" css={cssIcon({ clickable: false, draft: true })}>
                {icon}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="start"
                onClick={addValue}
                css={cssIcon({ clickable: true, draft: true })}
              >
                <Icons.AddButton />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div>{helperText}</div>
    </React.Fragment>
  )
}

const cssLabel = css`
  && {
    font-size: 0.75rem;
    margin-bottom: 8px;
  }
`
const cssValue = css``
const cssNextValue = css``
const cssIcon = (v: { clickable: boolean; draft: boolean }) => css`
  ${v.draft && 'color: #999;'}
  ${v.clickable && 'cursor: pointer;'}
`
