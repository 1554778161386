/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { jsx } from '@emotion/core'
import * as Api from 'shared/api'
import { useConnections } from 'shared/context/connections'
import * as Chat from 'shared/data/chatConfiguration'
import { UUID } from 'shared/types/UUID'
import { Caller } from 'shared/types/Caller'
import { ConnectionHeader, ConnectionLoader, ConnectionProblem } from 'shared/components/Connection'
import { ActionDisconnect } from 'shared/views/actions/ActionDisconnect'
import { ActionToggleCamera } from 'shared/views/actions/ActionToggleCamera'
import { ActionToggleMicrophone } from 'shared/views/actions/ActionToggleMicrophone'
import { t } from 'shared/translations'
import { TwilioVideoChat } from './TwilioVideoChat'

type VideoChatProps = {
  me: UUID
  participant: Caller
  chat: Chat.ChatConfigurationState
  outgoing: boolean
}

export const VideoChat: React.FC<VideoChatProps> = ({ me, participant, chat, outgoing }) => {
  const { actions, dispatch } = useConnections()
  const [connected, setConnected] = useState<boolean>()
  const [cameraOn, setCameraOn] = useState<boolean>(true)
  const [microphoneOn, setMicrophoneOn] = useState<boolean>(true)
  const { id } = participant

  useEffect(() => {
    let cancelled = false
    let callUpSent = false
    dispatch(Chat.loading())
    Api.fetchChatConfiguration(me, id)
      .then(res => {
        if (!cancelled) {
          dispatch(Chat.success(res.chat))
          setConnected(true)
          if (outgoing) {
            Api.callUp(me, id).catch(() => !cancelled && setConnected(false))
            callUpSent = true
          }
        }
      })
      .catch(err => {
        if (!cancelled) {
          dispatch(Chat.failure(err))
          setConnected(false)
        }
      })

    return () => {
      cancelled = true
      // When videochat window gets closed, we hang up the connection.
      // We may skip that part if the call up request hasn't been sent yet.
      if (callUpSent) {
        Api.hangUp(me, id)
      }
    }
  }, [actions, dispatch, me, id, outgoing])

  const close = () => {
    actions.disconnect(participant.id)
  }

  if (chat.pending) {
    return (
      <Overlay>
        <Screen>
          <HeaderBox>
            <ConnectionHeader participant={participant} connected={connected} />
          </HeaderBox>
          <ContentBox>
            <ConnectionLoader participant={participant} size="large" />
          </ContentBox>
          <ActionsBox>
            <ActionDisconnect disconnect={close} />
          </ActionsBox>
        </Screen>
      </Overlay>
    )
  }

  if (chat.error) {
    return (
      <Overlay>
        <Screen>
          <HeaderBox>
            <ConnectionHeader participant={participant} connected={connected} />
          </HeaderBox>
          <ContentBox>
            <ConnectionProblem message={t['connection.error.chat_configuration']} />
          </ContentBox>
          <ActionsBox>
            <ActionDisconnect disconnect={close} />
          </ActionsBox>
        </Screen>
      </Overlay>
    )
  }

  if (chat.data) {
    if (typeof connected === 'undefined') {
      return (
        <Overlay>
          <Screen>
            <HeaderBox>
              <ConnectionHeader participant={participant} connected={connected} />
            </HeaderBox>
            <ContentBox>
              <ConnectionLoader participant={participant} size="large" />
            </ContentBox>
            <ActionsBox>
              <ActionDisconnect disconnect={close} />
            </ActionsBox>
          </Screen>
        </Overlay>
      )
    }
    if (connected === false) {
      return (
        <Overlay>
          <Screen>
            <HeaderBox>
              <ConnectionHeader participant={participant} connected={connected} />
            </HeaderBox>
            <ContentBox>
              <ConnectionProblem message={t['connection.error.calling_up']} />
            </ContentBox>
            <ActionsBox>
              <ActionDisconnect disconnect={close} />
            </ActionsBox>
          </Screen>
        </Overlay>
      )
    }
    if (connected === true) {
      return (
        <Overlay>
          <DarkScreen>
            <HeaderBox>
              <ConnectionHeader participant={participant} connected={connected} />
            </HeaderBox>
            <TwilioVideoChat chat={chat.data} cameraOn={cameraOn} microphoneOn={microphoneOn} />
            <ActionsBox>
              <ActionToggleCamera on={cameraOn} toggle={() => setCameraOn(!cameraOn)} />
              <ActionToggleMicrophone
                on={microphoneOn}
                toggle={() => setMicrophoneOn(!microphoneOn)}
              />
              <ActionDisconnect disconnect={close} />
            </ActionsBox>
          </DarkScreen>
        </Overlay>
      )
    }
  }

  return null
}

const Overlay = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`

const Screen = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  min-width: 640px;
  min-height: 480px;
  width: calc(100vw - 32px);
  height: calc(0.5 * (100vw - 32px));
  border-radius: 2px;
  background: white;
`

const DarkScreen = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  min-width: 640px;
  min-height: 480px;
  width: calc(100vw - 32px);
  height: calc(0.5 * (100vw - 32px));
  background: #3f3f3f;
`

const HeaderBox = styled.div`
  position: absolute;
  top: 20px;
  left: 24px;
  display: flex;
`

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const ActionsBox = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 20px auto 0;
  & > button:not(:first-of-type) {
    margin-left: 12px;
  }
`
