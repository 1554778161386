import React from 'react'
import { css } from '@emotion/core'
import { Entry, EntryInput } from 'shared/types/Entry'
import { MultiValueFieldProps, MultiValueBaseProps, MultiValueField } from 'shared/components/FormField/MultiValueField'
import { Icons } from 'shared/components/Icons'

const EntriesListField: React.FC<MultiValueFieldProps<Entry, EntryInput>> = MultiValueField

export const EntriesField: React.FC<MultiValueBaseProps<EntryInput>> = (props) => {
  return (
    <EntriesListField
      {...props}
      css={cssEntriesListField}
      icon={<Icons.Portrait />}
      values={props.field.value}
      onChange={entries => props.form.setFieldValue(props.field.name, entries)}
      toText={entry => entry.name}
      create={name => ({ name })}
      edit={(entry, name) => ({ ...entry, name })}
    />
  )
}

const cssEntriesListField = css`
  margin-bottom: 300px;
`