import React, { useEffect, useState } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Ft from 'shared/data/physiotherapist'
import * as Cp from 'shared/data/company'
import { useToasts } from 'shared/context/toasts'
import { Physiotherapist } from 'shared/types/Physiotherapist'
import { UUID } from 'shared/types/UUID'
import { Company } from 'shared/types/Company'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { ButtonLink } from 'shared/components/Link'
import { ListHeader } from 'shared/components/ListHeader'
import { ListFilters } from 'shared/components/ListFilters'
import { TableRowActions } from 'shared/components/TableRowActions'
import { PhysiotherapistsList } from 'shared/views/PhysiotherapistsList'
import { ActionLinkEdit } from 'shared/views/actions/ActionLinkEdit'
import { ActionToggleActiveStatus } from 'shared/views/actions/ActionToggleActiveStatus'
import { t } from 'shared/translations'
import { link } from 'modules/routes'
import { Params } from 'modules/company/routes'

export const CompanyPhysiotherapists: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, collection, entity } = Ft.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy, updateStatus } = Ft.useQueries()
  const { companyId } = match.params
  const [company, setCompany] = useState<Company>()

  useEffect(() => {
    let cancelled = false
    if (companyId) {
      Api.fetchCompany(companyId).then(res => !cancelled && setCompany(res.company))
    }
    return () => {
      cancelled = true
    }
  }, [companyId])

  useEffect(() => {
    let cancelled = false
    if (companyId) {
      collection.loading()
      Api.fetchPhysiotherapists({ ...query, companies: [companyId] })
        .then(res => !cancelled && collection.success(res.physiotherapists, res.meta))
        .catch(err => !cancelled && collection.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [collection, query, companyId])

  const activatePhysiotherapist = (id: UUID) => {
    Api.activatePhysiotherapist(id)
      .then(res => {
        toasts.success(`Aktywowano fizjoterapeutę`)
        entity.success(res.physiotherapist)
      })
      .catch(() => {
        toasts.error(`Nie udało się aktywować fizjoterapeuty`)
      })
  }

  const deactivatePhysiotherapist = (id: UUID) => {
    Api.deactivatePhysiotherapist(id)
      .then(res => {
        toasts.success(`Dezaktywowano fizjoterapeutę`)
        entity.success(res.physiotherapist)
      })
      .catch(() => {
        toasts.error(`Nie udało się dezaktywować fizjoterapeuty`)
      })
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <ListHeader
          title={t['title.physiotherapists']}
          action={
            Cp.canAddPhysiotherapist(company) && (
              <ButtonLink to={link['/company/:companyId/physiotherapists/add'](match.params)}>
                {t['title.physiotherapist.add']}
              </ButtonLink>
            )
          }
        />
        <ListFilters
          searchTerm={query.searchTerm || ''}
          status={query.status}
          searchBy={searchBy}
          updateStatus={updateStatus}
        />
        <PhysiotherapistsList
          state={state}
          order={query.order}
          loadNext={loadNext}
          sortBy={sortBy}
          rowLink={physiotherapist =>
            link['/physiotherapist/:physiotherapistId']({
              physiotherapistId: physiotherapist.id,
            })
          }
          renderActions={props => {
            const p = props.rowData as Physiotherapist
            return (
              <TableRowActions>
                <ActionLinkEdit
                  to={link['/company/:companyId/physiotherapists/:physiotherapistId']({
                    companyId: match.params.companyId,
                    physiotherapistId: p.id,
                  })}
                />
                <ActionToggleActiveStatus
                  status={p.state}
                  activate={() => activatePhysiotherapist(p.id)}
                  deactivate={() => deactivatePhysiotherapist(p.id)}
                />
              </TableRowActions>
            )
          }}
        />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
