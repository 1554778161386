import trim from 'lodash/trim'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import isPlainObject from 'lodash/isPlainObject'
import { Query } from 'shared/types/Query'
import { defaults } from './config'

export const request = {
  head: (endpoint: string) =>
    send(endpoint, {
      ...defaults.head(),
    }),
  get: (endpoint: string, params?: RequestInit) =>
    send(endpoint, {
      ...defaults.get(),
      ...params,
    }),
  post: (endpoint: string, payload: any, params?: RequestInit) =>
    send(endpoint, {
      ...defaults.post(),
      ...params,
      body: JSON.stringify(payload),
    }),
  put: (endpoint: string, payload: any, params?: RequestInit) =>
    send(endpoint, {
      ...defaults.put(),
      ...params,
      body: JSON.stringify(payload),
    }),
  patch: (endpoint: string, payload?: any, params?: RequestInit) =>
    send(endpoint, {
      ...defaults.patch(),
      ...params,
      body: JSON.stringify(payload),
    }),
  delete: (endpoint: string, params?: RequestInit) =>
    send(endpoint, {
      ...defaults.delete(),
      ...params,
    }),
}

const send = async (input: RequestInfo, init?: RequestInit): Promise<any> => {
  try {
    const res = await fetch(input, init)
    if (!res.ok) {
      // We received response from the server, but there was some error
      // Some HTTP errors don't come with a JSON payload
      // Make sure we don't attempt to parse them as JSON
      if (res.status === 404 && res.statusText === 'Not Found') {
        return Promise.reject({
          message: res.statusText,
        })
      }
      if (res.status === 405 && res.statusText === 'Method Not Allowed') {
        return Promise.reject({
          message: res.statusText,
        })
      }
      throw await res.json()
    }
    // Make sure we're not trying to parse empty responses as JSON
    if (res.status === 204 && res.statusText === 'No Content') {
      return res.text()
    }
    // Special-case checking for existence of files
    // These are HEAD requests with no response body to unpack, just make we get status 200
    if (res.status === 200 && typeof input === 'string' && input.includes('/files/')) {
      return Promise.resolve('')
    }
    return res.json()
  } catch (err) {
    // Network error
    if (err instanceof Error) {
      return Promise.reject({
        message: err.message,
      })
    }
    // Server error
    return Promise.reject(err)
  }
}

export const serialize = (queryParams?: Query): string => {
  let params: Query = {}
  if (queryParams) {
    params = { ...queryParams }
  }
  let queryString = Object.entries(params).reduce((queryString, [key, value]) => {
    if (isEmptyValue(value)) {
      return queryString
    }
    if (isPlainObject(value)) {
      if (Object.keys(value).length > 0) {
        value = flatten(Object.entries(value)).join(':')
      }
    }
    return `${queryString}&${key}=${encodeURI(value)}`
  }, '')

  return queryString && `?${trim(queryString, '&')}`
}

export const isEmptyValue = (v: any): boolean => {
  if (typeof v === 'string') {
    return trim(v) === ''
  } else if (typeof v === 'object' && isEmpty(v)) {
    return true
  }
  return isNil(v)
}
