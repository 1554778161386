import {
  RemoteEntity,
  RemoteEntityReducer,
  remoteEntityReducer,
  idle,
} from 'shared/types/RemoteEntity'
import { ChatConfiguration } from 'shared/types/ChatConfiguration'

export const initialState: RemoteEntity<ChatConfiguration> = idle
export const reducer: RemoteEntityReducer<ChatConfiguration> = remoteEntityReducer
