import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Pt from 'shared/data/patient'
import { useToasts } from 'shared/context/toasts'
import { PatientInput } from 'shared/types/Patient'
import { Availability } from 'shared/types/Availability'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { PatientForm } from 'shared/views/PatientForm'
import {
  ActionCall,
  ActionCallLargeButton,
  LargeActionFloatingBox,
} from 'shared/views/actions/ActionCall'
import { createEditFormFieldValidator } from 'shared/utils/validation'
import { link } from 'modules/routes'
import { Params } from 'modules/physiotherapist/routes'

export const PhysiotherapistPatientTabEdit: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, entity } = Pt.useEntityReducer()
  const [availability, setAvailability] = useState<Availability>()
  const [redirect, setRedirect] = useState<string>()
  const { patientId } = match.params
  const { data } = state
  const backToList = link['/physiotherapist/:physiotherapistId/patients'](match.params)

  useEffect(() => {
    let cancelled = false
    if (patientId) {
      entity.loading()
      Api.fetchPatient(patientId)
        .then(res => !cancelled && entity.success(res.patient))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, patientId])

  useEffect(() => {
    let cancelled = false
    if (patientId) {
      Api.getUserAvailability(patientId)
        .then(res => !cancelled && setAvailability(res.user.availability))
        .catch(() => !cancelled && setAvailability(undefined))
    }
    return () => {
      cancelled = true
    }
  }, [patientId])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const save = (input: PatientInput) => {
    entity.loading()
    Api.updatePatient(Pt.payloadUpdate(data, input))
      .then(res => {
        toasts.success(`Zapisano zmiany`)
        entity.success(res.patient)
        setRedirect(backToList)
      })
      .catch(err => {
        toasts.success(`Nie udało się zapisać zmian`)
        entity.failure(err)
      })
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumnScrollable>
        <PatientForm
          createFieldValidator={createEditFormFieldValidator}
          state={state}
          save={save}
          headerActions={[]}
          footerActions={[]}
        />
        <LargeActionFloatingBox>
          <ActionCall
            participant={data}
            availability={availability}
            renderer={ActionCallLargeButton}
          />
        </LargeActionFloatingBox>
      </Layout.FlexColumnScrollable>
    </ErrorBoundary>
  )
}
