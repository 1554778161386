import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Pt from 'shared/data/patient'
import { useToasts } from 'shared/context/toasts'
import { Patient } from 'shared/types/Patient'
import { UUID } from 'shared/types/UUID'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { ButtonLink } from 'shared/components/Link'
import { ListHeader } from 'shared/components/ListHeader'
import { ListFilters } from 'shared/components/ListFilters'
import { TableRowActions } from 'shared/components/TableRowActions'
import { PatientsList } from 'shared/views/PatientsList'
import { ActionLinkEdit } from 'shared/views/actions/ActionLinkEdit'
import { ActionToggleActiveStatus } from 'shared/views/actions/ActionToggleActiveStatus'
import { t } from 'shared/translations'
import { link } from 'modules/routes'
import { Params } from 'modules/superadmin/routes'

export const SuperAdminPatients: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, collection, entity } = Pt.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy, updateStatus } = Pt.useQueries()

  useEffect(() => {
    let cancelled = false
    collection.loading()
    Api.fetchPatients(query)
      .then(res => !cancelled && collection.success(res.patients, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = true
    }
  }, [collection, query])

  const activatePatient = (id: UUID) => {
    Api.activatePatient(id)
      .then(res => {
        toasts.success(`Aktywowano pacjenta`)
        entity.success(res.patient)
      })
      .catch(() => {
        toasts.error(`Nie udało się aktywować pacjenta`)
      })
  }

  const deactivatePatient = (id: UUID) => {
    Api.deactivatePatient(id)
      .then(res => {
        toasts.success(`Dezaktywowano pacjenta`)
        entity.success(res.patient)
      })
      .catch(() => {
        toasts.error(`Nie udało się dezaktywować pacjenta`)
      })
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <ListHeader
          title={t['title.patients']}
          action={
            <ButtonLink to={link['/superadmin/patients/add'](match.params)}>
              {t['title.patient.add']}
            </ButtonLink>
          }
        />
        <ListFilters
          searchTerm={query.searchTerm || ''}
          status={query.status}
          searchBy={searchBy}
          updateStatus={updateStatus}
        />
        <PatientsList
          state={state}
          order={query.order}
          loadNext={loadNext}
          sortBy={sortBy}
          rowLink={patient =>
            link['/patient/:patientId']({
              patientId: patient.id,
            })
          }
          renderActions={props => {
            const p = props.rowData as Patient
            return (
              <TableRowActions>
                <ActionLinkEdit
                  to={link['/superadmin/patients/:patientId']({
                    patientId: p.id,
                  })}
                />
                <ActionToggleActiveStatus
                  status={p.state}
                  activate={() => activatePatient(p.id)}
                  deactivate={() => deactivatePatient(p.id)}
                />
              </TableRowActions>
            )
          }}
        />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
