import React from 'react'
import { Note, NoteInput } from 'shared/types/Note'
import { MultiValueFieldProps, MultiValueBaseProps, MultiValueField } from 'shared/components/FormField/MultiValueField'
import { Icons } from 'shared/components/Icons'

const NotesListField: React.FC<MultiValueFieldProps<Note, NoteInput>> = MultiValueField

export const NotesField: React.FC<MultiValueBaseProps<NoteInput>> = (props) => {
  return (
    <NotesListField
      {...props}
      icon={<Icons.Notes />}
      values={props.field.value}
      onChange={notes => props.form.setFieldValue(props.field.name, notes)}
      toText={note => note.content}
      create={content => ({ content })}
      edit={(note, content) => ({ ...note, content })}
      multiline={true}
    />
  )
}
