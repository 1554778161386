import { Query } from 'shared/types/Query'
import { Status } from 'shared/types/Status'

type CreateQueriesParams<D extends object = any> = {
  query: Query<D>
  updateQuery: (q: Query<D>) => void
}

export type CreateQueries<D extends object = any> = (params: CreateQueriesParams<D>) => Queries<D>

export type Queries<D extends object = any> = {
  loadNext: () => void
  searchBy: (searchTerm: string) => void
  sortBy: (by: keyof D) => void
  updateStatus: (status?: Status) => void
}

export const createQueries: CreateQueries = ({ query, updateQuery }) => ({
  loadNext: () =>
    updateQuery({
      ...query,
      offset: 0,
      limit: (query.limit || 16) + 8,
    }),
  searchBy: searchTerm =>
    updateQuery({
      ...query,
      offset: 0,
      limit: 16,
      searchTerm,
    }),
  sortBy: by => {
    const [[key, value = 'asc']] = Object.entries(query.order || {})
    return updateQuery({
      ...query,
      offset: 0,
      limit: 16,
      order: {
        [by]: key === by && value === 'asc' ? 'desc' : 'asc',
      },
    })
  },
  updateStatus: status => {
    let nextQuery = {
      ...query,
      offset: 0,
      limit: 16,
    } as any
    if (status) {
      nextQuery.states = [status]
    } else {
      delete nextQuery.states
    }
    return updateQuery(nextQuery)
  },
})
