import React from 'react'
import * as Api from 'shared/api'
import { UUID } from 'shared/types/UUID'
import { Patient } from 'shared/types/Patient'
import { GetLabel, Fetch } from 'shared/types/Label'
import { RemoteLabel } from 'shared/components/RemoteLabel'

type PatientLabelProps = {
  id: UUID
}

const getLabel: GetLabel<Patient> = pt => `${pt.firstName} ${pt.lastName}`
const fetch: Fetch<Patient> = id => Api.fetchPatient(id).then(res => res.patient)

export const PatientLabel: React.FC<PatientLabelProps> = ({ id }) => {
  return <RemoteLabel id={id} getLabel={getLabel} fetch={fetch} />
}
