/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { NavLink } from 'react-router-dom'
import { colors } from 'shared/styles/colors'

type SidebarGroupProps = {
  title: string
  links: { icon: React.ReactNode; to: string; label: string }[]
}

export const SidebarGroup: React.FC<SidebarGroupProps> = ({ title, links }) => {
  return (
    <nav css={cssSidebarGroup}>
      <header css={cssTitle}>{title}</header>
      {links.map(link => (
        <NavLink
          key={link.to}
          to={link.to}
          css={cssLink}
          activeStyle={{
            fontWeight: 'bold',
            background: '#eee',
            color: '#FF6247',
            borderTopRightRadius: '25px',
            borderBottomRightRadius: '25px',
          }}
        >
          {link.icon}
          {link.label}
        </NavLink>
      ))}
    </nav>
  )
}

// Let's only show role name in sidebar when there are 2+ sidebar panels.
// This will happen when I visit someone else's panel as an admin/company user.
const cssSidebarGroup = css`
  margin-top: 16px;
  &:first-of-type > header {
    display: none;
  }
`

const cssTitle = css`
  padding: 0 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 32px;
  color: #ccc;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
`

const cssLink = css`
  height: 40px;
  padding: 0 10px;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  color: ${colors.mediumDarkGrey};
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  svg {
    margin: 0 10px;
  }
  &:hover {
    background: ${colors.lightGrey};
  }
`
