/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useState } from 'react'
import { ExerciseParameters } from 'shared/types/ExerciseParameters'
import * as Layout from 'shared/components/Layout'
import { Modal, ModalTitle } from 'shared/components/Modal'
import { IconButton, FooterButton } from 'shared/components/Button'
import { Icons } from 'shared/components/Icons'
import { ExerciseExplainedView } from 'shared/views/ExerciseExplainedView'
import { TeleportToModals } from 'shared/views/portals/PortalWithModals'
import { t } from '../../translations'

type ActionOpenPreviewProps = {
  values: ExerciseParameters[]
  disabled?: boolean
  renderAsButton?: boolean
}

export const ActionOpenPreview: React.FC<ActionOpenPreviewProps> = ({
  values,
  disabled,
  renderAsButton,
}) => {
  const [open, setOpen] = useState(false)

  const openModal = () => setOpen(true)
  const closeModal = () => setOpen(false)

  return (
    <React.Fragment>
      {renderAsButton ? (
        <FooterButton disabled={disabled} onClick={openModal} title={'Podgląd zestawu'}>
          <Icons.Visibility />
          {'Podgląd zestawu'}
        </FooterButton>
      ) : (
        <IconButton disabled={disabled} onClick={openModal} title={'Podgląd zestawu'}>
          <Icons.Visibility />
        </IconButton>
      )}

      {open && (
        <TeleportToModals>
          <Modal open={open} onClose={closeModal}>
            <ModalTitle>{t['title.templatesPreview']}</ModalTitle>
            <Layout.FlexColumnScrollable css={cssLimitHeight}>
              {values.map((xp, i) => (
                <ExerciseExplainedView key={i} exercisesParameters={xp}/>
              ))}
            </Layout.FlexColumnScrollable>
            <footer css={cssFooter}></footer>
          </Modal>
        </TeleportToModals>
      )}
    </React.Fragment>
  )
}

const cssLimitHeight = css`
  height: calc(100% - 50px);
`
const cssFooter = css`
  height: 16px;
  background: white;
`
