import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { PatientPayments } from './Payments'
import { path } from 'modules/routes'

export const PatientPaymentsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={path['/patient/:patientId/payments']} component={PatientPayments} />
    </Switch>
  )
}
