import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

export const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'inherit',
      flex: 1,
      overflow: 'auto',
      paddingBottom: 30,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      flexGrow: 1,
    },
    padding: {
      padding: '16px',
    },
    paddingTop: {
      paddingTop: '16px',
    },
    label: {
      fontSize: '0.75rem !important',
    },
  })
)
