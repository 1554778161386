import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import { Authorize } from 'Auth/Authorize'
import { checkAccess } from './access'
import { Params, path, link } from './routes'
import { CompanyProfile } from './pages/Profile'
import { CompanyPhysiotherapistsRoutes } from './pages/Physiotherapists'
import { CompanySettlementsRoutes } from './pages/Settlements'

export const Module: React.FC = () => {
  return (
    <Authorize checkAccess={checkAccess}>
      <Switch>
        <Route exact path={path['/company/:companyId']} component={Entry} />
        <Route exact path={path['/company/:companyId/profile']} component={CompanyProfile} />
        <Route
          path={path['/company/:companyId/physiotherapists']}
          component={CompanyPhysiotherapistsRoutes}
        />
        <Route
          path={path['/company/:companyId/settlements']}
          component={CompanySettlementsRoutes}
        />
      </Switch>
    </Authorize>
  )
}

const Entry = () => {
  const { match } = useRouter<Params>()
  return <Redirect to={link['/company/:companyId/physiotherapists'](match.params)} />
}
