/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { AppHeader } from 'App/layout/AppHeader'
import { AppSidebar } from 'App/layout/AppSidebar'
import { AppPanel } from 'App/layout/AppPanel'
import { AppModals } from 'App/layout/AppModals'
import { AppToasts } from 'App/layout/AppToasts'
import { AppPages } from 'App/AppPages'

export const AppLayout: React.FC = () => {
  return (
    <div css={cssAppLayout}>
      <AppHeader />
      <div css={cssAppLayoutContainer}>
        <AppSidebar />
        <main css={cssAppLayoutMain}>
          <AppPages />
        </main>
        <AppPanel />
      </div>
      <AppModals />
      <AppToasts />
    </div>
  )
}

const cssAppLayout = css`
  width: 100vw;
  height: 100vh;
`
const cssAppLayoutContainer = css`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: calc(100vh - 60px);
  min-height: calc(100vh - 60px);
`
const cssAppLayoutMain = css`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: white;
  transition: width 320ms linear;
`
