import { pl } from 'date-fns/locale'
import { Translations } from './Translations'
import { pl as translations } from './translations.pl'

/**
 * Object containing all known translations
 */
export const t: Translations = translations

/**
 * Locale options for `date-fns` formatting functions
 */
export const localeOptions = { locale: pl }
