import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import { Authorize } from 'Auth/Authorize'
import { checkAccess } from './access'
import { PatientProfile } from './pages/Profile'
import { PatientPhysiotherapistsRoutes } from './pages/Physiotherapists'
import { PatientSetsRoutes } from './pages/Sets'
import { PatientPaymentsRoutes } from './pages/Payments'
import { Params, path, link } from './routes'

export const Module: React.FC = () => {
  return (
    <Authorize checkAccess={checkAccess}>
      <Switch>
        <Route exact path={path['/patient/:patientId']} component={Entry} />
        <Route exact path={path['/patient/:patientId/profile']} component={PatientProfile} />
        <Route path={path['/patient/:patientId/sets']} component={PatientSetsRoutes} />
        <Route
          path={path['/patient/:patientId/physiotherapists']}
          component={PatientPhysiotherapistsRoutes}
        />
        <Route path={path['/patient/:patientId/payments']} component={PatientPaymentsRoutes} />
      </Switch>
    </Authorize>
  )
}

const Entry = () => {
  const { match } = useRouter<Params>()
  return <Redirect to={link['/patient/:patientId/sets'](match.params)} />
}
