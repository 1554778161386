import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Ft from 'shared/data/physiotherapist'
import { useToasts } from 'shared/context/toasts'
import { PhysiotherapistInput } from 'shared/types/Physiotherapist'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { FormFooter } from 'shared/components/FormFooter'
import { FormHeader } from 'shared/views/headers/FormHeader'
import { PhysiotherapistForm } from 'shared/views/PhysiotherapistForm'
import { createEditFormFieldValidator } from 'shared/utils/validation'
import { link } from 'modules/routes'
import { Params } from 'modules/company/routes'

export const CompanyPhysiotherapistEdit: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, entity } = Ft.useEntityReducer()
  const [redirect, setRedirect] = useState<string>()
  const { physiotherapistId } = match.params
  const { data } = state
  const backToList = link['/company/:companyId/physiotherapists'](match.params)

  useEffect(() => {
    let cancelled = false
    if (physiotherapistId) {
      entity.loading()
      Api.fetchPhysiotherapist(physiotherapistId)
        .then(res => !cancelled && entity.success(res.physiotherapist))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, physiotherapistId])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const save = (input: PhysiotherapistInput) => {
    entity.loading()
    Api.updatePhysiotherapist(Ft.payloadUpdate(data, input))
      .then(res => {
        toasts.success(`Zapisano zmiany`)
        entity.success(res.physiotherapist)
        setRedirect(backToList)
      })
      .catch(err => {
        toasts.error(`Nie udało się zapisać zmian`)
        entity.failure(err)
      })
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <FormHeader
          back={backToList}
          title={`${data.firstName} ${data.lastName}`}
          status={data.state}
        />
        <PhysiotherapistForm
          createFieldValidator={createEditFormFieldValidator}
          state={state}
          save={save}
          headerActions={[]}
          footerActions={[]}
        />
        <FormFooter />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
