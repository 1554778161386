/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import styled from '@emotion/styled'
import { Physiotherapist } from 'shared/types/Physiotherapist'
import { Availability } from 'shared/types/Availability'
import { AvailabilityDot } from 'shared/components/AvailabilityDot'
import { AvailabilityStatus } from 'shared/components/AvailabilityStatus/index'
import { AvailabilityInfo } from 'shared/components/AvailabilityInfo/index'
import { FormSectionHeader } from 'shared/components/FormSectionHeader'
import { Footer } from 'shared/components/FormFooter'
import { ActionCall, ActionCallLargeButton } from 'shared/views/actions/ActionCall'
import { CompanyLabel } from 'shared/views/labels/CompanyLabel'
import { useFormStyles } from 'shared/styles/form'
import { colors } from 'shared/styles/colors'
import { t } from 'shared/translations'

type PhysiotherapistViewProps = {
  physiotherapist: Physiotherapist
  availability?: Availability
}

export const PhysiotherapistView: React.FC<PhysiotherapistViewProps> = ({
  physiotherapist,
  availability,
}) => {
  const classes = useFormStyles()

  return (
    <Grid container className={classes.root}>
      <Grid container direction="column" className={classes.padding}>
        <NameIconWrapper>
          <NameLetters>
            {physiotherapist.firstName[0]}
            {physiotherapist.lastName[0]}
          </NameLetters>
          {availability && <AvailabilityDot availability={availability} />}
          <h1>
            {physiotherapist.firstName} {physiotherapist.lastName}
          </h1>
          {availability && <AvailabilityStatus availability={availability} />}
          {availability && <AvailabilityInfo availability={availability} />}
        </NameIconWrapper>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} sm={6} lg={3}>
            <div>
              <Span>{t['property.firstName']}</Span>
              <p>{physiotherapist.firstName}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div>
              <Span>{t['property.lastName']}</Span>
              <p>{physiotherapist.lastName}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div>
              <Span>{t['property.phone']}</Span>
              <p>{physiotherapist.phone}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div>
              <Span>{t['property.email']}</Span>
              <p>{physiotherapist.email}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div>
              <Span>{t['property.voivodeship']}</Span>
              <p>{physiotherapist.voivodeship}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div>
              <Span>{t['property.city']}</Span>
              <p>{physiotherapist.city}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <div>
              <Span>{t['property.street']}</Span>
              <p>{physiotherapist.street}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} lg={1}>
            <div>
              <Span>{t['property.houseNumber']}</Span>
              <p>{physiotherapist.houseNumber}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} lg={1}>
            <div>
              <Span>{t['property.apartmentNumber']}</Span>
              <p>{physiotherapist.apartmentNumber}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>
              <Span>{t['property.description']}</Span>
              <p css={cssDescription}>{physiotherapist.description || '-'}</p>
            </div>
          </Grid>
        </Grid>
        <FormSectionHeader>{t['section.medical']}</FormSectionHeader>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} md={6}>
            <div>
              <Span>{t['property.company']}</Span>
              <p>
                <CompanyLabel id={physiotherapist.company} />
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <Span>{t['property.licenseNumber']}</Span>
              <p>{physiotherapist.licenseNumber}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <Span>{t['property.specialisations']}</Span>
              {physiotherapist.specialisations &&
                physiotherapist.specialisations.map(specialisation => (
                  <p key={specialisation.name}>{specialisation.name}</p>
                ))}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer css={cssFooter}>
        <ActionCall
          participant={physiotherapist}
          availability={availability}
          renderer={ActionCallLargeButton}
        />
      </Footer>
    </Grid>
  )
}

const Span = styled.span`
  font-size: 0.7rem;
  color: ${colors.mediumDarkGrey};
`

const NameIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;
`

const NameLetters = styled.div`
  font-size: 4rem;
  border-radius: 50%;
  padding: 25px 25px;
  margin-right: 30px;
  color: ${colors.mediumGrey};
  background-color: ${colors.lightMediumGrey};
`

const cssDescription = css`
  white-space: pre-wrap;
`

const cssFooter = css`
  justify-content: flex-end;
  padding: 0 24px;
`
