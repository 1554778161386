import { t } from 'shared/translations'

const VALID_ACCOUNT_NUMBER = /^\d{26}$/
const VALID_ACCOUNT_NUMBER_WITH_SPACES = /^\d{2} \d{4} \d{4} \d{4} \d{4} \d{4} \d{4}$/
const TODO_FIXME_IS_THIS_FORMAT_EVENT_CORRECT_BECAUSE_WE_GET_THAT_FROM_FIXTURES = /^\d{2} \d{4} \d{4} \d{4} \d{4} \d{4}$/

export const validateAccountNumber = (val: any): string | undefined => {
  let str = val
  if (typeof val === 'number') {
    str = String(val)
  }
  if (typeof str !== 'string') return t['invalid']
  if (
    !VALID_ACCOUNT_NUMBER.test(str) &&
    !VALID_ACCOUNT_NUMBER_WITH_SPACES.test(str) &&
    !TODO_FIXME_IS_THIS_FORMAT_EVENT_CORRECT_BECAUSE_WE_GET_THAT_FROM_FIXTURES.test(str)
  )
    return t['invalid']
}
