import React, { useEffect } from 'react'
import * as Api from 'shared/api'
import * as SetEx from 'shared/data/setOfExercises'
import { SetOfExercises } from 'shared/types/SetOfExercises'
import { UUID } from 'shared/types/UUID'
import { Modal, ModalTitle } from 'shared/components/Modal'
import { Search } from 'shared/components/Search'
import { SetsOfExercisesListSmall } from 'shared/views/SetsOfExercisesListSmall'
import { t } from 'shared/translations'

type ModalProps = {
  cancel: () => void
  select: (setEx: SetOfExercises) => void
  physiotherapistId: UUID
}

export const ModalSelectExistingSet: React.FC<ModalProps> = ({
  cancel,
  select,
  physiotherapistId,
}) => {
  const { state, collection } = SetEx.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy } = SetEx.useQueries()

  useEffect(() => {
    let cancelled = false
    collection.loading()
    Api.fetchSetsOfExercises({
      ...query,
      physiotherapists: [physiotherapistId],
      states: ['active'],
    })
      .then(res => !cancelled && collection.success(res.setsOfExercises, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = true
    }
  }, [collection, physiotherapistId, query])

  return (
    <Modal open={true} onClose={cancel}>
      <ModalTitle>{t['title.setsOfExercises']}</ModalTitle>
      <Search value={query.searchTerm || ''} onChange={evt => searchBy(evt.target.value)} />
      <SetsOfExercisesListSmall
        select={select}
        state={state}
        order={query.order}
        loadNext={loadNext}
        sortBy={sortBy}
      />
    </Modal>
  )
}
