import { UUID } from 'shared/types/UUID'

const serverUrl = process.env.REACT_APP_SERVER_URL
const serverEventsUrl = process.env.REACT_APP_SERVER_EVENTS_URL

/**
 * Create URL for listening to server events about my chat(s).
 * This is needed so that user can be notified about incoming calls.
 * @param userId {UUID} - my id
 */
export const eventSourceMyChatUrl = (me: UUID) =>
  `${serverEventsUrl}?topic=${encodeURIComponent(serverUrl + '/chat/' + me)}`

/**
 * Create URL for listening to server events about user's online status.
 * This is needed so that we know if other user is currently online (and/or busy) or not.
 * @param userId {UUID} - other person's id
 */
export const eventSourceUserAvailabilityUrl = (userId: UUID) =>
  `${serverEventsUrl}?topic=${encodeURIComponent(serverUrl + '/user/' + userId)}`
