import React, { useEffect, useState } from 'react'
import { UUID } from 'shared/types/UUID'
import { Entity } from 'shared/types/Entity'
import { GetLabel, Fetch } from 'shared/types/Label'
import { RemoteCollection } from 'shared/types/RemoteCollection'

type RemoteLabelProps<D extends Entity = any> = {
  id: UUID
  getLabel: GetLabel<D>
  fetch: Fetch<D>
  cache?: RemoteCollection<D>
}

export const RemoteLabel: React.FC<RemoteLabelProps> = ({ id, getLabel, fetch, cache }) => {
  const isLoadingCache = Boolean(cache && cache.pending)
  const cached = cache && cache.data && cache.data.find(d => d.id === id)
  const cachedLabel = cached ? getLabel(cached) : '...'
  const shouldFetch = !cached && !isLoadingCache

  const [label, setLabel] = useState(cachedLabel)

  useEffect(() => {
    if (shouldFetch) {
      let cancelled = false
      fetch(id)
        .then(data => !cancelled && setLabel(getLabel(data)))
        .catch(() => !cancelled && setLabel('-'))
      return () => {
        cancelled = true
      }
    }
  }, [fetch, getLabel, id, shouldFetch])

  return <span>{label}</span>
}
