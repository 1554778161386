/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import * as Api from 'shared/api'
import * as SetEx from 'shared/data/setOfExercises'
import { SetOfExercisesByDay } from 'shared/types/SetOfExercises'
import * as Layout from 'shared/components/Layout'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { H4 } from 'shared/components/Heading'
import { AdditionalInfo } from 'shared/components/AdditionalInfo'
import { Icons } from 'shared/components/Icons'
import { ExerciseExplainedView } from 'shared/views/ExerciseExplainedView'
import { SetOfExercisesTag } from 'shared/views/tags/SetOfExercisesTag'
import { toDateLabel, getEndDay } from 'shared/utils/date'
import { t } from 'shared/translations'

type SetOfExercisesByDayPlanProps = {
  setOfExercisesByDay: SetOfExercisesByDay
}

export const SetOfExercisesByDayPlan: React.FC<SetOfExercisesByDayPlanProps> = ({
  setOfExercisesByDay,
}) => {
  const { state, entity } = SetEx.useEntityReducer()
  const { id } = setOfExercisesByDay
  const { data } = state

  // Load full details about this exercise set.
  // Things like full names, exercise parameters, statistics etc. are missing from `SetOfExercisesByDay`
  useEffect(() => {
    let cancelled = false
    entity.loading()
    Api.fetchSetOfExercises(id)
      .then(res => !cancelled && entity.success(res.setOfExercises))
      .catch(err => !cancelled && entity.failure(err))
    return () => {
      cancelled = true
    }
  }, [entity, id])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  return (
    <React.Fragment>
      <Layout.DetailsHeader css={cssHeader}>
        <H4>
          {setOfExercisesByDay.name} {t['date.forDay']} {toDateLabel(setOfExercisesByDay.date.substring(0,10))}
        </H4>
        <div>
          <TopAdditionalInfo icon={Icons.Assignment} text={t['info.set'](data.number)} />
          <TopAdditionalInfo
            icon={Icons.Person}
            text={t['info.author'](data.physiotherapistFullName)}
          />
          <TopAdditionalInfo
            icon={Icons.Calendar}
            text={t['info.activeDateRange'](
              toDateLabel(data.dateStart.substring(0,10)),
              toDateLabel(getEndDay(data.dateStart, data.duration))
            )}
          />
        </div>
        <aside>
          <SetOfExercisesTag />
        </aside>
      </Layout.DetailsHeader>
      <Layout.DetailsSection css={cssExercises}>
        {data.exercisesParameters.map((parameters, index) => (
          <ExerciseExplainedView key={index} exercisesParameters={parameters} />
        ))}
      </Layout.DetailsSection>
    </React.Fragment>
  )
}

const cssHeader = css`
  position: relative;
  display: flex;
  flex-direction: column;
  & > h4 {
    min-height: 36px;
    font-size: 1.7rem;
    font-weight: 400;
  }
  & > div:nth-of-type(1) {
    padding: 12px 0 16px;
  }
  & > aside {
    position: absolute;
    top: 0;
    right: 16px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
  }
  & div > div > svg{
    font-size: 1.3rem !important;
  }
  & div > div{
    vartical-align: middle;
    font-size: 15px;
  }
`
const cssExercises = css`
  display: flex;
  flex-direction: column;
`
const TopAdditionalInfo = styled(AdditionalInfo)`
  && {
    margin-left: -10px !important;
  }
`