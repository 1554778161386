import { Physiotherapist } from 'shared/types/Physiotherapist'
import { UseRemoteEntityReducer, useRemoteEntityReducer } from 'shared/hooks/useRemoteEntityReducer'
import {
  UseRemoteCollectionReducer,
  useRemoteCollectionReducer,
} from 'shared/hooks/useRemoteCollectionReducer'

export const useEntityReducer: UseRemoteEntityReducer<Physiotherapist> = useRemoteEntityReducer
export const useCollectionReducer: UseRemoteCollectionReducer<
  Physiotherapist
> = useRemoteCollectionReducer
