import React, { useEffect, useState } from 'react'
import * as Api from 'shared/api'
import * as SetStat from 'shared/data/setOfExercisesStatistics'
import { SetOfExercisesByDay } from 'shared/types/SetOfExercises'
import { SetOfExercisesStatistics } from 'shared/types/SetOfExercisesStatistics'
import {
  TimelineEntryIcon,
  TimelineEntryTitle,
  TimelineEntryContent,
} from 'shared/components/Timeline'
import { t } from 'shared/translations'

type TimelineEntryProps = {
  entry: SetOfExercisesByDay
  isSelected: boolean
}

export const TimelineEntryPastSet: React.FC<TimelineEntryProps> = ({ entry, isSelected }) => {
  const [statistics, setStatistics] = useState<SetOfExercisesStatistics>()
  const { setOfExercisesStatistics } = entry
  const size = isSelected ? 'large' : 'normal'
  const variant = SetStat.getColor(statistics)
  const Icon = SetStat.getIcon(statistics)

  useEffect(() => {
    let cancelled = false
    if (setOfExercisesStatistics) {
      Api.fetchSetOfExercisesStatistics(setOfExercisesStatistics)
        .then(res => !cancelled && setStatistics(res.setOfExercisesStatistics))
        .catch(() => !cancelled && setStatistics(undefined))
    }
    return () => {
      cancelled = true
    }
  }, [setOfExercisesStatistics])

  return (
    <React.Fragment>
      <TimelineEntryIcon size={size} variant={variant} icon={Icon} />
      <TimelineEntryTitle>
        <div>{entry.name}</div>
        <div>{entry.date.substring(0,10)}</div>
      </TimelineEntryTitle>
      <TimelineEntryContent>
        {entry.setOfExercisesStatistics
          ? statistics
            ? t['exercises.percentScore'](statistics.progress)
            : '...'
          : t['failed']}
      </TimelineEntryContent>
    </React.Fragment>
  )
}
