/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Status } from 'shared/types/Status'
import { Search } from 'shared/components/Search'
import { Icons } from 'shared/components/Icons'
import { colors } from 'shared/styles/colors'
import { t } from 'shared/translations'

type FiltersProps = {
  searchTerm: string
  status?: Status
  searchBy: (searchTerm: string) => void
  updateStatus?: (status?: Status) => void
}

export const ListFilters: React.FC<FiltersProps> = ({
  searchTerm,
  status,
  searchBy,
  updateStatus,
}) => {

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)

  const onMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const onMenuClose = () => {
    setMenuAnchor(null)
  }

  const inputLabel = React.useRef<HTMLLabelElement>(null);

  const selectStatus = (status: Status) => {
    onMenuClose()
    if(updateStatus){
      updateStatus(status)
    }
  }

  return (
    <div css={cssFilters}>
      <div css={cssSearchBar}>
        <Icons.Search />
      </div>
      <Search
        type="text"
        placeholder={t['placeholder.search']}
        value={searchTerm}
        onChange={e => searchBy(e.target.value)}
      />

      {updateStatus && (
        <>
          <StatusSelectButton onClick={onMenuClick} aria-haspopup="true">
            {status? t[status] : "Status"}
            <i css={cssSelectArrow}></i>
          </StatusSelectButton>
          <Menu
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={onMenuClose}
          >
            <MenuItem onClick={() => selectStatus("new")}>{t['new']}</MenuItem>
            <MenuItem onClick={() => selectStatus("active")}>{t['active']}</MenuItem>
            <MenuItem onClick={() => selectStatus("inactive")}>{t['inactive']}</MenuItem>
          </Menu>
        </>
      )}
    </div>
  )
}

const cssFilters = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 54px;
  margin-right: 10px;
  margin-bottom: 5px;
  position: relative;
  & input {
    height: 80%;
    ::placeholder {
      font-size: 16px;
    }
  }
`
const cssSelectArrow = css`
  border: solid ${colors.mediumDarkGrey};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 40px;
  margin-right: -30px;
  margin-bottom: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`
const StatusSelectButton = styled.button`
  min-width: 170px;
  margin-left: 15px;
  min-height: 36px;
  background-color: white;
  height: 80%;
  border: 2px solid ${colors.mediumGrey};
  border-radius: 0;
  text-align-last: center;
    cursor: pointer;

  &:hover{
    //border-bottom: none;
    border-radius: 0px;
  }
`
const cssSearchBar = css`
  display: flex;
  align-items: inherit;
  min-height: 36px;
  height: 80%;
  padding: 0px 10px;
  background: ${colors.searchBarGrey};
  & svg {
    font-size: 190%;
    color: ${colors.mediumDarkGrey};
  }
`
