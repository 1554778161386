/** @jsx jsx */
import MuiIconButton from '@material-ui/core/IconButton'
import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { colors } from 'shared/styles/colors'

export const ButtonPrimary = styled.button`
  background: ${colors.mainOrange};
  border: 1px solid ${colors.mainOrange};
  color: white;
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 2px;
  cursor: pointer;
  &[disabled] {
    background: white;
    color: ${colors.mainOrange};
    border-color: ${colors.mainOrange};
    cursor: default;
  }
`

export const ButtonDefault = styled.button`
  background: white;
  border: 1px solid #ccc;
  color: #666;
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 2px;
  cursor: pointer;
  &[disabled] {
    color: #ccc;
    cursor: default;
  }
`

export const IconButton = styled(MuiIconButton)`
  && {
    width: 40px;
    height: 40px;
    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    overflow: visible;
    font-size: 2.142857142857143rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    border: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    display: inline-flex;
    outline: none;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    -moz-appearance: none;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }
`

export const ModalEscapeButton = styled(Button)`
  && {
    background: transparent;
    border: 1px solid ${colors.mediumGrey};
    padding: 8px 20px;
    border-radius: 3px;
  }
`

export const ModalConfirmButton = styled(Button)`
  && {
    background: ${colors.mainOrange};
    color: white;
    text-decoration: none;
    padding: 8px 20px;
    border-radius: 3px;
    margin: 0px 15px;
  }
`

export const FooterButton = styled(IconButton)`
  && {
    width: auto;
    height: 50px;
    border-radius: 5%;
    font-size: 1rem;
    margin: 0 5px;
    padding: 0 5px;
    bottom: 0;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      transition: background-color 150ms;
      border-radius: 5%;
    }
  }
`
