import React from 'react'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'
import { Session } from 'shared/types/Session'
import { useSession } from 'shared/context/session'
import { CompanyLabel } from 'shared/views/labels/CompanyLabel'
import { PatientLabel } from 'shared/views/labels/PatientLabel'
import { PhysiotherapistLabel } from 'shared/views/labels/PhysiotherapistLabel'
import { t } from 'shared/translations'
import * as Cp from 'modules/company'
import * as Ft from 'modules/physiotherapist'
import * as Pt from 'modules/patient'
import * as SA from 'modules/superadmin'

export const AppBreadcrumbs: React.FC = () => {
  const { state } = useSession()
  const { session } = state

  if (!session) {
    return null
  }

  return (
    <React.Fragment>
      <SuperAdminBreadcrumb session={session} />
      <CompanyBreadcrumb session={session} />
      <PhysiotherapistBreadcrumb session={session} />
      <PatientBreadcrumb session={session} />
    </React.Fragment>
  )
}

const Subtitle = styled.h3`
  margin-left: 8px;
  font-size: 1rem;
  font-weight: 400;
  &:not(:first-of-type)::before {
    content: '⟩';
    font-size: 1rem;
    margin-right: 8px;
  }
`

const BreadcrumbLink = styled(NavLink)`
  color: #666;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const SuperAdminBreadcrumb: React.FC<{ session: Session }> = ({ session }) => {
  if (session.role === 'superadmin') {
    return (
      <Subtitle>
        <BreadcrumbLink to={SA.link[SA.entry]()}>{t['superadmin']}</BreadcrumbLink>
      </Subtitle>
    )
  }
  return null
}

const CompanyBreadcrumb: React.FC<{ session: Session }> = ({ session }) => {
  const params = Cp.useParams()

  if (session.role === 'company') {
    const id = session.userId
    return (
      <Subtitle>
        <BreadcrumbLink to={Cp.link[Cp.entry]({ companyId: id })}>
          <CompanyLabel id={id} />
        </BreadcrumbLink>
      </Subtitle>
    )
  }
  if (params && params.companyId) {
    const id = params.companyId
    return (
      <Subtitle>
        <BreadcrumbLink to={Cp.link[Cp.entry]({ companyId: id })}>
          <CompanyLabel id={id} />
        </BreadcrumbLink>
      </Subtitle>
    )
  }
  return null
}

const PhysiotherapistBreadcrumb: React.FC<{
  session: Session
}> = ({ session }) => {
  const params = Ft.useParams()

  if (session.role === 'physiotherapist') {
    const id = session.userId
    return (
      <Subtitle>
        <BreadcrumbLink to={Ft.link[Ft.entry]({ physiotherapistId: id })}>
          <PhysiotherapistLabel id={id} />
        </BreadcrumbLink>
      </Subtitle>
    )
  }
  if (params && params.physiotherapistId) {
    const id = params.physiotherapistId
    return (
      <Subtitle>
        <BreadcrumbLink to={Ft.link[Ft.entry]({ physiotherapistId: id })}>
          <PhysiotherapistLabel id={id} />
        </BreadcrumbLink>
      </Subtitle>
    )
  }
  return null
}

const PatientBreadcrumb: React.FC<{ session: Session }> = ({ session }) => {
  const params = Pt.useParams()

  if (session.role === 'patient') {
    const id = session.userId
    return (
      <Subtitle>
        <BreadcrumbLink to={Pt.link[Pt.entry]({ patientId: id })}>
          <PatientLabel id={id} />
        </BreadcrumbLink>
      </Subtitle>
    )
  }
  if (params && params.patientId) {
    const id = params.patientId
    return (
      <Subtitle>
        <BreadcrumbLink to={Pt.link[Pt.entry]({ patientId: id })}>
          <PatientLabel id={id} />
        </BreadcrumbLink>
      </Subtitle>
    )
  }
  return null
}
