import React from 'react'
import * as Ft from 'shared/data/physiotherapist'
import { ListProps } from 'shared/types/List'
import { Physiotherapist } from 'shared/types/Physiotherapist'
import { Column } from 'shared/types/Table'
import { Wrapper } from 'shared/components/Wrapper'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { StatusText } from 'shared/views/text/StatusText'
import { CompanyLabel } from 'shared/views/labels/CompanyLabel'
import { createScrollHandler } from 'shared/utils/scroll'
import { t } from 'shared/translations'
import { PhysiotherapistsTable } from './PhysiotherapistsTable'

export const PhysiotherapistsList: React.FC<ListProps<Physiotherapist>> = ({
  state,
  order,
  loadNext,
  sortBy,
  rowLink,
  renderActions,
}) => {
  const { data, meta } = state
  const [[key, value = 'asc']] = Object.entries(order || Ft.defaultOrder)

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const handleScroll = createScrollHandler({ data, meta, loadNext })

  const columns = renderActions
    ? [...dataColumns, { ...actionColumn, cellRenderer: renderActions }]
    : dataColumns

  return (
    <Wrapper>
      <PhysiotherapistsTable
        columns={columns}
        rowCount={data.length}
        rowGetter={row => data[row.index]}
        rowLink={rowLink}
        handleScroll={handleScroll}
        handleRequestSort={sortBy}
        orderKey={key as keyof Physiotherapist}
        orderValue={value}
      />
    </Wrapper>
  )
}

const dataColumns: Column<Physiotherapist>[] = [
  { id: 'firstName', label: t['property.firstName'], width: 200, minWidth: 100 },
  { id: 'lastName', label: t['property.lastName'], width: 200, minWidth: 100 },
  {
    id: 'state',
    label: t['property.state'],
    width: 100,
    minWidth: 80,
    cellRenderer: props => {
      const { state } = props.rowData as Physiotherapist
      return <StatusText status={state} />
    },
  },
  { id: 'phone', label: t['property.phone'], width: 200, minWidth: 100 },
  { id: 'email', label: t['property.email'], width: 300, minWidth: 120 },
  {
    id: 'company',
    label: t['property.company'],
    width: 250,
    minWidth: 120,
    cellRenderer: props => {
      const { company } = props.rowData as Physiotherapist
      return <CompanyLabel id={company} />
    },
  },
]

const actionColumn: Column<Physiotherapist> = {
  id: 'actions',
  label: '',
  width: 400,
  minWidth: 100,
}
