import React from 'react'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { FormHeader } from 'shared/views/headers/FormHeader'
import { link } from 'modules/superadmin/routes'

export const SuperAdminProfile: React.FC = () => {
  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <FormHeader back={link['/superadmin']()} title={'Profil'} />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
