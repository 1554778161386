/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { NavLink } from 'react-router-dom'
import { colors } from 'shared/styles/colors'

type TabsProps = {
  tabs: { label: string; to: string }[]
}

export const Tabs: React.FC<TabsProps> = ({ tabs }) => {
  return (
    <nav css={cssTabs}>
      {tabs.map((t, i) => (
        <NavLink key={i} to={t.to} css={cssTab} activeStyle={tabActiveStyle}>
          {t.label}
        </NavLink>
      ))}
    </nav>
  )
}

const cssTabs = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 16px 16px;
  height: 40px;
  border-bottom: 1px solid ${colors.lightGrey};
`
const cssTab = css`
  display: flex;
  text-decoration: none;
  padding: 0 20px;
  margin-top: 1px;
  height: 40px;
  align-items: center;
  color: #999;
`
const tabActiveStyle = {
  color: 'inherit',
  // fontWeight: 'bold',
  borderBottom: `2px solid ${colors.mainOrange}`,
} as const
