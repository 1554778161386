/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { SvgIconProps } from '@material-ui/core/SvgIcon'

type AdditionalInfoProps = {
  icon: React.ComponentType<SvgIconProps>
  text: string
}

export const AdditionalInfo: React.FC<AdditionalInfoProps> = ({ icon: Icon, text }) => {
  return (
    <div css={s.AdditionalInfo}>
      <Icon css={s.icon} />
      <div css={s.text}>{text}</div>
    </div>
  )
}

const s = {
  AdditionalInfo: css`
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1rem;
    height: 20px;
  `,
  icon: css`
    margin-right: 4px;
    font-size: 1.3rem !important;
    color: #999 !important;
  `,
  text: css`
    color: inherit;
    font-size: 1rem;
  `,
}
