import React from 'react'
import { FieldProps } from 'formik'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import MaterialTextField, { StandardTextFieldProps } from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputMask from 'react-input-mask'
import { FieldState, FieldVariant } from 'shared/types/Form/FieldState'
import { Icons } from 'shared/components/Icons'

interface TextFieldProps extends FieldProps, StandardTextFieldProps {
  state: FieldState
  mask?: string
}

export const TextField: React.FC<TextFieldProps> = ({
  field,
  form,
  state,
  mask,
  ...inputProps
}) => {
  const classes = useTextFieldStyles()
  const { pending, variant, helperText } = state
  const variantProps = textFieldVariant(classes, variant)

  return mask ? (
    <InputMask mask={mask} maskChar={''} disabled={pending} {...field}>
      {(props: any) => {
        return (
          <MaterialTextField
            fullWidth={true}
            disabled={pending}
            helperText={helperText}
            {...variantProps}
            {...inputProps}
            {...props}
          />
        )
      }}
    </InputMask>
  ) : (
    <MaterialTextField
      fullWidth={true}
      disabled={pending}
      helperText={helperText}
      {...field}
      {...variantProps}
      {...inputProps}
    />
  )
}

const textFieldVariant = (classes: Classes, fieldVariant?: FieldVariant) => {
  switch (fieldVariant) {
    case 'success':
      return {
        className: classes.textField_success,
        InputProps: {
          endAdornment: (
            <InputAdornment className={classes.good} position="end" variant="outlined">
              <Icons.Done />
            </InputAdornment>
          ),
        },
      }
    case 'error':
      return {
        className: classes.textField_error,
        InputProps: {
          endAdornment: (
            <InputAdornment className={classes.error} position="end" variant="outlined">
              <Icons.Error />
            </InputAdornment>
          ),
        },
      }
    case 'edited':
      return {
        className: classes.textField_edited,
        InputProps: {
          endAdornment: (
            <InputAdornment className={classes.edited} position="end" variant="outlined">
              <Icons.EditedForm />
            </InputAdornment>
          ),
        },
      }
    default:
      return {
        className: classes.textField,
      }
  }
}

type Classes = Record<
  | 'textField'
  | 'textField_success'
  | 'textField_edited'
  | 'textField_error'
  | 'error'
  | 'edited'
  | 'good',
  string
>

const useTextFieldStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginBottom: theme.spacing(2),
    },
    textField_success: {
      marginBottom: theme.spacing(2),
      '& label': {
        color: 'green',
      },
      '& label.Mui-focused': {
        color: 'green',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'green',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'green',
      },
    },
    textField_edited: {
      marginBottom: theme.spacing(2),
      '& label': {
        color: 'orange',
      },
      '& label.Mui-focused': {
        color: 'orange',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'orange',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'orange',
      },
    },
    textField_error: {
      marginBottom: theme.spacing(2),
      '& label': {
        color: 'red',
      },
      '& label.Mui-focused': {
        color: 'red',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'red',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'red',
      },
      '& .MuiFormHelperText-root': {
        color: 'red',
      },
    },
    error: {
      color: 'red',
    },
    edited: {
      color: 'orange',
    },
    good: {
      color: 'green',
    },
  })
)
