import React from 'react'
import * as Api from 'shared/api'
import * as Ex from 'shared/data/exercise'
import { UUID } from 'shared/types/UUID'
import { Exercise } from 'shared/types/Exercise'
import { GetLabel, Fetch } from 'shared/types/Label'
import { RemoteLabel } from 'shared/components/RemoteLabel'

type ExerciseLabelProps = {
  id: UUID
}

const getLabel: GetLabel<Exercise> = ex => ex.name
const fetch: Fetch<Exercise> = id => Api.fetchExercise(id).then(res => res.exercise)

export const ExerciseLabel: React.FC<ExerciseLabelProps> = ({ id }) => {
  const cache = Ex.useCachedExercises()
  return <RemoteLabel id={id} getLabel={getLabel} fetch={fetch} cache={cache} />
}
