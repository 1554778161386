import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Cp from 'shared/data/company'
import { useToasts } from 'shared/context/toasts'
import { CompanyInput } from 'shared/types/Company'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { FormFooter } from 'shared/components/FormFooter'
import { FormHeader } from 'shared/views/headers/FormHeader'
import { CompanyProfileForm } from 'shared/views/CompanyProfileForm'
import { createEditFormFieldValidator } from 'shared/utils/validation'
import { Params, link } from 'modules/company/routes'

export const CompanyProfile: React.FC = () => {
  const { match } = useRouter<Params>()
  const toasts = useToasts().actions
  const { state, entity } = Cp.useEntityReducer()
  const { companyId } = match.params
  const { data } = state
  const backToEntry = link['/company/:companyId']({ companyId: match.params.companyId })

  useEffect(() => {
    let cancelled = false
    if (companyId) {
      entity.loading()
      Api.fetchCompany(companyId)
        .then(res => !cancelled && entity.success(res.company))
        .catch(err => !cancelled && entity.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [entity, companyId])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  const save = (input: CompanyInput) => {
    entity.loading()
    Api.updateCompany(Cp.payloadUpdate(data, input))
      .then(res => {
        toasts.success(`Zapisano zmiany`)
        entity.success(res.company)
      })
      .catch(err => {
        toasts.error(`Nie udało się zapisać zmian`)
        entity.failure(err)
      })
  }

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <FormHeader back={backToEntry} title={'Profil'} />
        <CompanyProfileForm
          createFieldValidator={createEditFormFieldValidator}
          state={state}
          save={save}
          headerActions={[]}
          footerActions={[]}
        />
        <FormFooter />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
