import React, { useContext, useMemo, useReducer } from 'react'

// --- GLOBAL LAYOUT CONTEXT ---

type LayoutState = {
  hasOpenPanel: boolean
  hasOpenSidebar: boolean
}

type LayoutAction = { type: 'TOGGLE_PANEL' } | { type: 'TOGGLE_SIDEBAR' }

type LayoutActions = {
  togglePanel: () => void
  toggleSidebar: () => void
}

type LayoutRedux = {
  state: LayoutState
  dispatch: React.Dispatch<LayoutAction>
}

const initialState: LayoutState = {
  hasOpenPanel: false,
  hasOpenSidebar: true,
}

const reducer: React.Reducer<LayoutState, LayoutAction> = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_PANEL':
      return { ...state, hasOpenPanel: !state.hasOpenPanel }
    case 'TOGGLE_SIDEBAR':
      return { ...state, hasOpenSidebar: !state.hasOpenSidebar }
    default:
      return state
  }
}

/**
 * Current state of application layout lives here
 */
const LayoutContext: React.Context<LayoutRedux> = React.createContext({
  state: initialState,
} as LayoutRedux)

// --- PROVIDE LAYOUT ---

export const LayoutProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  return <LayoutContext.Provider value={{ state, dispatch }}>{children}</LayoutContext.Provider>
}

// --- USE LAYOUT

export const useLayout = () => {
  const { state, dispatch } = useContext(LayoutContext)

  // ~~~ Note ~~~
  // Actions just need a dispatcher to work, they themselves are not going to change.
  // We memoize them to make sure they will not be recalculated on every state change.
  // That would risk running into infinite loops during rendering.
  const actions: LayoutActions = useMemo(
    () => ({
      togglePanel: () =>
        dispatch({
          type: 'TOGGLE_PANEL',
        }),
      toggleSidebar: () =>
        dispatch({
          type: 'TOGGLE_SIDEBAR',
        }),
    }),
    [dispatch]
  )

  return {
    state,
    actions,
  }
}
