import React from 'react'
import * as Api from 'shared/api'
import { UUID } from 'shared/types/UUID'
import { Company } from 'shared/types/Company'
import { GetLabel, Fetch } from 'shared/types/Label'
import { RemoteLabel } from 'shared/components/RemoteLabel'

type CompanyLabelProps = {
  id: UUID
}

const getLabel: GetLabel<Company> = cp => cp.name
const fetch: Fetch<Company> = id => Api.fetchCompany(id).then(res => res.company)

export const CompanyLabel: React.FC<CompanyLabelProps> = ({ id }) => {
  return <RemoteLabel id={id} getLabel={getLabel} fetch={fetch} />
}
