import React, { useEffect } from 'react'
import * as Api from 'shared/api'
import * as Cp from 'shared/data/company'
import { SelectEntity, SelectEntityProps } from 'shared/components/Select'
import { Company } from 'shared/types/Company'
import { UUID } from 'shared/types/UUID'

const Select: React.FC<SelectEntityProps<Company>> = SelectEntity

type CompaniesSelectProps = {
  company?: UUID
  selectCompany: (id?: UUID) => void
}

export const CompaniesSelect: React.FC<CompaniesSelectProps> = ({ company, selectCompany }) => {
  const { state, collection } = Cp.useCollectionReducer()
  const { data } = state

  useEffect(() => {
    let cancelled = false
    collection.loading()
    Api.fetchCompanies({ states: ['new', 'active'] })
      .then(res => !cancelled && collection.success(res.companies, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = true
    }
  }, [collection])

  if (!data) {
    return <div>...</div>
  }

  return (
    <Select options={data} getOptionLabel={c => c.name} value={company} onChange={selectCompany} />
  )
}
