import React from 'react'
import { RemoteEntity } from 'shared/types/RemoteEntity'
import { RemoteCollection } from 'shared/types/RemoteCollection'
import { Loading } from 'shared/components/Loading'
import { Problem } from 'shared/components/Problem'

export type RemoteLoaderProps = {
  state: RemoteEntity | RemoteCollection
}

/**
 * Define what should be rendered when trying to load remote resource and we didn't get it yet.
 * This should cover 3 of possible states of *RemoteEntity* and/or *RemoteCollection*
 * - **intial** - loading not even started, probably best to not display anything?
 * - **loading** - display a loader
 * - **failure** - display error message
 * In any other cases we also shouldn't render anything
 */
export const RemoteLoader: React.FC<RemoteLoaderProps> = ({ state }) => {
  if (!state.data) {
    if (state.pending) {
      return <Loading />
    }
    if (state.error) {
      return <Problem error={state.error} />
    }
  }
  return null
}
