import debounce from 'lodash/debounce'
import { Meta } from 'shared/types/Meta'

type ScrollHandlerConfig = {
  data: object[]
  meta: Meta
  loadNext: () => void
}

export const createScrollHandler = ({ data, meta, loadNext }: ScrollHandlerConfig) =>
  debounce((element: HTMLElement) => {
    if (data.length === meta.total) {
      // Nothing more to load
      return false
    }

    const fixedScrollTopValue = Number(element.scrollTop.toFixed())
    if (element) {
      const bottom = element.scrollHeight - fixedScrollTopValue === element.clientHeight
      if (bottom) {
        loadNext()
      }
    }
  }, debounceTimeout)

const debounceTimeout = 500
