import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { path } from 'modules/patient/routes'
import { PatientSets } from './Sets'
import { PatientSet } from './Set'

export const PatientSetsRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={path['/patient/:patientId/sets']} component={PatientSets} />
      <Route path={path['/patient/:patientId/sets/:setId']} component={PatientSet} />
    </Switch>
  )
}
