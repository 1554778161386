type Currency = 'PLN'

export const formatCurrency = (amount: string, currency: Currency = 'PLN'): string => {
  switch (currency) {
    case 'PLN':
    default: {
      return `${amount.replace('.', ',')} zł`
    }
  }
}
