import { Session } from 'shared/types/Session'

export const checkAccess = (session?: Session): boolean => {
  switch (session && session.role) {
    case 'superadmin':
    case 'patient':
      return true
    default:
      return false
  }
}
