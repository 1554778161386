/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import * as Api from 'shared/api'
import * as SetEx from 'shared/data/setOfExercises'
import { SetOfExercisesByDay, SetOfExercises } from 'shared/types/SetOfExercises'
import { SetOfExercisesStatistics } from 'shared/types/SetOfExercisesStatistics'
import * as Layout from 'shared/components/Layout'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { H4 } from 'shared/components/Heading'
import { AdditionalInfo } from 'shared/components/AdditionalInfo'
import { Icons } from 'shared/components/Icons'
import { ExerciseStatisticsView } from 'shared/views/ExerciseStatisticsView'
import { SetOfExercisesTag } from 'shared/views/tags/SetOfExercisesTag'
import { toDateLabel, getEndDay } from 'shared/utils/date'
import { formatDuration } from 'shared/utils/time'
import { t } from 'shared/translations'

type SetOfExercisesByDayResultProps = {
  setOfExercisesByDay: SetOfExercisesByDay
}

export const SetOfExercisesByDayResult: React.FC<SetOfExercisesByDayResultProps> = ({
  setOfExercisesByDay,
}) => {
  const { state, entity } = SetEx.useEntityReducer()
  const [statistics, setStatistics] = useState<SetOfExercisesStatistics>()
  const { id, setOfExercisesStatistics } = setOfExercisesByDay
  const { data } = state

  // Load full details about this exercise set.
  // Things like full names, exercise parameters, statistics etc. are missing from `SetOfExercisesByDay`
  useEffect(() => {
    let cancelled = false
    entity.loading()
    Api.fetchSetOfExercises(id)
      .then(res => !cancelled && entity.success(res.setOfExercises))
      .catch(err => !cancelled && entity.failure(err))
    return () => {
      cancelled = true
    }
  }, [entity, id])

  // Load exercise set statistics
  useEffect(() => {
    let cancelled = false
    if (setOfExercisesStatistics) {
      Api.fetchSetOfExercisesStatistics(setOfExercisesStatistics)
        .then(res => !cancelled && setStatistics(res.setOfExercisesStatistics))
        .catch(() => !cancelled && setStatistics(undefined))
    }
    return () => {
      cancelled = true
    }
  }, [setOfExercisesStatistics])

  if (!data) {
    return <RemoteLoader state={state} />
  }

  return (
    <React.Fragment>
      <Layout.DetailsHeader css={cssHeader}>
        <H4>
          {setOfExercisesByDay.name} {t['date.fromDay']} {toDateLabel(setOfExercisesByDay.date)}
        </H4>
        <div>
          <AdditionalInfo icon={Icons.Assignment} text={t['info.set'](data.number)} />
          <AdditionalInfo
            icon={Icons.Person}
            text={t['info.author'](data.physiotherapistFullName)}
          />
          <AdditionalInfo
            icon={Icons.Calendar}
            text={t['info.activeDateRange'](
              toDateLabel(data.dateStart),
              toDateLabel(getEndDay(data.dateStart, data.duration))
            )}
          />
        </div>
        <aside>
          {statistics && (
            <React.Fragment>
              <div>
                <SetOfExercisesTag status={statistics.state} />
              </div>
              <SetOfExerciseByDaySummary statistics={statistics} setOfExercises={data} />
            </React.Fragment>
          )}
        </aside>
      </Layout.DetailsHeader>
      <Layout.DetailsSection css={cssExercises}>
        {data.exercisesParameters.map((parameters, index) => (
          <ExerciseStatisticsView
            key={index}
            exercisesParameters={parameters}
            exerciseStatistic={
              statistics &&
              statistics.exercisesStatistics.find(exStat => exStat.exercise === parameters.exercise)
            }
          />
        ))}
      </Layout.DetailsSection>
    </React.Fragment>
  )
}

type SetOfExercisesByDaySummaryProps = {
  statistics: SetOfExercisesStatistics
  setOfExercises: SetOfExercises
}

const SetOfExerciseByDaySummary: React.FC<SetOfExercisesByDaySummaryProps> = ({
  statistics,
  setOfExercises,
}) => {
  const totalSetRepetitions = setOfExercises.exercisesParameters.reduce((sum, xp) => {
    return sum + (xp.seriesCount || 0) * (xp.repetitionsPerSeries || 0)
  }, 0)

  return (
    <div css={cssSummary}>
      <H4>{t['title.summary']}</H4>
      <div css={cssSummaryStatistics}>
        <AdditionalInfo icon={Icons.Timelapse} text={`Wykonano na ${statistics.progress}%`} />
        <AdditionalInfo
          icon={Icons.Time}
          text={`Łączny czas ${formatDuration(statistics.duration)}`}
        />
        <AdditionalInfo
          icon={Icons.Refresh}
          text={`Łącznie ${statistics.repetitionsCompleted}/${totalSetRepetitions} powtórzeń`}
        />
      </div>
    </div>
  )
}

const cssHeader = css`
  position: relative;
  display: flex;
  flex-direction: column;
  & > h4 {
    min-height: 36px;
    font-size: 1.7rem;
    font-weight: 400;
  }
  & > div:nth-of-type(1) {
    padding: 12px 0 16px;
  }
  & > aside {
    position: absolute;
    top: 0;
    right: 16px;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
  }
`
const cssExercises = css`
  display: flex;
  flex-direction: column;
`
const cssSummary = css`
  margin-left: 16px;
`
const cssSummaryStatistics = css`
  margin-top: 24px;
`
