import React, { useEffect } from 'react'
import * as S from 'shared/data/session'
import * as Cns from 'shared/data/connections'
import { eventSourceMyChatUrl } from 'shared/serverEvents'
import { useSession } from 'shared/context/session'
import { useConnections } from 'shared/context/connections'
import { isConnectionEvent } from 'shared/types/ConnectionEvent'
import { VideoChat } from 'shared/components/VideoChat'
import { PopupIncomingConnection } from 'shared/views/popups/PopupIncomingConnection'
import { PopupFinishedConnection } from 'shared/views/popups/PopupFinishedConnection'

export const AppConnections: React.FC = () => {
  const { state, actions } = useConnections()
  const session = useSession()
  const me = S.selectUserId(session.state)
  const role = S.selectRole(session.state)

  useEffect(() => {
    let es: EventSource
    if (me && Cns.canReceiveCalls(role)) {
      const incomingCallsUrl = eventSourceMyChatUrl(me)

      es = new EventSource(incomingCallsUrl)

      es.onopen = () => {}
      es.onerror = () => {}

      es.onmessage = evt => {
        const message = JSON.parse(evt.data)
        console.log('Connection message', message)
        if (isConnectionEvent(message)) {
          switch (message.status) {
            case 'connection_start': {
              // Server notifies about `connection_start` events when:
              // - someone is calling us
              // - we are calling someone
              return actions.connectionStart(message.caller)
            }
            case 'connection_end': {
              // Server notifies about `connection_end` events when:
              // - someone hangs up a call (incoming or active)
              // - we hang up a call (incoming or active)
              return actions.connectionEnd(message.caller)
            }
          }
        } else {
          console.warn(`--- Unexpected event status ---
          Received unexpected event status from server EventSource
          EventSource url: ${es.url}
          Received status: ${evt.data.status}
          Expected status: "connection_start", "connection_end"
          `)
        }
      }

      console.log('Listen for connections at', incomingCallsUrl)
    }

    return () => {
      console.log('Stop listening for connections')
      es && es.close()
    }
  }, [actions, me, role])

  // Make sure user is authenticated before displaying any kind of connections
  if (!me) {
    return null
  }

  // It's possible (though very unlikely) that user will receive 2+ incoming connections at once.
  // In that case, to keep it simple, let's just display the first one, whichever arrived first.
  return (
    <React.Fragment>
      {state.activeConnection && <VideoChat me={me} {...state.activeConnection} />}
      {state.incomingConnections.length > 0 && (
        <PopupIncomingConnection me={me} caller={state.incomingConnections[0]} />
      )}
      {!state.activeConnection && state.finishedConnection && (
        <PopupFinishedConnection caller={state.finishedConnection} />
      )}
    </React.Fragment>
  )
}
