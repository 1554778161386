import React from 'react'
import * as SA from 'modules/superadmin'
import * as Cp from 'modules/company'
import * as Ft from 'modules/physiotherapist'
import * as Pt from 'modules/patient'

export const AppPages: React.FC = () => {
  return (
    <React.Fragment>
      <SA.Module />
      <Cp.Module />
      <Ft.Module />
      <Pt.Module />
    </React.Fragment>
  )
}
