import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'

export const useTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexContainer: {
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
    },
    tableRow: {
      cursor: 'pointer',
      borderBottom: `1px solid rgba(224, 224, 224, 1)`,
    },
    tableRowHover: {
      '&:hover': {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCell: {
      border: 'unset',
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
    },
    noClick: {
      cursor: 'initial',
    },
  })
)
