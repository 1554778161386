/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React, { useEffect } from 'react'
import * as Api from 'shared/api'
import * as Ex from 'shared/data/exercise'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { ListHeader } from 'shared/components/ListHeader'
import { ListFilters } from 'shared/components/ListFilters'
import { ExercisesList } from 'shared/views/ExercisesList'
import { t } from 'shared/translations'

export const PhysiotherapistExercises: React.FC = () => {
  const { state, collection } = Ex.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy } = Ex.useQueries()

  useEffect(() => {
    let cancelled = false
    collection.loading()
    Api.fetchExercises(query)
      .then(res => !cancelled && collection.success(res.exercises, res.meta))
      .catch(err => !cancelled && collection.failure(err))
    return () => {
      cancelled = true
    }
  }, [collection, query])

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <ListHeader title={t['title.exercises']} />
        <div css={s.hint}>{t['exercises.hint']}</div>
        <ListFilters searchTerm={query.searchTerm || ''} searchBy={searchBy} />
        <ExercisesList state={state} order={query.order} loadNext={loadNext} sortBy={sortBy} />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}

// TODO should we get this hint out of here?
const s = {
  hint: css`
    padding: 0 16px;
    font-size: 0.75rem;
    color: #666;
  `,
}
