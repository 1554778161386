/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import Grid from '@material-ui/core/Grid'
import MaterialTextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Field, FieldProps, FieldArray } from 'formik'
import * as SetEx from 'shared/data/setOfExercises'
import * as Pt from 'shared/data/patient'
import { FormProps, FormFieldsProps } from 'shared/types/Form'
import { Template } from 'shared/types/Template'
import { SetOfExercises, SetOfExercisesInput } from 'shared/types/SetOfExercises'
import { FormStepHeader } from 'shared/components/FormSectionHeader'
import { ButtonPrimary } from 'shared/components/Button'
import { DateField } from 'shared/components/FormField/DateField'
import { TextField } from 'shared/components/FormField/TextField'
import { Icons } from 'shared/components/Icons'
import { ActionSelectTemplate } from 'shared/views/actions/ActionSelectTemplate'
import { ActionSelectExistingSet } from 'shared/views/actions/ActionSelectExistingSet'
import { SetOfExercisesCalendar } from 'shared/views/SetOfExercisesCalendar'
import { SetOfExercisesParameters } from 'shared/views/SetOfExercisesParameters'
import { t } from 'shared/translations'
import { toNearbyDayLabel, isPastDay } from 'shared/utils/date'
import { useFormStyles } from 'shared/styles/form'
import { colors } from 'shared/styles/colors'
import { createForm } from 'shared/components/Form'
import useRouter from 'use-react-router'

type RouteParams = {
  setId: string;
};

const SetOfExercisesFormFields: React.FC<FormFieldsProps<SetOfExercisesInput>> = ({
  formik,
  fieldValidator,
}) => {
  const classes = useFormStyles()
  const patient = Pt.useCachedPatient()
  const router = useRouter<RouteParams>();
  const { setId } = router.match.params;
  const reset = () => {
    formik.resetForm()
  }

  const onSelectTemplate = (template: Template) => {
    formik.setValues({
      ...formik.values,
      name: formik.values.name || template.name, // Preserve existing name if there is one, use template name as fallback
      duration: template.duration || 1,
      everyNumberDay: template.everyNumberDay || 1,
      onceDay: template.onceDay || 1,
      custom: template.custom,
      exercisesParameters: template.templateExercisesParameters,
    })
  }

  const onSelectExistingSet = (setEx: SetOfExercises) => {
    formik.setValues({
      ...formik.values,
      name: formik.values.name || setEx.name, // Preserve existing name if there is one, use set name as fallback
      duration: setEx.duration || 1,
      everyNumberDay: setEx.everyNumberDay || 1,
      onceDay: setEx.onceDay || 1,
      custom: setEx.custom,
      exercisesParameters: setEx.exercisesParameters,
    })
  }

  const onSelectStartDate = (dateStart: Date | null) => {
    if (dateStart) {
      formik.setFieldValue('dateStart', dateStart)
      formik.setFieldValue(
        'frequencies',
        SetEx.selectOcurrencesWithinRange({ ...formik.values, dateStart })
      )
    }
  }

  const onBlurDuration = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const duration = Number(evt.target.value)
    if (duration > 0) {
      formik.setFieldValue('duration', duration)
      formik.setFieldValue(
        'frequencies',
        SetEx.selectOcurrencesWithinRange({ ...formik.values, duration })
      )
    }
  }

  const onBlurOnceDay = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const onceDay = Number(evt.target.value)
    if (onceDay > 0) {
      formik.setFieldValue('onceDay', onceDay)
      formik.setFieldValue(
        'frequencies',
        SetEx.selectOcurrencesWithinRange({ ...formik.values, onceDay })
      )
    }
  }

  return (
    <Grid container direction="column" className={classes.padding}>
      <div css={cssSection}>
        <FormStepHeader icon={1} title={t['property.sourceSet']} />
        <ButtonPrimary type="button" onClick={reset} disabled={true}>
          {t['property.newFromScratch']}
        </ButtonPrimary>
        { !setId ?
          <React.Fragment>
          <ActionSelectTemplate onSelect={onSelectTemplate} />
          <ActionSelectExistingSet onSelect={onSelectExistingSet} />
          </React.Fragment>
          : null
        }
      </div>
      <div css={cssSection}>
        <FormStepHeader icon={2} title={t['property.schedule']} />
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <Field
                name="dateStart"
                render={(props: FieldProps) => {
                  const isPastDateStart = isPastDay(props.field.value)
                  return (
                    <DateField
                      field={props.field}
                      form={props.form}
                      value={props.field.value}
                      onChange={onSelectStartDate}
                      state={fieldValidator(props)}
                      label={t['property.dateStart']}
                      required={true}
                      disabled={isPastDateStart}
                      disablePast={!isPastDateStart}
                      autoOk
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start" css={cssAdornment}>
                            <Icons.TimeStart />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end" css={cssAdornment}>
                            {toNearbyDayLabel(props.field.value)}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            <div>
              <Field
                name="duration"
                render={(props: FieldProps) => (
                  <TextField
                    field={props.field}
                    form={props.form}
                    onBlur={onBlurDuration}
                    state={fieldValidator(props)}
                    label={t['property.duration']}
                    type="number"
                    required={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" css={cssAdornment}>
                          <Icons.TimeEnd />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" css={cssAdornment}>
                          {t['unit.week(s)'](props.field.value)}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Field
                name="everyNumberDay"
                render={(props: FieldProps) => (
                  <TextField
                    field={props.field}
                    form={props.form}
                    onBlur={onBlurOnceDay}
                    state={fieldValidator(props)}
                    label={t['property.everyNumberDay']}
                    type="number"
                    required={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" css={cssAdornment}>
                          <Icons.Calendar />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" css={cssAdornment}>
                          {t['unit.interval'](props.field.value)}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Field
                name="onceDay"
                render={(props: FieldProps) => (
                  <TextField
                    field={props.field}
                    form={props.form}
                    onBlur={onBlurOnceDay}
                    state={fieldValidator(props)}
                    label={t['property.onceDay']}
                    type="number"
                    required={true}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" css={cssAdornment}>
                          <Icons.Refresh />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end" css={cssAdornment}>
                          {t['unit.times(s)PerDay'](props.field.value)}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <div>
              <Field
                name="frequencies"
                render={(props: FieldProps) => (
                  <SetOfExercisesCalendar
                    field={props.field}
                    form={props.form}
                    state={fieldValidator(props)}
                    setOfExercises={formik.values}
                  />
                )}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <div css={cssSection}>
        <FormStepHeader icon={3} title={t['property.exercisesSet']} />
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Field
              name="name"
              render={(props: FieldProps) => (
                <TextField
                  field={props.field}
                  form={props.form}
                  state={fieldValidator(props)}
                  label={t['property.setName']}
                  required={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <MaterialTextField
              fullWidth
              value={patient.diagnosis || ''}
              label={t['property.recognition']}
              InputProps={{ readOnly: true }}
              disabled={true}
            />
          </Grid>
        </Grid>
      </div>
      <FieldArray
        name="exercisesParameters"
        render={(arrayHelpers: any) => <SetOfExercisesParameters {...arrayHelpers} />}
      />
    </Grid>
  )
}

export const SetOfExercisesForm: React.FC<FormProps<
  SetOfExercises,
  SetOfExercisesInput
>> = createForm({
  validation: SetEx.validation,
  FormFields: SetOfExercisesFormFields,
})

const cssSection = css`
  width: 100%;
  &:not(:first-of-type) {
    margin-top: 40px;
  }
  & > button:not(:first-of-type) {
    margin-left: 16px;
  }
`
const cssAdornment = css`
  color: ${colors.adornmentGrey};
  white-space: nowrap;
`
