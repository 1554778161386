/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import Slide from '@material-ui/core/Slide'
import { useLayout } from 'shared/context/layout'
import * as SA from 'modules/superadmin'
import * as Cp from 'modules/company'
import * as Ft from 'modules/physiotherapist'
import * as Pt from 'modules/patient'

export const AppSidebar: React.FC = () => {
  const { state } = useLayout()

  return state.hasOpenSidebar ? (
    <Slide direction="right" timeout={240} in={state.hasOpenSidebar}>
      <aside css={cssAside}>
        <SA.Sidebar />
        <Cp.Sidebar />
        <Ft.Sidebar />
        <Pt.Sidebar />
      </aside>
    </Slide>
  ) : null
}

const cssAside = css`
  position: relative;
  width: 280px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: white;
`
