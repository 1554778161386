/** @jsx jsx */
import React, { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { Caller } from 'shared/types/Caller'
import { useInterval } from 'shared/hooks/useInterval'
import { formatTimer } from 'shared/utils/time'
import { t } from 'shared/translations'

type ConnectionHeaderProps = {
  participant: Caller
  connected?: boolean
  incoming?: boolean
  finished?: boolean
}

export const ConnectionHeader: React.FC<ConnectionHeaderProps> = ({
  participant,
  connected,
  incoming,
  finished,
}) => {
  let title: string
  if (typeof connected === 'boolean') {
    title = connected ? t['connection.conversation'] : t['connection.error']
  } else {
    title = incoming ? t['connection.calling'] : t['connection.connecting']
  }
  if (finished) {
    title = t['connection.finished']
  }

  return (
    <div css={cssHeader(connected)}>
      <div css={cssCallStatus}>{title}</div>
      <div css={cssCallerName}>
        {participant.firstName} {participant.lastName}
      </div>
      {connected && <ConnectionTimer />}
    </div>
  )
}

export const ConnectionTimer: React.FC = () => {
  const [timer, setTimer] = useState(0)

  useInterval(() => {
    setTimer(timer + 1)
  }, 1000)

  return <div>{formatTimer(timer)}</div>
}

const cssHeader = (connected?: boolean) => css`
  color: ${connected === true ? 'white' : '#666'};
`

const cssCallStatus = css`
  margin: 0;
  font-weight: 400;
`

const cssCallerName = css`
  font-size: 1.17rem;
  font-weight: 500;
  margin: 8px 0;
`
