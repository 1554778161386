/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import React from 'react'
import { Session } from 'shared/types/Session'
import { Settlement } from 'shared/types/Settlement'
import { Invoice } from 'shared/types/Invoice'
import { GooglePayButton } from 'shared/components/GooglePayButton'
import { Authorize } from 'Auth/Authorize'

type CompanyPaymentOptionsProps = {
  checkAccess: (session?: Session) => boolean
  settlement: Settlement
  invoice: Invoice
}

export const CompanyPaymentOptions: React.FC<CompanyPaymentOptionsProps> = ({
  checkAccess,
  settlement,
  invoice,
}) => {
  if (settlement.state !== 'not_paid') {
    return null
  }

  return (
    <Authorize checkAccess={checkAccess}>
      <div css={cssPaymentOptions}>
        <GooglePayButton settlement={settlement} invoice={invoice} />
      </div>
    </Authorize>
  )
}

const cssPaymentOptions = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px;
`
