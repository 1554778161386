import { parseISO, startOfDay } from 'date-fns'
import { UTCDateTime } from 'shared/types/UTC'
import { formatUTCDateTime } from 'shared/utils/date'

/**
 * Convert raw form value to a type that can be sent to the server
 * @example new Date(2020,...) --> '2020-10-30T00:00:00+00:00'
 */
export const normalizeDateTime = (date: Date): UTCDateTime => {
  return formatUTCDateTime(startOfDay(date))
}

/**
 * Convert JSON value received from the server to raw form value
 * @example '2020-10-30T00:00:00+00:00' --> new Date(2020,...)
 */
export const denormalizeDateTime = (date: UTCDateTime): Date => {
  return parseISO(date)
}
