import React from 'react'
import * as Cp from 'shared/data/company'
import { ListProps } from 'shared/types/List'
import { Company } from 'shared/types/Company'
import { Column } from 'shared/types/Table'
import { Wrapper } from 'shared/components/Wrapper'
import { RemoteLoader } from 'shared/components/RemoteLoader'
import { StatusText } from 'shared/views/text/StatusText'
import { createScrollHandler } from 'shared/utils/scroll'
import { t } from 'shared/translations'
import { CompaniesTable } from './CompaniesTable'

export const CompaniesList: React.FC<ListProps<Company>> = ({
  state,
  order,
  loadNext,
  sortBy,
  rowLink,
  renderActions,
}) => {
  const { data, meta } = state
  const [[key, value = 'asc']] = Object.entries(order || Cp.defaultOrder)

  if (!data || !meta) {
    return <RemoteLoader state={state} />
  }

  const handleScroll = createScrollHandler({ data, meta, loadNext })

  const columns = renderActions
    ? [...dataColumns, { ...actionColumn, cellRenderer: renderActions }]
    : dataColumns

  return (
    <Wrapper>
      <CompaniesTable
        columns={columns}
        rowCount={data.length}
        rowGetter={({ index }) => data[index]}
        rowLink={rowLink}
        handleScroll={handleScroll}
        handleRequestSort={sortBy}
        orderKey={key as keyof Company}
        orderValue={value}
      />
    </Wrapper>
  )
}

const dataColumns: Column<Company>[] = [
  { id: 'name', label: t['property.name'], width: 600, minWidth: 200, sortable: true },
  {
    id: 'state',
    label: t['property.state'],
    width: 200,
    minWidth: 80,
    cellRenderer: props => {
      return <StatusText status={(props.rowData as Company).state} />
    },
  },
  {
    id: 'phone',
    label: t['property.phone'],
    width: 200,
    minWidth: 100,
    sortable: true,
  },
  { id: 'email', label: t['property.email'], width: 300, minWidth: 120, sortable: true },
  {
    id: 'city',
    label: t['property.address'],
    width: 400,
    minWidth: 160,
    sortable: true,
    cellRenderer: props => {
      const { city, street, houseNumber, apartmentNumber } = props.rowData as Company
      const streetAddress = [street, houseNumber, apartmentNumber].filter(Boolean).join(' ')
      return [city, streetAddress].filter(Boolean).join(', ')
    },
  },
]

const actionColumn: Column<Company> = {
  id: 'actions',
  label: '',
  width: 200,
  minWidth: 100,
}
