import { matchPath } from 'react-router'
import useRouter from 'use-react-router'
import mapValues from 'lodash/mapValues'
import { CreateLink, createLink, prefix } from 'shared/utils/url'

export type Params = {
  physiotherapistId: string
  patientId?: string
  setId?: string
}

const routes = {
  '/physiotherapist/:physiotherapistId': undefined,
  '/physiotherapist/:physiotherapistId/profile': undefined,
  '/physiotherapist/:physiotherapistId/patients': undefined,
  '/physiotherapist/:physiotherapistId/patients/add': undefined,
  '/physiotherapist/:physiotherapistId/patients/:patientId': undefined,
  '/physiotherapist/:physiotherapistId/patients/:patientId/sets': undefined,
  '/physiotherapist/:physiotherapistId/patients/:patientId/sets/add': undefined,
  '/physiotherapist/:physiotherapistId/patients/:patientId/sets/:setId': undefined,
  '/physiotherapist/:physiotherapistId/patients/:patientId/history': undefined,
  '/physiotherapist/:physiotherapistId/patients/:patientId/edit': undefined,
  '/physiotherapist/:physiotherapistId/exercises': undefined,
}

export const path = mapValues(routes, (v, k) => prefix + k)
export const link = mapValues(routes, (v, k) => (createLink as CreateLink<Params>)(k))

export const root = prefix + '/physiotherapist'
export const entry = '/physiotherapist/:physiotherapistId'

export const useParams = () => {
  const { location } = useRouter<Params>()
  const match = matchPath<Params>(location.pathname, prefix + entry)
  return (match && match.params) || undefined
}
