import { apiUrl } from 'shared/api/config'
import { request, serialize } from 'shared/api/utils'
import { UUID } from 'shared/types/UUID'
import { Entity } from 'shared/types/Entity'
import { Meta } from 'shared/types/Meta'
import { Query } from 'shared/types/Query'

// DEFINE CRUD REQUESTS

/**
 * Fetching a collection of resources of type `T`.
 * @param T - expected shape of api response
 * @param Q - shape of query allowed by this endpoint
 * @example
 * // Let's say there is endpoint GET /api/patients that returns payload:
 * { patients: Patient[], meta: Meta }
 *
 * // We can represent calling this endpoint as:
 * FetchMany<{ patients: Patient[], meta: Meta }>
 */
export type FetchMany<T extends { meta: Meta } = any, Q extends Query = any> = (
  query?: Q
) => Promise<T>

/**
 * Fetching a single resource of type `T` by its id.
 * @param T - expected shape of api response
 * @example
 * // Let's say there is endpoint GET /api/patients/{id} that returns:
 * { patient: Patient }
 *
 * // We can represent calling that endpoint as:
 * FetchOne<{ patient: Patient }>
 */
export type FetchOne<T extends object = any> = (...ids: UUID[]) => Promise<T>

/**
 * Creating a single resource of type `T` (using *POST*).
 * @param T - expected shape of api response
 * @param P - shape of payload needed to create the resource.
 * This is usually input from some form, but sometimes additional
 * parameters (like an id based on current URL) are needed too.
 * @example
 * // Let's say there is endpoint POST /api/patients that expects payload:
 * { name: string, email: string }
 * // and returns:
 * { patient: Patient }
 *
 * // We can represent calling that endpoint as:
 * CreateOne<{ patient: Patient }, { name: string, email: string }>
 */
export type CreateOne<T extends object = any, P extends object = any> = (payload: P) => Promise<T>

/**
 * Updating a single resource of type `T` (using *PUT*).
 * @param T - expected shape of api response
 * @param P - shape of payload needed to update the resource.
 * This is just the original object with some fields overwritten.
 * Shape of the resource shouldn't be changed here, so we don't expect
 * additional parameters (as was the case for creating a resource).
 * @example
 * // Let's say there is endpoint PUT /api/patients/{id} that expects payload:
 * { id: ID, name: string, email: string }
 * // and returns:
 * { patient: Patient }
 *
 * // We can represent calling that endpoint as:
 * UpdateOne<{ patient: Patient }, { id: ID, name: string, email: string }>
 */
export type UpdateOne<T extends object = any, P extends object & Entity = any> = (
  payload: P
) => Promise<T>

/**
 * Change status of a single resource of type `T` (using *PATCH*)
 * @param T - exected shape of api response
 * @example
 * // Let's say there is endpoint PATCH /api/patients/{id}/activate that returns:
 * { patient: Patient }
 *
 * // We can represent calling that endpoint as:
 * PatchOne<{ patient: Patient }>
 */
export type PatchOne<T extends object = any> = (...ids: UUID[]) => Promise<T>

/**
 * Delete a single resource of type `T` by its id.
 * We don't expect to receive any particular shape back, so this operation
 * doesn't need to be parametized. It works the same way for every resource.
 * @example
 * // Let's say there is endpoint DELETE /api/patients/{id}
 * // We can represent calling that endpoint as:
 * DeleteOne
 */
export type DeleteOne = (id: UUID) => Promise<any>

// SEND CRUD REQUESTS

export const fetchMany = (resource: string) => async (query?: Query) => {
  return request.get(`${apiUrl}/${resource}${serialize(query)}`)
}

export const fetchOne = (resource: string | ((...ids: UUID[]) => string)) => async (
  ...ids: UUID[]
) => {
  const endpoint = typeof resource === 'function' ? resource(...ids) : `${resource}/${ids[0]}`
  return request.get(`${apiUrl}/${endpoint}`)
}

export const createOne = (resource: string) => async (input: object) => {
  return request.post(`${apiUrl}/${resource}`, input)
}

export const updateOne = (resource: string) => async (input: object & Entity) => {
  return request.put(`${apiUrl}/${resource}/${input.id}`, input)
}

export const patchOne = (resource: string | ((...ids: UUID[]) => string)) => async (
  ...ids: UUID[]
) => {
  const endpoint = typeof resource === 'function' ? resource(...ids) : `${resource}/${ids[0]}`
  return request.patch(`${apiUrl}/${endpoint}`)
}

export const activateOne = (resource: string) => async (id: UUID) => {
  return request.patch(`${apiUrl}/${resource}/${id}/activate`)
}

export const deactivateOne = (resource: string) => async (id: UUID) => {
  return request.patch(`${apiUrl}/${resource}/${id}/deactivate`)
}

export const deleteOne = (resource: string) => async (id: UUID) => {
  return request.delete(`${apiUrl}/${resource}/${id}`)
}
