import React, { useEffect } from 'react'
import useRouter from 'use-react-router'
import * as Api from 'shared/api'
import * as Ft from 'shared/data/physiotherapist'
import { Physiotherapist } from 'shared/types/Physiotherapist'
import * as Layout from 'shared/components/Layout'
import { ErrorBoundary } from 'shared/components/ErrorBoundary'
import { ListHeader } from 'shared/components/ListHeader'
import { ListFilters } from 'shared/components/ListFilters'
import { TableRowActions } from 'shared/components/TableRowActions'
import { PhysiotherapistsList } from 'shared/views/PhysiotherapistsList'
import { ActionCall } from 'shared/views/actions/ActionCall'
import { t } from 'shared/translations'
import { link } from 'modules/routes'
import { Params } from 'modules/patient/routes'

export const PatientPhysiotherapists: React.FC = () => {
  const { match } = useRouter<Params>()
  const { state, collection } = Ft.useCollectionReducer()
  const { query, loadNext, searchBy, sortBy, updateStatus } = Ft.useQueries()
  const { patientId } = match.params

  useEffect(() => {
    let cancelled = false
    if (patientId) {
      collection.loading()
      Api.fetchPhysiotherapists({ ...query, patients: [patientId] })
        .then(res => !cancelled && collection.success(res.physiotherapists, res.meta))
        .catch(err => !cancelled && collection.failure(err))
    }
    return () => {
      cancelled = true
    }
  }, [collection, query, patientId])

  return (
    <ErrorBoundary>
      <Layout.FlexColumn>
        <ListHeader title={t['title.patientPhysiotherapists']} />
        <ListFilters
          searchTerm={query.searchTerm || ''}
          status={query.status}
          searchBy={searchBy}
          updateStatus={updateStatus}
        />
        <PhysiotherapistsList
          state={state}
          order={query.order}
          loadNext={loadNext}
          sortBy={sortBy}
          rowLink={physiotherapist =>
            link['/patient/:patientId/physiotherapists/:physiotherapistId']({
              patientId: match.params.patientId,
              physiotherapistId: physiotherapist.id,
            })
          }
          renderActions={props => {
            const p = props.rowData as Physiotherapist
            return (
              <TableRowActions>
                <ActionCall participant={p} />
              </TableRowActions>
            )
          }}
        />
      </Layout.FlexColumn>
    </ErrorBoundary>
  )
}
